import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  EMAIL_ADDRESS,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CHANGE_FORGOT_PASSWORD_SUCCESS,
  CHANGE_FORGOT_PASSWORD_FAIL,
  LOGOUT,
  RESET_FLAG_VALIDATION,
  UPDATE_USER
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const remember = JSON.parse(localStorage.getItem("remember"));

const initialState =
  user && remember
    ? {
        isRegister: false,
        emailAddress: null,
        isVerifyEmail: false,
        isForgotPassword: false,
        isChangeForgotPassword: false,
        remember: null,
        isLoggedIn: true,
        user,
        isActive: false,
      }
    : {
        isRegister: false,
        emailAddress: null,
        isVerifyEmail: false,
        isForgotPassword: false,
        isChangeForgotPassword: false,
        remember: null,
        isLoggedIn: false,
        user: null,
        isActive: false,
      };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegister: true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isRegister: false,
      };
    case EMAIL_ADDRESS:
      return {
        ...state,
        emailAddress: payload,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isRegister: false,
        isVerifyEmail: true,
      };
    case VERIFY_EMAIL_FAIL:
      return {
        ...state,
        isRegister: true,
        isVerifyEmail: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isForgotPassword: false,
        isChangeForgotPassword: false,
        user: payload.user,
        isActive: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isForgotPassword: false,
        isChangeForgotPassword: false,
        user: null,
        isActive: payload === 2 ? true : false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPassword: true,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isForgotPassword: false,
      };
    case CHANGE_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangeForgotPassword: true,
      };
    case CHANGE_FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isChangeForgotPassword: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case RESET_FLAG_VALIDATION:
      return {
        ...state,
        isRegister: false,
        isForgotPassword: false,
      };
    case UPDATE_USER: 
      return {
        ...state,
        user: payload,
      }
    default:
      return state;
  }
}
