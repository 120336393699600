import React from "react";
import "./styles.scss";
import { Table } from "modules";
import GameService from "services/game.service";
import moment from "moment";

const HistoryJackpot = () => {
  let structure = [
    {
      name: "CREATED DATE",
      key: "created_at",
      render: (item) => moment.utc(item.end).local().format("L HH:mm:ss"),
    },
    {
      name: "NUMBER",
      key: "draw_number",
      render: (item) => (
        <div className={`flex items-center justify-center gap-2`}>
          {item.draw_number.split("").map((el, idx) => (
            <div key={idx} className={`inline-flex items-center justify-center number-history`}>
              {el}
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "CODE",
      key: "phase_code",
      render: (item) => `#${item.phase_code}`,
    },
  ];

  return (
    <div className="history-jackpot">
      <Table
        hasOrderColumn
        isShowHeader={false}
        structure={structure}
        fetchData={async (state) =>
          GameService.getDraw(
            moment().subtract(1, "month").utc().set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
            moment().utc().set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
            state.pageNumber - 1,
            state.limit
          ).then((res) => {
            return {
              data: res?.data?.data?.result,
              count: res?.data?.data?.total,
            };
          })
        }
      />
    </div>
  );
};

export default HistoryJackpot;
