import {
  FETCH_PHASE_SUCCESS,
  FETCH_PHASE_FAIL,
  FETCH_TIME_PHASE_SUCCESS,
  FETCH_TIME_PHASE_FAIL,
  GET_GAME_MAX_MIN_SUCCEES,
  GET_GAME_MAX_MIN_FAIL,
  GET_GAME_EVEN_ODD_SUCCEES,
  GET_GAME_EVEN_ODD_FAIL,
} from "../actions/types";

const initialState = {
  listPhase: null,
  timePhase: null,
  gamePhaseMaxMin: null,
  gamePhaseEvenOdd: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_PHASE_SUCCESS:
      return {
        ...state,
        listPhase: payload,
      };
    case FETCH_PHASE_FAIL:
      return {
        ...state,
        listPhase: null,
      };
    case FETCH_TIME_PHASE_SUCCESS:
      return {
        ...state,
        timePhase: payload,
      };
    case FETCH_TIME_PHASE_FAIL:
      return {
        ...state,
        timePhase: null,
      };
    case GET_GAME_MAX_MIN_SUCCEES:
      return {
        ...state,
        gamePhaseMaxMin: payload,
      };
    case GET_GAME_MAX_MIN_FAIL:
      return {
        ...state,
        gamePhaseMaxMin: null,
      };
    case GET_GAME_EVEN_ODD_SUCCEES:
      return {
        ...state,
        gamePhaseEvenOdd: payload,
      };
    case GET_GAME_EVEN_ODD_FAIL:
      return {
        ...state,
        gamePhaseEvenOdd: null,
      };
    default:
      return state;
  }
}
