import React, { useEffect } from "react";
import FooterGlobal from "component/footerGlobal";
import HeaderGlobal from "component/headerGlobal";
import PropTypes from "prop-types";
import "./style.scss";
import { MenuGlobal } from "component/menuGlobal";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useIsMobile } from "utils/utils";
import socketService from 'socket.service';

const MasterLayout = (props) => {
  const { children } = props;

  const isMobile = useIsMobile();

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    socketService.connect();
    return () => {
      socketService.disconnect();
    };
  }, [isLoggedIn]);

  // if (isLoggedIn) return <Redirect to="/" />;
  return (
    <>
      {!isMobile ? (
        <div className="flex min-h-screen">
          <MenuGlobal />
          <div style={{ display: "flex", flexDirection: "column" }} className="flex-1 relative">
            <HeaderGlobal />
            <div
              style={{
                background: "#26003E",
                backgroundImage: "url(/assets/images/dashboard-background.png)",
                backgroundSize: "cover",
                minHeight: "73vh",
              }}
              className="px-10 pt-24 pb-8 flex-1"
            >
              {children}
            </div>
            <FooterGlobal />
          </div>
        </div>
      ) : (
        <div className="min-h-screen">
          <div style={{ display: "flex", flexDirection: "column" }} className="flex-1 relative">
            <HeaderGlobal />
            <div
              style={{
                background: "#26003E",
                backgroundImage: "url(/assets/images/dashboard-background.png)",
                backgroundSize: "cover",
                minHeight: "73vh",
                padding: "16px",
              }}
            >
              {children}
            </div>
            <FooterGlobal />
          </div>
        </div>
      )}

      <Toaster />
    </>
  );
};

MasterLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MasterLayout;
