import {
  FETCH_POST_DETAIL_FAIL,
  FETCH_POST_DETAIL_SUCCESS,
  FETCH_LIST_POSTS_FAIL,
  FETCH_LIST_POSTS_SUCCESS,
  LOADING_FAIL,
} from "./types";

import PostService from "../services/post.service";
//   import { setMessage } from "./message";

export const getListPost = (page, pageSize) => (dispatch) => {
  return PostService.getListPost(page, pageSize).then(
    (response) => {
      dispatch({
        type: FETCH_LIST_POSTS_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const error_code =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.error_code || error.response.data.message)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: FETCH_LIST_POSTS_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getPostDetail = (post_id) => (dispatch) => {
  return PostService.getPostDetail(post_id).then(
    (response) => {
      dispatch({
        type: FETCH_POST_DETAIL_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const error_code =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.error_code || error.response.data.message)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: FETCH_POST_DETAIL_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};
