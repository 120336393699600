import React from "react";
import "./style.scss";
import ImagePayment from "assets/svg/ImagePayment.js";
import Phone from "assets/images/Phone.svg";
import { useTranslation } from "react-i18next";
import Circle from "assets/images/Circle.svg";
import { Icon } from "modules";

function WalletPayment() {
  const { t } = useTranslation();

  return (
    <div className="wallet-payment">
      <div className="container text-center">
        <h3 className="font-title-section font-bold font-wallet" data-aos="fade-up" data-aos-duration="600">
          {t("Wallet Payment")}
        </h3>
        <p data-aos="fade-up" data-aos-duration="600" data-aos-delay="100" className="home-title xl:mx-96 mx-0" style={{ marginTop: "1.188rem" }}>
          {t("mmlotto is the best way to play these exciting lucky gaming from anywhere in the world.")}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
          <div className="wallet-payment__left relative" data-aos="fade-up" data-aos-duration="600" data-aos-delay="100">
            <img src={Circle} className="circle" alt="" />
            <img src={Phone} alt="Phone" data-aos="zoom-out-right" data-aos-duration="600" data-aos-delay="600" />
            <div className="wallet-payment__img absolute " data-aos="zoom-out-left" data-aos-duration="600" data-aos-delay="600">
              <ImagePayment />
            </div>
          </div>
          <div className="wallet-payment__right lg:pl-40 text-left">
            <h2 data-aos="fade-up" data-aos-duration="600" data-aos-delay="600">
              {t("Exchange Rate")}
            </h2>
            <p className="wallet-payment__title" data-aos="fade-up" data-aos-duration="600" data-aos-delay="650">
              {t("Buy international lucky tickets online using any of the payment methods available on MMLotto Play now and win big!")}
            </p>
            {/* <a href="#">
              {t("Learn more")} <Icon.ArrowRight />
            </a> */}
            <div className="wallet-payment__item" data-aos="fade-up" data-aos-duration="600" data-aos-delay="650">
              <Icon.USDT />
              <p>Tether</p>
            </div>
            <div className="wallet-payment__item" data-aos="fade-up" data-aos-duration="600" data-aos-delay="700">
              <Icon.Bitcoin />
              <p>Bitcoin</p>
            </div>
            <div className="wallet-payment__item" data-aos="fade-up" data-aos-duration="600" data-aos-delay="750">
              <Icon.ETH />
              <p>ETH Ethereum</p>
            </div>
            <div className="wallet-payment__item" data-aos="fade-up" data-aos-duration="600" data-aos-delay="800">
              <Icon.BNB />
              <p>BNB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletPayment;
