let TranslationEN = {
  translations: {
    "en-US": "en-US",
    Home: "Home",
    Games: "Games",
    "Winner Users": "Winner Users",
    "Winner Numbers": "Winner Numbers",
    "Winning Users": "Winning Users",
    "Winning Numbers": "Winning Numbers 666",
    News: "News",
    "Contact Us": "Contact",
    About: "About",
    "About Us": "About Us",
    FAQ: "FAQ",
    "Term Of Service": "Term Of Service",
    "User Profile": "User Profile",
    "Take the chance to change your life": "Take the chance to change your life",
    "The world's hottest gaming platform. We bring a new level of availability and transparency in the gaming market.":
      "The world's hottest gaming platform. We bring a new level of availability and transparency in the gaming market.",
    "JOIN US": "JOIN US",
    "Buy Lottery Tickets Online": "Buy Lottery Tickets Online",
    "Buy lottery tickets online to the biggest lotteries in the world offering huge jackpot prizes that you can win when you play online lottery.":
      "Buy lottery tickets online to the biggest lotteries in the world offering huge jackpot prizes that you can win when you play online lottery.",
    Hours: "Hours",
    Minutes: "Minutes",
    Seconds: "Seconds",
    "Lottery Jackpot": "Lottery Jackpot",
    "Choose from the Powerball, Mega Millions, Lotto or Lucky Day Lotto and try for a chance to win a big cash prize":
      "Choose from the Powerball, Mega Millions, Lotto or Lucky Day Lotto and try for a chance to win a big cash prize",
    "Next Drawing": "Next Drawing",
    "Buy Ticket": "Buy Ticket",
    "Latest Lottery Results": "Latest Lottery Results",
    "Check your lotto results online, find all the lotto winning numbers and see if you won the latest lotto jackpots!":
      "Check your lotto results online, find all the lotto winning numbers and see if you won the latest lotto jackpots!",
    "Lottery Winning Numbers": "Lottery Winning Numbers",
    "Lottery Winning Users": "Lottery Winning Users",
    "See more": "See more",
    "How It Works": "How It Works",
    "mmlotto is the best way to play these exciting lotteries from anywhere in the world.":
      "mmlotto is the best way to play these exciting lotteries from anywhere in the world.",
    Choose: "Choose",
    "Choose your lottery & pick your numbers": "Choose your lottery & pick your numbers",
    Buy: "Buy",
    "Complete your purchase": "Complete your purchase",
    "Start dreaming, you're almost there": "Start dreaming, you're almost there",
    "Wallet Payment": "Wallet Payment",
    "Exchange Rawallet-paymentte": "Exchange Rawallet-paymentte",
    "Buy international lottery tickets online using any of the payment methods available on mmlotto Play now and win big!":
      "Buy international lottery tickets online using any of the payment methods available on mmlotto Play now and win big!",
    "Buy Game": "Buy Game",
    "Our mmlotto lottery games are available on mobile app, please download and enjoy your time":
      "Our mmlotto lottery games are available on mobile app, please download and enjoy your time",
    "Subscribe to Our Newsletter": "Subscribe to Our Newsletter",
    "Time is the most precious thing you have when bootstrapping. You can't take time.":
      "Time is the most precious thing you have when bootstrapping. You can't take time.",
    "Enter your email": "Enter your email",
    "Learn more": "Learn more",
    "Help Menu": "Help Menu",
    "View Profile": "View Profile",
    Setting: "Setting",
    Dashboard: "Dashboard",
    "Order History": "Order History",
    "Winning History": "Winning History",
    Wallet: "Wallet",
    KYC: "KYC",
    "Account Security": "Account Security",
    "Affiliate Member": "Affiliate Member",
    "Support Ticket": "Support Ticket",
    "Available Balance": "Available Balance",
    Wins: "Wins",
    Losts: "Losts",
    "Read More": "Read More",
    "A few word about us": "A few word about us",
    "Our goal is to provide our customers with the most convenient and enjoyable lottery purchasing experience.":
      "Our goal is to provide our customers with the most convenient and enjoyable lottery purchasing experience.",
    "It’s not just what we do, it’s who we are. From past to present, we are a success story still in the making.With an enthusiasm to serve, commitment to providing fun and fair games is matched by our desire to make a positive difference in the community.":
      "It’s not just what we do, it’s who we are. From past to present, we are a success story still in the making.With an enthusiasm to serve, commitment to providing fun and fair games is matched by our desire to make a positive difference in the community.",
    "Playing It Global for Over a Decade": "Playing It Global for Over a Decade",
    "Play from Anywhere in the World": "Play from Anywhere in the World",
    "Absolutely No Commissions Taken": "Absolutely No Commissions Taken",
    "A Simple and Secure Service": "A Simple and Secure Service",
    "Winning Made Easy": "Winning Made Easy",
    "A bit of History": "A bit of History",
    "Why Choose Us?": "Why Choose Us?",
    "Description Why":
      "With a selection of the world’s biggest and best international jackpots to choose from, there are already millions of reasons why you should be playing with mmlotto gaming. But you don’t need a million reasons. All you need is six - and every one of them’s a winner:",
    "Biggest lottery jackpots": "Biggest lottery jackpots",
    "Performance Bonuses": "Performance Bonuses",
    "Dedicated Support": "Dedicated Support",
    "Reliable Payment": "Reliable Payment",
    "Unlimited Affiliates": "Unlimited Affiliates",
    "Instant payout system": "Instant payout system",
    "Buying date": "Buying date",
    "Buying Date": "Buying Date",
    Number: "Number",
    Price: "Price",
    Win: "Win",
    Withdraw: "Withdraw",
    Deposit: "Deposit",
    History: "History",
    "Amount to withdraw": "Amount to withdraw",
    "Amount to deposit": "Amount to deposit",
    "Withdraw from My Wallet to": "Withdraw from My Wallet to",
    "Exchange rate": "Exchange Rate",
    Source: "Source",
    "last update": "last update",
    "Wallet Address": "Wallet Address",
    Fee: "Fee",
    Note: "Note",
    "Minimum deposit amount is 10$": "Minimum deposit amount is 10$",
    "Minimum withdraw amount is 10$": "Minimum withdraw amount is 10$",
    "Know Your Customers": "Know Your Customers",
    "Let’s start with getting to know your profession": "Let’s start with getting to know your profession",
    "Personal Information": "Personal Information",
    "First name": "First name",
    "Middle name": "Middle name",
    "Last name": "Last name",
    Gender: "Gender",
    Male: "Male",
    Female: "Female",
    "Phone number": "Phone number",
    "Date of birth": "Date of birth",
    "Date of issue": "Date of issue",
    "ID card": "ID card",
    Address: "Address",
    Country: "Country",
    "State (Optional)": "State (Optional)",
    "Postcode (Ip code)": "Postcode (Ip code)",
    "Address 2 (Optional)": "Address 2 (Optional)",
    City: "City",
    "Upload your document": "Upload your document",
    "Your sefile": "Your sefile",
    "Indentify Card": "Indentify Card",
    "Show Indentify Card": "Show Indentify Card",
    "(Front)": "(Front)",
    "(Back)": "(Back)",
    Save: "Save",
    Submit: "Submit",
    "First name is required.": "First name is required.",
    "Middle name is required.": "Middle name is required.",
    "Last name is required.": "Last name is required.",
    "Gender is required.": "Gender is required.",
    "Phone number is required.": "Phone number is required.",
    "Only numbers are allowed": "Only numbers are allowed",
    "No less than 8 numbers": "No less than 8 numbers",
    "ID card is required.": "ID card is required.",
    "City is required.": "City is required.",
    "Address is required.": "Address is required.",
    "Wallet address is required.": "Wallet address is required.",
    "Birthday is required.": "Birthday is required.",
    "Issue date is required.": "Issue date is required.",
    "Enter your first name": "Enter your first name",
    "Enter your middle name": "Enter your middle name",
    "Enter your last name": "Enter your last name",
    "Select your gender": "Select your gender",
    "Enter your phone number": "Enter your phone number",
    "Choose country": "Choose country",
    State: "State",
    "Identify number": "Identify number",
    Great: "Great",
    Succesfull: "Succesfull",
    "Your checkout is successful.": "Your checkout is successful.",
    "Thank for using our services.": "Thank for using our services.",
    Sorry: "Sorry",
    "Your payment is currently unavailable.": "Your payment is currently unavailable.",
    "Your account has insufficient funds!": "Your account has insufficient funds!",
    "Data not found": "Data not found",
    Languages: "Languages",
    Notification: "Notification",
    "Push Notifications": "Push Notifications",
    "Email Notifications": "Email Notifications",
    "Change Login Password": "Change Login Password",
    "Current Password": "Current Password",
    "New Password": "New Password",
    "Confirm Password": "Confirm Password",
    "Enter your current password": "Enter your current password",
    "Enter your new password": "Enter your new password",
    "Current password is required.": "Current password is required.",
    "New password is required.": "New password is required.",
    "Confirm password is required.": "Confirm password is required.",
    "Password must be between 8 and 40 characters.": "Password must be between 8 and 40 characters.",
    "Password's not match": "Password's not match",
    "Change password": "Change password",
    "Members by country": "Members by country",
    "Country with the larggest number of members": "Country with the larggest number of members",
    "No. Of Members": "No. Of Members",
    Income: "Income",
    "Income from member": "Income from member",
    "You have no income from the f1": "You have no income from the f1",
    "Membership Growth": "Membership Growth",
    "New members": "New members",
    Earning: "Earning",
    "1 week": "1 week",
    "1 month": "1 month",
    "1 year": "1 year",
    "Earning by Campaign Types": "Earning by Campaign Types",
    All: "All",
    Personal: "Personal",
    Referral: "Referral",
    "Your team": "Your team",
    "Active members": "Active members",
    Member: "Member",
    "Persional Income": "Persional Income",
    Commisions: "Commisions",
    "Ticket ID": "Ticket ID",
    "Sending date": "Sending date",
    Subject: "Subject",
    Status: "Status",
    Options: "Options",
    "Create A Ticket": "Create A Ticket",
    "Terms & Conditions": "Terms & Conditions",
    "Dummy text is also used to demostate the aprreasrance of different typefaces and layouts":
      "Dummy text is also used to demostate the aprreasrance of different typefaces and layouts",
    "1. How to successful our business plan": "1. How to successful our business plan",
    "When replacing a multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained. multi-lined selection of text, the generated dummy text maintains the amount of lines.":
      "When replacing a multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained. multi-lined selection of text, the generated dummy text maintains the amount of lines.",
    "2. Clients satisfaction make company value": "2. Clients satisfaction make company value",
    "3. World class creative firm": "3. World class creative firm",
    "4. We are the best online platform": "4. We are the best online platform",
    "5. Clients satisfaction make company value": "5. Clients satisfaction make company value",
    "Games Policy": "Games Policy",
    "When replacing a multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained. multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained.multi-lined selection of text":
      "When replacing a multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained. multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained.multi-lined selection of text",
    "The amount of words is world": "The amount of words is world",
    "When replacing a selection": "When replacing a selection",
    "Maintains the amount of lines": "Maintains the amount of lines",
    "Words is roughly being main": "Words is roughly being main",
    "Back to news": "Back to news",
    "SIGN IN": "SIGN IN",
    "Not a member?": "Not a member?",
    "now!": "now!",
    "Email Address": "Email Address",
    "Password is required.": "Password is required.",
    "Your Password": "Your Password",
    "Remember me?": "Remember me?",
    "Forgotten your password?": "Forgotten your password?",
    "Sign In": "Sign In",
    "Email is required.": "Email is required.",
    "This is not a valid email.": "This is not a valid email.",
    Login: "Login",
    "No account logged in": "No account logged in",
    Logout: "Logout",
    "Get In Touch": "Get In Touch",
    "If you have any questions or queries our helpful support team will be more than happy to assist.":
      "If you have any questions or queries our helpful support team will be more than happy to assist.",
    "Please see our Frequent Asked Questions (FAQ) page to read more information":
      "Please see our Frequent Asked Questions (FAQ) page to read more information",
    "Full name is required.": "Full name is required.",
    Message: "Message",
    "Check FAQs": "Check FAQs",
    Send: "Send",
    "Password Recovery": "Password Recovery",
    "Enter your email address to reset your password.": "Enter your email address to reset your password.",
    "Send Code": "Send Code",
    "OTP Verification": "OTP Verification",
    "We'll send a verification code to the email address you used to reset the password.":
      "We'll send a verification code to the email address you used to reset the password.",
    "Code expires in": "Code expires in",
    "Code has expired": "Code has expired",
    Verify: "Verify",
    "We'll send a verification code to the email address you used to create the account.":
      "We'll send a verification code to the email address you used to create the account.",
    "Enter your password": "Enter your password",
    "Enter your confirm Password": "Enter your confirm Password",
    "Full Name": "Full Name",
    "Enter full name": "Enter full name",
    "Your Email": "Your Email",
    REGISTER: "REGISTER",
    "Country is required.": "Country is required.",
    Register: "Register",
    "Select your country": "Select your country",
    "Referral Code": "Referral Code",
    "Referral By": "Referral By",
    Password: "Password",
    Remove: "Remove",
    Upload: "Upload",
    "Frequently Asked Questions": "Frequently Asked Questions",
    Winning: "Winning",
    Result: "Result",
    "Page not found": "Page not found",
    "Sorry, but the page you are looking is not found. Please, make sure you have typed the current URL":
      "Sorry, but the page you are looking is not found. Please, make sure you have typed the current URL",
    "Go To HomePage": "Go To HomePage",
    "Descriptopn buy ticket":
      "Up is opinion message manners correct hearing husband my. Dispos commanded dashwoods cordially depending at at. Its strangers who you certainty earnestly resources suffering she. Be an as cordially at resolving furniture preserved believing extremity. Easy mr pain felt in. Too northward affection additions nay.",
    Jackpot: "Jackpot",
    "Nex Drawing": "Nex Drawing",
    "Quick Pick": "Quick Pick",
    "Add To Card": "Add To Card",
    "Buy Now": "Buy Now",
    Clear: "Clear",
    Filter: "Filter",
    "From date": "From date",
    "Address Wallet": "Address Wallet",
    "Enter your address wallet": "Enter your address wallet",
    "Select status transaction": "Select status transaction",
    Type: "Type",
    "Select type transaction": "Select type transaction",
    Pending: "Pending",
    Success: "Success",
    Rejected: "Rejected",
    "Receipt Number": "Receipt Number",
    Quantity: "Quantity",
    withdraw: "Withdraw",
    Time: "Time",
    To: "To",
    Amount: "Amount",
    Value: "Value",
    "Transaction Fee": "Transaction Fee",
    "Transaction Hash": "Transaction Hash",
    Confirm: "Confirm",
    "Transaction Details": "Transaction Details",
    "Order details": "Order details",
    "Order ID": "Order ID",
    Total: "Total",
    Tickets: "Tickets",
    deposit: "Deposit",
    "Add To Cart": "Add To Cart",
    "Add Cart": "Add Cart",
    Cart: "Cart",
    "Affliate on home page": "Affliate on home page",
    "Tell a friend is our unique lottery friend promotion club that enables you to earn amazing bonus money rewaerds for inviting friends to play the word's biggest jackpots!":
      "Tell a friend is our unique lottery friend promotion club that enables you to earn amazing bonus money rewaerds for inviting friends to play the word's biggest jackpots!",
    "Tell a friend is our unique lottery friend promotion club that enables":
      "Tell a friend is our unique lottery friend promotion club that enables",
    "Exchange Rate": "Exchange Rate",
    "All Rights Reserved © 2020 by mmlotto": "All Rights Reserved © 2020 by mmlotto",
    "New Coming Product": "New Coming Product",
    "Learn More": "Learn More",
    "Reserve now for 58% off": "Reserve now for 58% off",
    "Mega6X Max": "Mega6X Max",
    "COMING SOON TO KICKSTARTER": "COMING SOON TO KICKSTARTER",
    "Not only is Mega 6X Pro your best chance to pocket , but it’s also only $0 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else.":
      "Not only is Mega 6X Pro your best chance to pocket , but it’s also only $0 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else.",
    "Not only is Mega 6X Max your best chance to pocket , but it’s also only $0 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else.":
      "Not only is Mega 6X Max your best chance to pocket , but it’s also only $0 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else.",
    "High Revenues": "High Revenues",
    "Unlimited Affilicates": "Unlimited Affilicates",
    By: "By",
    "No tickets": "No tickets",
    "Scan address to receive payment": "Scan address to receive payment",
    "You can't withdraw!": "You can't withdraw!",
    "You can't deposit!": "You can't deposit!",
    "Table View": "Table View",
    "Tree View": "Tree View",
    Max: "Max",
    Min: "Min",
    Even: "Even",
    odd: "Odd",
    MMLotto: "MMLotto",
    "Get Lucky Number": "Get Lucky Number",
    "History Jackpot": "History Jackpot",
    Whitepaper: "Whitepaper",
    "Term of User": "Term of User",
    "Terms for Fire Sale": "Terms for Fire Sale",
    "Terms for Airdrop": "Terms for Airdrop",
    "Privacy Policy": "Privacy Policy",
    "Welcome back!": "Welcome back!",
    "We're so excited to see you again!": "We're so excited to see you again!",
    "Log In to your MMLotto !!": "Log In to your MMLotto !!",
  },
};

export default TranslationEN;
