import React from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

export const NavLink = ({className = "", ...props}) => {
  const history = useHistory();

  return (
    <Link
      className={`${className} ${history.location.pathname === props.path ? "active" : ""}`}
      onClick={() => {
        if (toast?.dismiss) toast.dismiss();
        if (props?.onClick) props.onClick();
      }}
      to={props.path}
    >
      {props.children}
    </Link>
  );
};

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
