import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Icon } from "modules";
// import moment from "moment";

function CountDownTime(props) {
  const { t } = useTranslation();
  const { Countdown, typeCountDown } = props;

  return (
    <div className="count-down-container">
      <div className="text-center">
        <div
          className={`${
            typeCountDown === "Game"
              ? "modal-time__number"
              : typeCountDown === "Home"
              ? "home-time__number"
              : typeCountDown === "GameMinMax"
              ? "count-down-time__MinMax"
              : typeCountDown === "GameEvenOdd"
              ? "count-down-time__EvenOdd"
              : "count-down-time__number"
          } flex items-center justify-center`}
        >
          <span>
            {Countdown
              ? (Countdown.get("date") - 1) * 24 + Countdown.get("hour") > 9
                ? parseInt((Countdown.get("date") - 1) * 24 + Countdown.get("hour"))
                : "0" + parseInt((Countdown.get("date") - 1) * 24 + Countdown.get("hour"))
              : "00"}
          </span>
        </div>
        {/* <p className="count-down-time__title">{t("hrs")}</p> */}
      </div>
      <div className="icon-colon">
        <Icon.Colon />
      </div>
      <div className="text-center">
        <div
          className={`${
            typeCountDown === "Game"
              ? "modal-time__number"
              : typeCountDown === "Home"
              ? "home-time__number"
              : typeCountDown === "GameMinMax"
              ? "count-down-time__MinMax"
              : typeCountDown === "GameEvenOdd"
              ? "count-down-time__EvenOdd"
              : "count-down-time__number"
          } flex items-center justify-center`}
        >
          <span>
            {Countdown ? (Countdown.get("minute") > 9 ? parseInt(Countdown.get("minute")) : "0" + parseInt(Countdown.get("minute"))) : "00"}
          </span>
        </div>
        {/* <p className="count-down-time__title">{t("mins")}</p> */}
      </div>
      <div className="icon-colon">
        <Icon.Colon />
      </div>
      <div className="text-center">
        <div
          className={`${
            typeCountDown === "Game"
              ? "modal-time__number"
              : typeCountDown === "Home"
              ? "home-time__number"
              : typeCountDown === "GameMinMax"
              ? "count-down-time__MinMax"
              : typeCountDown === "GameEvenOdd"
              ? "count-down-time__EvenOdd"
              : "count-down-time__number"
          } flex items-center justify-center`}
        >
          <span>
            {Countdown ? (Countdown.get("second") > 9 ? parseInt(Countdown.get("second")) : "0" + parseInt(Countdown.get("second"))) : "00"}
          </span>
        </div>
        {/* <p className="count-down-time__title">{t("secs")}</p> */}
      </div>
    </div>
  );
}

export default CountDownTime;
