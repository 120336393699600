import React, { useEffect, useState } from "react";
import "./styles.scss";
import CountDownTime from "component/countDownTime";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { buyGame } from "actions/game";
import { useLocation } from "react-router-dom";
import { Button, Icon } from "modules";
import { getGameEvenOdd, getGameMaxMin, getPhase, getTimePhase } from "actions/phase";
import toast from "react-hot-toast";
import { LOADING_SUCCESS } from "actions/types";
import MinMax from "assets/images/MinMax.png";
import EvenOdd from "assets/images/EvenOdd.png";
import { InputNumber } from "primereact/inputnumber";
import ModalRulesGame from "component/modalRulesGame";
import { getProfileUser } from "actions/user";
import { TabPanel, TabView } from "primereact/tabview";
import { useIsMobile } from "utils/utils";

const LuckyNumber = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [CountdownMaxMin, setCountDownMaxMin] = useState(0);
  const [CountdownEvenOdd, setCountDownEvenOdd] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [evenValue, setEvenValue] = useState(0);
  const [oddValue, setOddValue] = useState(0);
  const [isErr, setIsErr] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [typeGame, setTypeGame] = useState(null);

  const phase = useSelector((state) => state.phase);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const error = (message) => toast.error(message);
  const success = (message) => toast.success(message);
  const info = () => toast("Please login", { icon: "❗" });

  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const onSubmit = (typeGame, type) => {
    if (!isLoggedIn) {
      info();
    } else {
      setIsErr(false);
      const amount =
        typeGame === "MaxMin" && type === 1
          ? maxValue
          : typeGame === "MaxMin" && type === 2
          ? minValue
          : typeGame === "EvenOdd" && type === 1
          ? evenValue
          : oddValue;
      if (amount > 0)
        dispatch(
          buyGame({
            lottery_id: typeGame === "MaxMin" ? phase?.gamePhaseMaxMin?.lotteryId : phase?.gamePhaseEvenOdd?.lotteryId,
            phase_id: typeGame === "MaxMin" ? phase?.gamePhaseMaxMin?.id : phase?.gamePhaseEvenOdd?.id,
            choose:
              typeGame === "MaxMin" && type === 1
                ? "Max"
                : typeGame === "MaxMin" && type === 2
                ? "Min"
                : typeGame === "EvenOdd" && type === 1
                ? "Even"
                : "Odd",
            amount: amount,
          })
        )
          .then(() => {
            setIsErr(false);
            dispatch(getGameMaxMin());
            dispatch(getGameEvenOdd());
            dispatch(getProfileUser());
            success(
              `You have successfully purchased the game ${
                typeGame === "MaxMin" && type === 1
                  ? "Over"
                  : typeGame === "MaxMin" && type === 2
                  ? "Under"
                  : typeGame === "EvenOdd" && type === 1
                  ? "Even"
                  : "Odd"
              }`
            );
          })
          .catch(() => {
            setIsErr(true);
          });
      else {
        error("Please enter the MUT you want to play");
      }
    }
  };

  const renderMaxMin = () => (
    <>
      <div className="minMaxEvenOdd--top">
        <div className="flex items-center minMaxEvenOdd--top--left">
          <span>MMLotto Game 1</span>
          <span
            onClick={() => {
              setDisplayModal(true);
              setTypeGame("MaxMin");
            }}
          >
            Rules play
          </span>
        </div>
        <div className="flex items-center minMaxEvenOdd--top--right">
          <span>TIME END</span>
          <CountDownTime Countdown={CountdownMaxMin} typeCountDown={"Game"} />
        </div>
      </div>
      <div className="minMaxEvenOdd--bottom">
        <div className="minMaxEvenOdd--bottom__top">
          <div className="content flex-1">
            {/* “Not only is MMLotto your best chance to pocket , but it’s also only $2 to play. Best of all, if you win the top prize, you won’t have to
            share it with anyone else." */}
          </div>
          <div className="time">
            <span>
              Jackpot:
              <span className="value">{new Date().toLocaleDateString(t("en-US"), optionsDate)}</span>
            </span>
            <span className="block">ID: #{phase?.gamePhaseMaxMin?.phaseCode}</span>
          </div>
        </div>
        <div className="minMaxEvenOdd--bottom__bottom">
          <img src={MinMax} alt="MinMax" />
          <div className="play">
            <div className="choose-play col-span-1">
              <div className="name-type-ticket">
                <span>{t("Over")}</span>
              </div>
              <div className="input-price-ticket">
                <span className="p-input-icon-left">
                  <Icon.Diamond />
                  <InputNumber
                    placeholder="0.00"
                    minFractionDigits={2}
                    value={maxValue}
                    onFocus={() => setMaxValue()}
                    onChange={(e) => setMaxValue(e.value)}
                  />
                </span>
              </div>
              <div className="flex items-center justify-center amount-user">
                <span>Total Set: {Number(phase?.gamePhaseMaxMin?.totalDiamondMax).toFixed(2)}</span>
              </div>
              <div className="flex items-center justify-center">
                <Button label={t("Buy ticket")} buttonType="secondary" onClick={() => onSubmit("MaxMin", 1)} />
              </div>
            </div>

            <div className="choose-play col-span-1">
              <div className="name-type-ticket">
                <span>{t("Under")}</span>
              </div>
              <div className="input-price-ticket">
                <span className="p-input-icon-left">
                  <Icon.Diamond />
                  <InputNumber
                    placeholder="0.00"
                    minFractionDigits={2}
                    value={minValue}
                    onFocus={() => setMinValue()}
                    onChange={(e) => setMinValue(e.value)}
                  />
                </span>
              </div>
              <div className="flex items-center justify-center amount-user">
                <span>Total Set: {Number(phase?.gamePhaseMaxMin?.totalDiamondMin).toFixed(2)}</span>
              </div>
              <div className="flex items-center justify-center">
                <Button label={t("Buy ticket")} buttonType="secondary" onClick={() => onSubmit("MaxMin", 2)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const renderEvenOdd = () => (
    <>
      <div className="minMaxEvenOdd--top">
        <div className="flex items-center minMaxEvenOdd--top--left">
          <span>MMLotto Game 2</span>
          <span
            onClick={() => {
              setDisplayModal(true);
              setTypeGame("EvenOdd");
            }}
          >
            Rules play
          </span>
        </div>
        <div className="flex items-center minMaxEvenOdd--top--right">
          <span>TIME END</span>
          <CountDownTime Countdown={CountdownEvenOdd} typeCountDown={"Game"} />
        </div>
      </div>
      <div className="minMaxEvenOdd--bottom">
        <div className="minMaxEvenOdd--bottom__top">
          <div className="content flex-1">
            {/* “Not only is MMLotto your best chance to pocket , but it’s also only $2 to play. Best of all, if you win the top prize, you won’t have to
            share it with anyone else." */}
          </div>
          <div className="time">
            <span>
              Jackpot:
              <span className="value">{new Date().toLocaleDateString(t("en-US"), optionsDate)}</span>
            </span>
            <span className="block">ID: #s123213</span>
            {/* <span>
                Jackpot:
                <span className="value">{new Date(item?.start.replace(/-/g, "/")).toLocaleDateString(t("en-US"), optionsDate)}</span>
              </span>
              <span className="block">ID: #{item?.phase_code}</span> */}
          </div>
        </div>
        <div className="minMaxEvenOdd--bottom__bottom">
          <img src={EvenOdd} alt="EvenOdd" />
          <div className="play">
            <div className="choose-play col-span-1">
              <div className="name-type-ticket">
                <span>{t("Even")}</span>
              </div>
              <div className="input-price-ticket">
                <span className="p-input-icon-left">
                  <Icon.Diamond />
                  <InputNumber
                    placeholder="0.00"
                    minFractionDigits={2}
                    value={evenValue}
                    onFocus={() => setEvenValue()}
                    onChange={(e) => setEvenValue(e.value)}
                  />
                </span>
              </div>
              <div className="flex items-center justify-center amount-user">
                <span>Total Set: {Number(phase?.gamePhaseEvenOdd?.totalDiamondEven).toFixed(2)}</span>
              </div>
              <div className="flex items-center justify-center">
                <Button label={t("Buy ticket")} buttonType="secondary" onClick={() => onSubmit("EvenOdd", 1)} />
              </div>
            </div>

            <div className="choose-play col-span-1">
              <div className="name-type-ticket">
                <span>{t("Odd")}</span>
              </div>
              <div className="input-price-ticket">
                <span className="p-input-icon-left">
                  <Icon.Diamond />
                  <InputNumber
                    placeholder="0.00"
                    minFractionDigits={2}
                    value={oddValue}
                    onFocus={() => setOddValue()}
                    onChange={(e) => setOddValue(e.value)}
                  />
                </span>
              </div>
              <div className="flex items-center justify-center amount-user">
                <span>Total Set: {Number(phase?.gamePhaseEvenOdd?.totalDiamondOdd).toFixed(2)}</span>
              </div>
              <div className="flex items-center justify-center">
                <Button label={t("Buy ticket")} buttonType="secondary" onClick={() => onSubmit("EvenOdd", 2)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    isErr && error(message);
  }, [isErr, message]);

  useEffect(() => {
    dispatch(getPhase());
    dispatch(getTimePhase());
    dispatch(getGameMaxMin());
    dispatch(getGameEvenOdd());
    dispatch({
      type: LOADING_SUCCESS,
    });
  }, [dispatch]);

  useEffect(() => {
    let timerMinMax;
    let timerEvenOdd;

    let start = phase.timePhase ? moment(phase.timePhase.time).utc() : moment().utc();

    let endMaxMin = phase.gamePhaseMaxMin ? moment(phase.gamePhaseMaxMin.end).utc() : moment().utc();
    const durationMaxMin = moment.duration(endMaxMin.diff(start));
    let MillisecondsMaxMin = durationMaxMin.asMilliseconds();

    if (MillisecondsMaxMin !== 0) {
      timerMinMax = setInterval(() => {
        if (MillisecondsMaxMin > 0) {
          MillisecondsMaxMin = moment.duration(MillisecondsMaxMin - 1000, "milliseconds");
        } else {
          if (MillisecondsMaxMin === 0) {
            dispatch(getTimePhase());
            dispatch(getGameMaxMin());
          }
        }
        if (MillisecondsMaxMin >= 0) setCountDownMaxMin(moment(MillisecondsMaxMin.asMilliseconds()).utc());
      }, 1000);
    }

    let endEvenOdd = phase.gamePhaseEvenOdd ? moment(phase.gamePhaseEvenOdd.end).utc() : moment().utc();
    const durationEvenOdd = moment.duration(endEvenOdd.diff(start));
    let MillisecondsEvenOdd = durationEvenOdd.asMilliseconds();

    if (MillisecondsEvenOdd !== 0) {
      timerEvenOdd = setInterval(() => {
        if (MillisecondsEvenOdd > 0) {
          MillisecondsEvenOdd = moment.duration(MillisecondsEvenOdd - 1000, "milliseconds");
        } else {
          if (MillisecondsEvenOdd === 0) {
            dispatch(getTimePhase());
            dispatch(getGameEvenOdd());
          }
        }
        if (MillisecondsEvenOdd >= 0) setCountDownEvenOdd(moment(MillisecondsEvenOdd.asMilliseconds()).utc());
      }, 1000);
    }
    return () => {
      clearInterval(timerMinMax);
      clearInterval(timerEvenOdd);
    };
  }, [phase, dispatch]);

  return (
    <>
      <div className="lucky-number-page">
        <h1 className="title">Get Your Lucky Number</h1>
        <div className="subtitle">
          Buy lottery tickets online to the biggest lotteries in the world offering huge jackpot prizes that you can win when you play online lottery.
        </div>

        {phase &&
          phase.listPhase != null &&
          (isMobile ? (
            <TabView>
              <TabPanel header="Over - Under" className="minMaxEvenOdd">
                {renderMaxMin()}
              </TabPanel>
              <TabPanel header="Even - Odd" className="minMaxEvenOdd">
                {renderEvenOdd()}
              </TabPanel>
            </TabView>
          ) : (
            <>
              <div className="minMaxEvenOdd">{renderMaxMin()}</div>
              <div className="minMaxEvenOdd mt-10">{renderEvenOdd()}</div>
            </>
          ))}
      </div>

      <ModalRulesGame
        typeGame={typeGame}
        displayModal={displayModal}
        closeModal={() => {
          setDisplayModal(false);
        }}
      />
    </>
  );
};

export default LuckyNumber;
