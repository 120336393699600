import {
  GET_WINNERS_SUCCESS,
  GET_WINNERS_FAIL,
  CHOOSE_NUMBER,
  NUMBER_CARD,
  DELETE_CARD,
  CHANGE_QUANTITY,
  POST_ID_CARD,
  BUY_TICKET_SUCCESS,
  BUY_TICKET_FAIL,
  CHANGE_PRICE,
  VALIDATION_MAX_GAME,
  ADD_TO_CART,
  CLEAR_VALIDATION_MAX_GAME,
  GET_LOCAL_CART,
  LOADING_FAIL,
  GET_DRAW_SUCCESS,
  GET_DRAW_FAIL,
  GET_TOTAL_DRAW,
  GET_TICKET_REMAIN,
  BUY_GAME_SUCCESS,
  BUY_GAME_FAIL,
  SET_MESSAGE,
} from "./types";

import GameService from "../services/game.service";
import { setMessage } from "./message";

export const getWinners = (from_date, to_date, page, pageSize) => (dispatch) => {
  return GameService.getWinners(from_date, to_date, page, pageSize).then(
    (response) => {
      dispatch({
        type: GET_WINNERS_SUCCESS,
        payload: response.data.data,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: response.data.message,
      // });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const error_code =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.error_code || error.response.data.message)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: GET_WINNERS_FAIL,
      });

      // dispatch(setMessage("register", error_code));

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getDraw = (from_date, to_date, page, pageSize) => (dispatch) => {
  return GameService.getDraw(from_date, to_date, page, pageSize).then(
    (response) => {
      dispatch({
        type: GET_DRAW_SUCCESS,
        payload: response.data.data,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: response.data.message,
      // });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const error_code =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.error_code || error.response.data.message)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: GET_DRAW_FAIL,
      });

      // dispatch(setMessage("register", error_code));

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const buyTicket = (list_item, type) => (dispatch) => {
  return GameService.buyTicket(list_item, type).then(
    (response) => {
      dispatch({
        type: BUY_TICKET_SUCCESS,
        payload: response.data.data.bonusDiamond,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: response.data.message,
      // });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.error_code || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: BUY_TICKET_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch(setMessage("buyTicket", error_code));

      return Promise.reject();
    }
  );
};

export const postIdCurrent = (data) => (dispatch) => {
  dispatch({
    type: POST_ID_CARD,
    payload: data.idCardCurrent,
  });
  if (data.typeGame === "Max")
    dispatch({
      type: VALIDATION_MAX_GAME,
    });
};

export const getChooseNumber = (data) => (dispatch) => {
  dispatch({
    type: CHOOSE_NUMBER,
    payload: data,
  });
  if (data.typeGame === "Max")
    dispatch({
      type: VALIDATION_MAX_GAME,
    });
};

export const getCard = (data) => (dispatch) => {
  dispatch({
    type: NUMBER_CARD,
    payload: data,
  });
  if (data.typeGame === "Max")
    dispatch({
      type: VALIDATION_MAX_GAME,
    });
};

export const deleteCard = (data) => (dispatch) => {
  dispatch({
    type: DELETE_CARD,
    payload: data.id,
  });
  if (data.typeGame === "Max")
    dispatch({
      type: VALIDATION_MAX_GAME,
    });
};

export const changeCount = (id, bol, modal) => (dispatch) => {
  dispatch({
    type: CHANGE_QUANTITY,
    payload: { id, bol, modal },
  });
};

export const changePrice = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_PRICE,
    payload: data,
  });
};

export const claerValidationMaxGame = () => (dispatch) => {
  dispatch({
    type: CLEAR_VALIDATION_MAX_GAME,
  });
};

export const addToCart = (ticket) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: ticket,
  });
};

export const getLocalCart = () => (dispatch) => {
  dispatch({
    type: GET_LOCAL_CART,
  });
};

export const getTotalDraw = (period) => (dispatch) => {
  return GameService.getTotalDraw(period).then(
    (response) => {
      dispatch({
        type: GET_TOTAL_DRAW,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getTicketRemain = () => (dispatch) => {
  return GameService.getTicketRemain().then(
    (response) => {
      dispatch({
        type: GET_TICKET_REMAIN,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const buyGame = (lottery_id, phase_id, choose, amount) => (dispatch) => {
  return GameService.buyGame(lottery_id, phase_id, choose, amount).then(
    (response) => {
      dispatch({
        type: BUY_GAME_SUCCESS,
        payload: response.data.data,
      });
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.resolve();
    },
    (error) => {
      const msg =
        (error.response &&
          error.response.data &&
          (error.response.data.errors || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: BUY_GAME_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: msg,
      });

      return Promise.reject();
    }
  );
};
