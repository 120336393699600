import { LOADING_FAIL, LOADING_SUCCESS } from "actions/types";

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case LOADING_SUCCESS:
      return { isLoading: true };

    case LOADING_FAIL:
      return { isLoading: false };

    default:
      return state;
  }
}
