import { LIST_COUNTRIES_SUCCESS, LIST_COUNTRIES_FAIL, SET_MESSAGE, LOADING_FAIL } from "./types";

import DirectoryService from "../services/directory.service";

export const getCountries = () => (dispatch) => {
  return DirectoryService.getCountries().then(
    (response) => {
      dispatch({
        type: LIST_COUNTRIES_SUCCESS,
        payload: response.data.data,
      });
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          (error.response.data.message || error.response.data.errors)) ||
        error.message ||
        error.toString();

      dispatch({
        type: LIST_COUNTRIES_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};
