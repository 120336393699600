import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const token = isSupported().then((value) => {
  if (value) {
    const firebaseApp = initializeApp({
      apiKey: "AIzaSyDm14ygNGDr8hVGLs_ABr-tzs2jJyOMA1Y",
      authDomain: "bbs-megabing.firebaseapp.com",
      projectId: "bbs-megabing",
      storageBucket: "bbs-megabing.appspot.com",
      messagingSenderId: "482966036933",
      appId: "1:482966036933:web:dd9c32ac6f913e403aabac",
      measurementId: "G-S9VDDH1NEH",
    });
    const messaging = getMessaging(firebaseApp);
    return getToken(messaging, {
      vapidKey:
        "BP0XPmrjq8dTOko3xWd1Yo-cN9wMKbJfPIPpG4TIy82QcqB-ROyRwWt2sfqKLNBKXwJlKhMa-lPwE12qIGfx3Mo",
    })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  } else {
    console.log("Firebase does not support");
    return;
  }
});

export { token };
