import React, { useState, useEffect, useCallback } from "react";
import Clipboard from "react-clipboard.js";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Icon, Table } from "modules";
import WalletService from "services/wallet.Service";
import moment from "moment";
import { dashboardLayout } from "component";
import { TableFilterInputSelect } from "component/table-filter-inputs/select";
import { TableFilterInputText } from "component/table-filter-inputs/text";
import { TableFilterRangeTimeInput } from "component/table-filter-inputs/range-time";
import ModalHistory from "component/modalHistory";
import { useTranslation } from "react-i18next";
import ModalDetailHistory from "component/modalDetailHistory";

const OrderHistoryPage = dashboardLayout((props) => {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const { t } = useTranslation();

  const [orderHistoryDetail, setOrderHistoryDetail] = useState(null);
  const [params, setParams] = useState();

  const onClickCloseDialog = () => {
    setOrderHistoryDetail(null);
  };

  const handleOnClickViewDetail = async (element) => {
    await WalletService.getOrderHistory(
      moment().subtract(1, "month").utc().set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
      moment().utc().set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
      params.pageNumber - 1,
      params.limit
    ).then((res) => {
      setOrderHistoryDetail(res?.data?.data?.result?.filter((x) => x.id === element?.id));
    });
  };

  let valuePeriod = (period) => {
    switch (period) {
      case 1:
        return "One-Time";
      case 2:
        return "Week";
      case 3:
        return "Month";
      case 4:
        return "Quarter";
      case 5:
        return "Year";
      default:
        return;
    }
  };

  let structure = [
    {
      name: "ID",
      key: "id",
      render: (item) => item?.id,
    },
    {
      name: "BUYING DATE",
      key: "created_at",
      render: (item) => moment.utc(item.created_at).local().format("L HH:mm:ss"),
    },
    {
      name: "RECEIPT NUMBER",
      key: "receipt_number",
      render: (item) => `#${item.receipt_number}`,
    },
    {
      name: "PRICE",
      render: (item) => (
        <div className="amount">
          <div className="amount__icon">
            <img src={`/assets/images/coins/${item.currency === "USD" ? "usdt" : "mut"}.png`} alt="usdt" />
          </div>
          <div className="amount__text">{item.currency === "USD" ? Number(item.order_total).toFixed(2) : Number(item.order_total).toFixed(2)}</div>
        </div>
      ),
    },
    {
      name: "PERIOD",
      key: "plan_type",
      render: (item) => valuePeriod(item.plan_type),
    },
    {
      name: "QUANTITY",
      key: "Quantity",
      render: (item) => `${item.Quantity === 0 ? 1 : item.Quantity} ${t("Ticket")}`,
    },
    {
      name: "ACTION",
      render: (item) => (
        <div className="action" onClick={() => handleOnClickViewDetail(item)}>
          <Icon.EyeViewDetailIcon />
        </div>
      ),
    },
  ];

  return (
    <div className="order-history-page">
      <Table
        hasOrderColumn
        structure={structure}
        fetchData={async (state) => {
          setParams(state);
          return WalletService.getOrderHistory(
            moment().subtract(1, "month").utc().set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
            moment().utc().set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
            state.pageNumber - 1,
            state.limit
          ).then((res) => {
            return {
              data: res?.data?.data?.result,
              count: res?.data?.data?.total,
            };
          });
        }}
      />
      <ModalDetailHistory visible={orderHistoryDetail != null} orderHistoryDetail={orderHistoryDetail} onClickCloseDialog={onClickCloseDialog} />
    </div>
  );
});

export default OrderHistoryPage;
