import React, { FC, useState, CSSProperties, SyntheticEvent } from "react";

import { Icon } from "modules";
import { ClassNames } from "../utils";

const Wraper = (props) => {
  if (props.isMiddle) return <div className="Button_Wraper_Middle">{props.children}</div>;
  return props.children;
};

export const Button = ({
  isVisible = true,
  label,
  type = "button",
  onClick,
  isMiddle = false,
  style,
  buttonType = "primary",
  isLoading,
  className,
  disabled,
  icon,
  id,
  uppercaseLabel = true,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const loading = isButtonLoading || isLoading;

  const buttonClassName = ClassNames({
    Button: true,
    middle: isMiddle,
    loading: !!loading,
    [buttonType]: !!buttonType,
    [className]: !!className,
    disabled: disabled,
  });

  const handleClick = async (e) => {
    if (isButtonLoading || disabled) return;
    if (e) e.preventDefault();
    if (typeof isLoading !== "boolean") setIsButtonLoading(true);
    await onClick();
    if (typeof isLoading !== "boolean") setIsButtonLoading(false);
  };

  if (!isVisible) return null;

  return (
    <Wraper isMiddle={isMiddle}>
      {(() => {
        if (onClick)
          return (
            <button id={id} disabled={disabled} style={style} type={type} className={buttonClassName} onClick={handleClick}>
              <Icon.Button />
              <div className="content-btn">
                {uppercaseLabel ? <span className="label">{`${label}`.toUpperCase()}</span> : <span className="label">{`${label}`}</span>}

                {icon ? icon() : null}

                {loading ? (
                  <div className="iconLoading">
                    <Icon.Loading />
                  </div>
                ) : null}
              </div>
            </button>
          );

        return (
          <button id={id} disabled={disabled} style={style} type={type} className={buttonClassName}>
            <Icon.Button />
            <div className="content-btn">
              {uppercaseLabel ? <span className="label">{`${label}`.toUpperCase()}</span> : <span className="label">{`${label}`}</span>}

              {icon ? icon() : null}

              {loading ? (
                <div className="iconLoading">
                  <Icon.Loading />
                </div>
              ) : null}
            </div>
          </button>
        );
      })()}
    </Wraper>
  );
};
