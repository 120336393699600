import { ACTIVE_PARTNER, CLEAR_MESSAGE } from "actions/types";
import { getProfileUser } from "actions/user";
import IconPartnerFail from "assets/svg/IconPartnerFail";
import IconPartnerSuccess from "assets/svg/IconPartnerSuccess";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./style.scss";

function ModalPartnerActive() {
  const [displayModal, setDisplayModal] = useState(false);
  const partner = useSelector((state) => state.user.partner);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    partner !== "" ? setDisplayModal(true) : setDisplayModal(false);
  }, [partner]);

  return (
    <Dialog
      visible={displayModal}
      onHide={() => setDisplayModal(false)}
      closable={false}
      showHeader={false}
      className="modal-active"
    >
      <div className="modal-active-content text-center">
        <div className="container">
          <div className="flex items-center justify-center mb-6">
            {partner ? <IconPartnerSuccess /> : <IconPartnerFail />}
          </div>
          <span className="modal-status">{partner ? "Great" : "Oop!"}</span>
          <p className="my-4 modal-title">{partner ? "Sign Up Success" : message}</p>
          <span className="modal-subtitle">
            {partner
              ? "You have become a partnet of Super Lucky"
              : "Registration failed, please try again later"}
          </span>
          <div
            className="mt-4 btn-click mx-auto "
            onClick={() => {
              setDisplayModal(false);
              dispatch(getProfileUser());
              dispatch({
                type: ACTIVE_PARTNER,
                payload: "",
              });
              dispatch({
                type: CLEAR_MESSAGE,
              });
              !user ? history.push("/login") : history.push("/home");
            }}
          >
            Click & Continue
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalPartnerActive;
