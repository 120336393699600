import React, { FC, useState, useEffect } from 'react'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';

export const InputDateTime = (props) => {
    const [value, setValue] = useState(null);

    const handleChange = (date) => {
        let time;
        if (date) time = new Date(date)?.getTime();

        setValue(time);
        props.onChange(time ? new Date(time)?.toUTCString() : null);
    }

    const convertTime = (time) => {
        if (!time) return;
        return new Date(time);
    }

    useEffect(() => {
        setValue(props?.defaultValue);
    }, [props.defaultValue, setValue])

    return <DateTimePicker
        className="InputDateTime"
        calendarClassName="InputDateTimeCalendar"
        clockClassName="InputDateTimeClock"
        // maxDetail={maxDetail}
        disableClock={true}
        onChange={handleChange}
        // minDate={convertTime(minDate)}
        // maxDate={convertTime(maxDate)}
        value={convertTime(value)}
        locale={'en-US'}
        disabled={props.isDisabled}
        onCalendarClose={() => props.onTouched()}
        onBlur={() => props.onTouched()}
        format={props?.format}
    />
}