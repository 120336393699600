import { getProfileUser, registerPartner } from "actions/user";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import VIP5 from "assets/images/VIP5.png";
import Standard from "assets/images/Standard.png";
import Golden from "assets/images/Golden.png";
import "./style.scss";
import { Icon } from "modules";

function ModalPartner(props) {
  const dispatch = useDispatch();
  const [typePartner, setTypePartner] = useState(null);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const info = () => toast("Please login", { icon: "❗" });

  useEffect(() => {
    user?.partner !== null && setTypePartner(user?.partner);
  }, [user]);

  return (
    <Dialog
      visible={props.displayModal}
      onHide={() => props.closeModal()}
      dismissableMask={true}
      closable={false}
      showHeader={false}
      className="modal-partner"
    >
      <div className="modal-partner-content text-center">
        <button
          className="btn-close"
          onClick={() => {
            props.closeModal();
          }}
        >
          <span className="pi pi-times"></span>
        </button>
        <div className="container">
          <div className="img-vip mx-auto mb-4">
            <img src={VIP5} alt="VIP" />
          </div>
          {user?.partner && user?.partner !== null ? (
            <span className="modal-title">{`You’re ${Number(user?.partner) === 1 ? "Standard" : "Golden"} Agent`}</span>
          ) : (
            <span className="modal-title">Sign up to become an agent</span>
          )}

          <div className="flex items-center justify-between gap-4 mt-8">
            <div
              className={`${typePartner === "1" && "active"} type-partner`}
              onClick={() => {
                user?.is_partner !== 1 && setTypePartner("1");
              }}
            >
              <div className="img-partner mx-auto mb-1.5">
                <img src={Standard} alt="VIP" />
              </div>
              <span className="title-partner">Standard</span>
            </div>
            <div
              className={`${typePartner === "2" && "active"} type-partner`}
              onClick={() => {
                user?.is_partner !== 1 && setTypePartner("2");
              }}
            >
              <div className="img-partner mx-auto mb-1.5">
                <img src={Golden} alt="VIP" />
              </div>
              <span className="title-partner">Golden</span>
            </div>
          </div>
          <div className="text-left">
            <ul>
              <li className="flex items-start gap-2 mt-8">
                <div>
                  <Icon.CheckPartner />
                </div>
                <span className="modal-text">Participate in VinID events (Private, Exclusive)</span>
              </li>
              <li className="flex items-start gap-2 my-4 py-4 border-t-2 border-b-2 border-dashed">
                <div>
                  <Icon.CheckPartner />
                </div>
                <span className="modal-text">Using the call center is free of charge</span>
              </li>
              <li className="flex items-start gap-2">
                <div>
                  <Icon.CheckPartner />
                </div>
                <span className="modal-text">Privilege of early check-out, late check-out when using the service under the conditions allowed</span>
              </li>
            </ul>
          </div>
          <div className="mt-6">
            <button
              className={`btn-play ${Number(user?.is_partner) === 1 && "hidden"}`}
              onClick={() => {
                if (user) {
                  if (typePartner) {
                    dispatch(registerPartner(typePartner));
                    dispatch(getProfileUser());
                    props.closeModal();
                  } else toast.error("Please select the type of partner you want to apply for");
                } else {
                  history.push("/login");
                  info();
                }
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalPartner;
