import RouteWithLayout from "component/routerLayout";
import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Home from "page/home";
import NotFound from "page/NotFound";
import MasterLayout from "layout";
import OverviewPage from "page/overview-page";
import OrderHistoryPage from "page/oder-history-page";
import DepositPage from "page/deposit-page";
import ExchangePage from "page/exchange-page";
import SettingsPage from "page/settings-page";
import AffiliateMemberPage from "page/affiliate-member-page";
import RedirectFromPage from "page/redirect-from-page";
import KycPage from "page/kyc-page";
import LuckyNumber from "page/luckyNumber";
import HistoryJackpot from "page/historyJackpot";
import Home2 from "page/home2";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout exact path="/" layout={MasterLayout} component={Home} />
      <RouteWithLayout exact path="/home" layout={MasterLayout} component={Home} />
      <RouteWithLayout exact path="/get-lucky-number" layout={MasterLayout} component={LuckyNumber} />
      <RouteWithLayout exact path="/history-jackpot" layout={MasterLayout} component={HistoryJackpot} />
      <RouteWithLayout exact path="/overview" layout={MasterLayout} component={OverviewPage} />
      <RouteWithLayout exact path="/order-history" layout={MasterLayout} component={OrderHistoryPage} />
      <RouteWithLayout exact path="/deposit" layout={MasterLayout} component={DepositPage} />
      <RouteWithLayout exact path="/exchange" layout={MasterLayout} component={ExchangePage} />
      <RouteWithLayout exact path="/settings" layout={MasterLayout} component={SettingsPage} />
      <RouteWithLayout exact path="/affiliate-member" layout={MasterLayout} component={AffiliateMemberPage} />
      <RouteWithLayout exact path="/kyc" layout={MasterLayout} component={KycPage} />
      <Route exact path="/redirect-from" component={RedirectFromPage} />

      <Route path="/404" component={NotFound} />
      <Redirect from="*" to="/404" />

      {/* /////////////////////////////////////////////// */}

      {/* <Route exact path="/register" component={Register} /> */}
      {/* <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/otp" component={OTPPage} />
        <Route exact path="/games" component={Game} />
        <Route exact path="/winning-user" component={WinningUser} />
        <Route path="/user" component={UserDashboard} />
        <Route path="/orderhistory" component={UserOrderHistory} />
        <Route path="/kyc" component={UserKYC} />
        <Route path="/setting" component={UserSetting} />
        <Route path="/account-security" component={UserAccountSecurity} />
        <Route path="/winning-number" component={WinningNumber} />
        <Route path="/aboutus" component={About} />
        <Route path="/news/:page" component={News} />
        <Route path="/wallet" component={UserWallet} />
        <Route exact path="/news" component={News} />
        <Redirect exact from="/news-detail" to="/news" />
        <Route exact path="/news-detail/:id" component={NewsDetail} />
        <Route path="/affiliate" component={UserAffiliate} />
        <Route path="/statistic" component={UserStatistic} />
        <Route path="/get-in-touch" component={GetInTouch} />
        <Route path="/faq" component={FAQ} />
        <Route path="/cart" component={Cart} />
        <Route path="/term-service" component={TermOfService} />
        <Route path="/notify" component={Notify} /> */}
      {/* <Route path="/referral" component={Register} /> */}
      {/* <Route path="/account-information" component={AccountInformation} />
        <Route path="/verify-partner" component={Home} />
        <Route path="/exchange" component={UserExchange} /> */}
    </Switch>
  );
};

export default Routes;
