import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { setStyleHeading } from "actions/styleHeading";
import './style.scss';
import { useTranslation } from 'react-i18next';

const NotFound = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(setStyleHeading(props.match.path));
      }, [dispatch, props.match.path]);

    return(
        <section className="container notfound my-20 md:my-40">
            <div className="title-page text-center" data-aos="fade-up" data-aos-duration="600">
                <span>404</span>
            </div>
            <div className="content">
                <div className="text-center content-main" data-aos="fade-up" data-aos-duration="600" data-aos-delay="100">
                    <span>Oops! {t("Page not found")}</span>
                </div>
                <div className="text-center content-description mb-12" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
                    <span>
                        {t('Sorry, but the page you are looking is not found. ' +
                        'Please, make sure you have typed the current URL')}
                    </span>
                </div>
                <div className="text-center button-homepage" data-aos="fade-up" data-aos-duration="600" data-aos-delay="300">
                    <Link to="/" className="rounded-full px-12 py-2.5 orange text-white">
                        {t('Go To HomePage')}
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default NotFound;