
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { token } from "firebase.js";
import { addTokenDevice, getProfileUser } from "actions/user";
import { useDispatch } from "react-redux";
import { UPDATE_USER } from "actions/types";

const RedirectFromPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const t = query.get('t');
    const accessPayload = {"access_token": decodeURIComponent(t)};
    localStorage.setItem("user", JSON.stringify(accessPayload));
    localStorage.setItem("remember", 'true');
    window.history.replaceState({}, document.title, window.location.pathname);
    window.open((t != null && t !== '') ? '/overview' : '/home', "_self");

    // useEffect(() => {
    //     dispatch({
    //         type: UPDATE_USER,
    //         payload: accessPayload,
    //     });

    //     dispatch(getProfileUser()).then(() => {
    //         token.then((data) => {
    //             dispatch(addTokenDevice(data));
    //             localStorage.setItem("deviceToken", data);
    //         });
    //         history.push("/overview");
    //     }).catch(() => {
    //         history.push("/home");
    //     });

    // }, []);

};

export default RedirectFromPage;