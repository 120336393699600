import React, { useState, useEffect } from "react";
import UserService from "services/user.service";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "modules";
import moment from "moment";
import { dashboardLayout } from "component";

const AffiliateMemberPage = dashboardLayout(() => {

    const user = useSelector((state) => state.user.user);

    const [affiliateMemberData, setAffiliateMemberData] = useState([]);

    let structure = [
        {
            name: "CREATED DATE",
            key: "created_at",
            render: (item) => moment(item?.created_at).format("L HH:mm:ss")
        },
        {
            name: "EMAIL",
            key: "email",
            render: (item) => item?.email
        },
        {
            name: "EARNING",
            key: "earning",
            render: (item) => new Intl.NumberFormat("en-US").format(item?.earning)
        },
    ];

    useEffect(() => {
        UserService.getListReferal(user?.id).then((res) => {
            setAffiliateMemberData(res?.data?.data);
        });
    }, []);

    return (
        <div className="affiliate-member-page">
            <div className="affiliate-member">
                <div className="affiliate-member__overview">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <div className="affiliate-member__overview__item">
                            <div className="affiliate-member__overview__item__label">Total Earning</div>
                            <div className="affiliate-member__overview__item__value">{(affiliateMemberData?.totalEarning != null) ? new Intl.NumberFormat("en-US").format(affiliateMemberData?.totalEarning) : 'N/A'} $</div>
                        </div>
                        <div className="affiliate-member__overview__item">
                            <div className="affiliate-member__overview__item__label">F1 member</div>
                            <div className="affiliate-member__overview__item__value">{(affiliateMemberData?.f1Member != null) ? new Intl.NumberFormat("en-US").format(affiliateMemberData?.f1Member) : 'N/A'}</div>
                        </div>
                        <div className="affiliate-member__overview__item">
                            <div className="affiliate-member__overview__item__label">F2 member</div>
                            <div className="affiliate-member__overview__item__value">{(affiliateMemberData?.f2Member != null) ? new Intl.NumberFormat("en-US").format(affiliateMemberData?.f2Member) : 'N/A'}</div>
                        </div>
                    </div>
                </div>
                <div className="affiliate-member__history">
                    <div className="affiliate-member__history__title">HISTORY</div>
                    <div className="affiliate-member__history__table">
                        <Table
                            hasOrderColumn
                            // filters={filters}
                            structure={structure}
                            fetchData={async (state) => UserService.fetchListReferal({
                                    user_id: user?.id,
                                    page: state.pageNumber - 1,
                                    pageSize: state.limit,
                                }).then((res) => {
                                    return ({
                                        data: res?.data?.data?.history?.result,
                                        count: res?.data?.data?.history?.total,
                                    });
                                })
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AffiliateMemberPage;
