import React, { useRef, useState } from 'react';

import { ObjectUtils, ClassNames } from "../utils";
import { Icon } from "modules";
import { Message } from '../message';

export const TableSearchBox = (props) => {
    const inputRef = useRef(null);
    let delayCheckTyping;

    const [isLoading, setIsLoading] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [indexSelect, setIndexSelect] = useState(-1);
    const isHasValue = !!ObjectUtils.getIn(inputRef, 'current.value');
    const [initSearch, setInitSearch] = useState(false);

    const handleChange = (e) => {
        clearTimeout(delayCheckTyping);
        const value = e.target.value;
        delayCheckTyping = setTimeout(async () => {
            if (inputRef && inputRef.current) {
                setInitSearch(true);
                setIsLoading(true);
                try {
                    const result = await props.fetchData(value);
                    setSearchResult(result);
                } catch (error) {
                    Message.error('Search failed');
                } finally {
                    setIsLoading(false);
                }
            }
        }, 300);
    };

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                setIndexSelect((prevIndex) => (prevIndex < searchResult.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'ArrowUp':
                setIndexSelect((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'Enter':
                if (indexSelect >= 0 && indexSelect < searchResult.length) {
                    props.onSelect(searchResult[indexSelect]);
                    props.onOffSearch();
                }
                break;
            case 'Escape':
                props.onOffSearch();
                break;
            default:
                break;
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            props.onOffSearch();
        }, 100);
    };

    return (
        <div className={ClassNames('table-search-box', { 'is-loading': isLoading })}>
            <input
                ref={inputRef}
                type="text"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onFocus={props.onActive}
                placeholder={props.placeholder}
                className="table-search-box-input"
            />
            {isHasValue && <Icon name="close" className="table-search-box-clear" onClick={() => (inputRef.current.value = '')} />}
            {initSearch && (
                <div className="table-search-box-results">
                    {searchResult.map((item, index) => (
                        <div
                            key={index}
                            className={ClassNames('table-search-box-result-item', { 'is-selected': index === indexSelect })}
                            onMouseDown={() => {
                                props.onSelect(item);
                                props.onOffSearch();
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};