import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";
import { message } from "./defineCode";

export const setMessage = (name, error_code) => {
  const messageErr = message(name, error_code);
  return {
    type: SET_MESSAGE,
    payload: messageErr,
  };
};

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
