import socketIOClient from "socket.io-client";
import store from './store';

const EMIT_TYPE = {
    SUBSCRIBE: "SUBSCRIBE_SUB_ROOM",
    UNSUBSCRIBE: "UNSUBSCRIBE_SUB_ROOM",
};

class SocketService {
    socket;
    subscribeRoom;

    constructor() {
        this.socket = null;
        this.subscribeRoom = [];
    }

    connect() {
        let token = store?.getState()?.auth?.user?.access_token?.split('|')[1];
        let url = `${process.env['REACT_APP_URL_SOCKET']}?token=${token}&appId=${process.env['REACT_APP_URL_SOCKET_APP_ID']}`;
        this.socket = socketIOClient(url, {
            transports: ['websocket'],
        });

        this.socket.on('connect', () => {
            console.log('Connected to the server');

            //subscribe here
            this.subcribe('ANNOUNCEMENT');
        });

        this.socket.on('disconnect', () => {
            console.log('Disconnected from the server');
        });

        // Add your custom event listeners here
        // this.socket.on('someEvent', (data) => {
        //     console.log('someEvent received:', data);
        // });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            console.log('Socket disconnected');
        }
    }

    // Add emit function to send events to the server
    emit(event, data) {
        if (this.socket) {
            this.socket.emit(event, data);
        }
    }

    // custom on function to listen for events
    on(inCommand, callBack) {
        if (this.socket) {
            this.socket.on("NEW_SERVER_COMMAND", (command, payload) => {
                if (inCommand === command) callBack(payload);
            });
        }
    }

    // Add function to unregister event listeners
    off(event, callback) {
        if (this.socket) {
            this.socket.off(event, callback);
        }
    }

    subcribe(commandType, args) {
        const subcriber = (args != null) ? `${commandType}=${args}` : commandType;
    
        if (!this.socket) return;
    
        if (this.subscribeRoom.length === 0) {
            this.socket.emit(EMIT_TYPE.SUBSCRIBE, subcriber);
            (this.subscribeRoom).push(subcriber);
        } else if (!(this.subscribeRoom).find((item) => item === subcriber)) {
            this.socket.emit(EMIT_TYPE.SUBSCRIBE, subcriber);
            (this.subscribeRoom).push(subcriber);
        }
    }
}

const socketService = new SocketService();
export default socketService;
