import customAxios from "../configs/axios";
import { userAPI } from "configs/const";

const getProfileUser = () => {
  return customAxios.get(userAPI.getProfileUser);
};

const logoutUser = () => {
  return customAxios.post(userAPI.logoutUser);
};

const resetPassword = (old_password, new_password) => {
  return customAxios.post(userAPI.resetPassword, { old_password, new_password });
};

const getKYC = () => {
  return customAxios.get(userAPI.getKYC);
};

const updateKYCPersonalInfo = (
  first_name,
  middle_name,
  last_name,
  dob,
  gender,
  identity_card,
  issue_date,
  phone_number
) => {
  console.log('dob', dob)
  console.log('issue_date', issue_date)
  return customAxios.post(userAPI.updateKYCPersonalInfo, {
    first_name,
    middle_name,
    last_name,
    dob,
    gender,
    identity_card,
    issue_date,
    phone_number,
  });
};

const updateKYCAddressInfo = (
  country_id,
  state_province,
  city,
  postal_code,
  address1,
  address2
) => {
  return customAxios.post(userAPI.updateKYCAddressInfo, {
    country_id,
    state_province,
    city,
    postal_code,
    address1,
    address2,
  });
};

const uploadKYCDocument = (selfie, front_identity_card, back_identity_card, front_passport) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  formData.append("selfie", selfie);
  if (front_identity_card) formData.append("front_identity_card", front_identity_card);
  if (back_identity_card) formData.append("back_identity_card", back_identity_card);
  if (front_passport) formData.append("front_passport", front_passport);
  return customAxios.post(userAPI.uploadKYCDocument, formData, config);
};

const uploadAvatar = (image) => {
  const configAva = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formDataAva = new FormData();
  formDataAva.append("image", image);
  return customAxios.post(userAPI.uploadAvatar, formDataAva, configAva);
};

const addToken = (device_token) => {
  return customAxios.post(userAPI.addToken, {
    device_token,
  });
};

const removeToken = (device_token) => {
  return customAxios.post(userAPI.removeToken, {
    device_token,
  });
};

const pushNotify = (enable) => {
  return customAxios.post(userAPI.pushNotify, {
    enable,
  });
};

const getNotify = (from_date, to_date, type) => {
  return customAxios.post(userAPI.getNotify, {
    from_date,
    to_date,
    type,
  });
};

const getReferral = (referral_code) => {
  return customAxios.post(userAPI.getReferral, {
    referral_code,
  });
};

const getListReferal = (user_id) => {
  return customAxios.post(userAPI.getListReferal, {
    user_id,
  });
};

const fetchListReferal = (payload) => {
  return customAxios.post(userAPI.getListReferal, payload);
};

const profileUpdate = (name, displayname, gender, dob, phone, email, country_id) => {
  return customAxios.post(userAPI.profileUpdate, {
    name,
    displayname,
    gender,
    dob,
    phone,
    email,
    country_id,
  });
};

const registerPartner = (partner_type) => {
  return customAxios.post(userAPI.registerPartner, {
    partner_type,
  });
};

export default {
  getProfileUser,
  logoutUser,
  resetPassword,
  getKYC,
  updateKYCPersonalInfo,
  updateKYCAddressInfo,
  uploadKYCDocument,
  uploadAvatar,
  addToken,
  removeToken,
  pushNotify,
  getNotify,
  getReferral,
  getListReferal,
  fetchListReferal,
  profileUpdate,
  registerPartner,
};
