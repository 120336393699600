export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LIST_COUNTRIES_SUCCESS = "LIST_COUNTRIES_SUCCESS";
export const LIST_COUNTRIES_FAIL = "LIST_COUNTRIES_FAIL";

export const EMAIL_ADDRESS = "EMAIL_ADDRESS";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const CHANGE_FORGOT_PASSWORD_SUCCESS = "CHANGE_FORGOT_PASSWORD_SUCCESS";
export const CHANGE_FORGOT_PASSWORD_FAIL = "CHANGE_FORGOT_PASSWORD_FAIL";

export const RESEND_CODE_REGISTER_SUCCESS = "RESEND_CODE_REGISTER_SUCCESS";
export const RESEND_CODE_REGISTER_FAIL = "RESEND_CODE_REGISTER_FAIL";

export const LOGOUT = "LOGOUT";
export const LOG_OUT_USER = "LOG_OUT_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_STYLE_HEADING = "SET_STYLE_HEADING";

export const GET_WINNERS_SUCCESS = "GET_WINNERS_SUCCESS";
export const GET_WINNERS_FAIL = "GET_WINNERS_FAIL";

export const GET_DRAW_SUCCESS = "GET_DRAW_SUCCESS";
export const GET_DRAW_FAIL = "GET_DRAW_FAIL";

export const FETCH_PHASE_SUCCESS = "FETCH_PHASE_SUCCESS";
export const FETCH_PHASE_FAIL = "FETCH_PHASE_FAIL";
export const FETCH_TIME_PHASE_SUCCESS = "FETCH_TIME_PHASE_SUCCESS";
export const FETCH_TIME_PHASE_FAIL = "FETCH_TIME_PHASE_FAIL";

export const FETCH_PROFILE_USER_SUCCESS = "FETCH_PROFILE_USER_SUCCESS";
export const FETCH_PROFILE_USER_FAIL = "FETCH_PROFILE_USER_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const FETCH_ORDER_HISTORY_SUCCESS = "FETCH_ORDER_HISTORY_SUCCESS";
export const FETCH_ORDER_HISTORY_FAIL = "FETCH_ORDER_HISTORY_FAIL";

export const BUY_TICKET_SUCCESS = "BUY_TICKET_SUCCESS";
export const BUY_TICKET_FAIL = "BUY_TICKET_FAIL";
export const POST_ID_CARD = "POST_ID_CARD";
export const CHOOSE_NUMBER = "CHOOSE_NUMBER";
export const NUMBER_CARD = "NUMBER_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const CHANGE_PRICE = "CHANGE_PRICE";
export const VALIDATION_MAX_GAME = "VALIDATION_MAX_GAME";
export const CLEAR_VALIDATION_MAX_GAME = "CLEAR_VALIDATION_MAX_GAME";

export const FETCH_RATE_SUCCESS = "FETCH_RATE_SUCCESS";
export const FETCH_RATE_FAIL = "FETCH_RATE_FAIL";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAIL = "FETCH_PAYMENT_METHODS_FAIL";
export const FETCH_TRANSACTION_HISTORY_SUCCESS = "FETCH_TRANSACTION_HISTORY_SUCCESS";
export const FETCH_TRANSACTION_HISTORY_FAIL = "FETCH_TRANSACTION_HISTORY_FAIL";
export const FETCH_TRANSACTION_DETAIL_HISTORY_SUCCESS = "FETCH_TRANSACTION_DETAIL_HISTORY_SUCCESS";
export const FETCH_TRANSACTION_DETAIL_HISTORY_FAIL = "FETCH_TRANSACTION_DETAIL_HISTORY_FAIL";

export const POST_WITHDRAW_SUCCESS = "POST_WITHDRAW_SUCCESS";
export const POST_WITHDRAW_FAIL = "POST_WITHDRAW_FAIL";

export const GET_KYC_SUCCESS = "GET_KYC_SUCCESS";
export const GET_KYC_FAIL = "GET_KYC_FAIL";
export const UPDATE_KYC_PERSONAL_SUCCESS = "UPDATE_KYC_PERSONAL_SUCCESS";
export const UPDATE_KYC_PERSONAL_FAIL = "UPDATE_KYC_PERSONAL_FAIL";
export const UPDATE_KYC_ADDRESS_SUCCESS = "UPDATE_KYC_ADDRESS_SUCCESS";
export const UPDATE_KYC_ADDRESS_FAIL = "UPDATE_KYC_ADDRESS_FAIL";
export const UPDATE_KYC_DOCUMENT_SUCCESS = "UPDATE_KYC_DOCUMENT_SUCCESS";
export const UPDATE_KYC_DOCUMENT_FAIL = "UPDATE_KYC_DOCUMENT_FAIL";

export const RESET_FLAG_VALIDATION = "RESET_FLAG_VALIDATION";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const CREATE_TICKET = "CREATE_TICKET";

export const FETCH_LIST_POSTS_SUCCESS = "FETCH_LIST_POSTS_SUCCESS";
export const FETCH_LIST_POSTS_FAIL = "FETCH_LIST_POSTS_FAIL";
export const FETCH_POST_DETAIL_SUCCESS = "FETCH_POST_DETAIL_SUCCESS";
export const FETCH_POST_DETAIL_FAIL = "FETCH_POST_DETAIL_FAIL";

export const ADD_TO_CART = "ADD_TO_CART";

export const POST_WITHDRAW_VERIFY_SUCCESS = "POST_WITHDRAW_VERIFY_SUCCESS";
export const POST_WITHDRAW_VERIFY_FAIL = "POST_WITHDRAW_VERIFY__FAIL";
export const GET_LOCAL_CART = "GET_LOCAL_CART";
export const CLEAR_CART_SHOP = "CLEAR_CART_SHOP";

export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_AVATAR_FAIL = "UPLOAD_AVATAR_FAIL";
export const UPDATE_AVA = "UPDATE_AVA";

export const LOADING_SUCCESS = "LOADING_SUCCESS";
export const LOADING_FAIL = "LOADING_FAIL";

export const ADD_TOKEN = "ADD_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const PUSH_NOTiFY = "PUSH_NOTiFY";

export const GET_NOTIFY_WALLET = "GET_NOTIFY_WALLET";
export const GET_NOTIFY_OTHER = "GET_NOTIFY_OTHER";

export const IS_NOTIFY_SUCCESS = "IS_NOTIFY_SUCCESS";
export const IS_NOTIFY_FAIL = "IS_NOTIFY_FAIL";

export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAIL = "GET_REFERRAL_FAIL";

export const GET_LIST_REFERRAL_SUCCESS = "GET_LIST_REFERRAL_SUCCESS";
export const GET_LIST_REFERRAL_FAIL = "GET_LIST_REFERRAL_FAIL";

export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";

export const SUBSCRIBE_UPDATE_SUCCESS = "SUBSCRIBE_UPDATE_SUCCESS";
export const SUBSCRIBE_UPDATE_FAIL = "SUBSCRIBE_UPDATE_FAIL";

export const CHANGE_DISPLAY_NAME = "CHANGE_DISPLAY_NAME";

export const REGISTER_PARTNER_SUCCESS = "REGISTER_PARTNER_SUCCESS";
export const REGISTER_PARTNER_FAIL = "REGISTER_PARTNER_FAIL";

export const ACTIVE_PARTNER = "ACTIVE_PARTNER";
export const GET_TOTAL_DRAW = "GET_TOTAL_DRAW";

export const GET_FEE_TRANSFER = "GET_FEE_TRANSFER";
export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS";
export const TRANSFER_FAIL = "TRANSFER_FAIL";

export const GET_TICKET_REMAIN = " GET_TICKET_REMAIN";

export const EXCHANGE_SUCCESS = "EXCHANGE_SUCESS";
export const EXCHANGE_FAIL = "EXCHANGE_FAIL";

export const GET_EXCHANGE_HISTORY = "GET_EXCHANGE_HISTORY";

export const BUY_GAME_SUCCESS = "BUY_GAME_SUCCESS";
export const BUY_GAME_FAIL = "BUY_GAME_FAIL";

export const GET_GAME_MAX_MIN_SUCCEES = "GET_GAME_MAX_MIN_SUCCEES";
export const GET_GAME_MAX_MIN_FAIL = "GET_GAME_MAX_MIN_FAIL";

export const GET_GAME_EVEN_ODD_SUCCEES = "GET_GAME_EVEN_ODD_SUCCEES";
export const GET_GAME_EVEN_ODD_FAIL = "GET_GAME_EVEN_ODD_FAIL";

export const VERIFY_PARTNER_SUCCESS = "VERIFY_PARTNER_SUCCESS";
export const VERIFY_PARTNER_FAIL = "VERIFY_PARTNER_FAIL";

export const GET_CONTRACT_LOTTERY_SUCCESS = "GET_CONTRACT_LOTTERY_SUCCESS";
export const GET_CONTRACT_LOTTERY_FAIL = "GET_CONTRACT_LOTTERY_FAIL";

export const UPDATE_USER = "UPDATE_USER";
