import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: "new-products-dots",
};

function NewProduct() {
  const { listPost } = useSelector((state) => state.post);
  const { t } = useTranslation();
  const history = useHistory();

  const renderCardNews = () => {
    return listPost?.map((item, index) => {
      return (
        <div key={index}>
          <div className="grid grid-cols-2">
            <div className="lg:col-span-1 lg:pr-4 col-span-2 content-card">
              <div className="title-product text-center lg:text-left">
                <span>{t("NEWS")}</span>
              </div>
              <div className="name-product text-center lg:text-left">
                <span>{t(item.title)}</span>
              </div>
              <div className="desciption-product text-center lg:text-left">
                <span>{t(item.description)}</span>
              </div>
              <div className="button-more flex lg:block justify-center">
                <button
                  onClick={() => {
                    history.push(`/news-detail/${item.id}`);
                  }}
                >
                  {t("Learn More")}
                </button>
              </div>
            </div>

            <div className="lg:col-span-1 col-span-2 img-newproduct">
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="lottery-jackpot">
        <div className="container mb-20 text-center" style={{ paddingTop: "3.188rem" }}>
          <h3
            className="font-title-section font-bold"
            style={{ marginBottom: "0rem" }}
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {t("New Coming Product")}
          </h3>
          <p
            className="home-title xl:mx-80 mx-0"
            style={{ marginBottom: "3rem" }}
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="100"
          >
            {t(
              "With a diverse ecosystem being built, together we will develop the world's largest community"
            )}
          </p>
          <div
            className=" items-center frame-buy-ticket"
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="200"
          >
            <div className="md:w-11/12 w-full container-product  margin-auto">
              <Slider {...settings}>{renderCardNews()}</Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewProduct;
