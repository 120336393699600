import { yupResolver } from "@hookform/resolvers/yup";
import { emailAddress, login } from "actions/auth";
import LogoPage from "assets/images/LogoPage.png";
import IconShowPass from "assets/svg/IconShowPass";
import { Button, Icon } from "modules";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import "./style.scss";
import { NavLink } from "component/navlink";
import { Config } from "configs";

const schema = yup.object().shape({
  email: yup.string().email("This is not a valid email.").trim().required("Email is required."),
  password: yup
    .string()
    .min(8, "Password must be between 8 and 40 characters.")
    .max(40, "Password must be between 8 and 40 characters.")
    .required("Password is required."),
});

function FormLogin(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const { message } = useSelector((state) => state.message);
  const [errSubmit, setErrSubmit] = useState(false);
  const isActive = useSelector((state) => state.auth.isActive);
  const [showPass, setShowPass] = useState(false);

  const emailRef = useRef();
  const passwordlRef = useRef();

  // const success = () => toast.success("Resend code successfully");
  const error = (message) => toast.error(message);
  const dismiss = () => toast.dismiss();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const { email, password } = data;

    setErrSubmit(false);
    setLoading(true);

    dispatch(login(email, password))
      .then(() => {
        setLoading(false);
        setErrSubmit(false);
        localStorage.setItem("remember", JSON.stringify(checked));
        props.onFinish();
        // history.push("/");
      })
      .catch(() => {
        dispatch(emailAddress(email));
        setErrSubmit(true);
        setLoading(false);
      });
    dismiss();
  };

  useEffect(() => {
    if (errSubmit) {
      error(message);
      (message === "Email not found" || message === "Unverified email") && emailRef.current.focus();
      message === "Password is wrong" && passwordlRef.current.focus();
      if (isActive) {
        history.push("/otp");
      }
    }
  }, [message, errSubmit, isActive, history]);

  return (
    <div className="form-login mx-auto">
      <img src={LogoPage} alt="LOT" className="mx-auto" />
      <h2 className="form-title text-center p-0">{t("Welcome back!")}</h2>
      <h3 className="form-content text-center p-0">{t("We're so excited to see you again!")}</h3>
      <h3 className="form-content text-center p-0 mb-6">{t("Log In to your MMLotto !!")}</h3>

      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="form-input p-field mb-8">
          <label ref={emailRef} htmlFor="email">
            {t("Email Address")}
          </label>
          <InputText type="text" name="email" id="email" placeholder={t("Enter your email")} {...register("email")} />
          <div className="err-box">
            <small className={errors.email && "err-message"}>{t(errors.email?.message)}</small>
          </div>
        </div>

        <div className="form-input p-field mb-6">
          <label ref={passwordlRef} htmlFor="password">
            {t("Your Password")}
          </label>
          <span className="p-input-icon-right">
            <InputText
              type={!showPass ? "password" : "text"}
              name="password"
              id="password"
              placeholder={t("Enter your password")}
              {...register("password")}
            />
            <i className="cursor-pointer" onClick={() => setShowPass(!showPass)}>
              {showPass ? <IconShowPass /> : <Icon.HidePass />}
            </i>
            {/* <i
                onClick={() => setShowPass(!showPass)}
                className={`pi ${showPass ? "pi-eye" : "pi-eye-slash "} cursor-pointer`}
              ></i> */}
          </span>
          <div className="err-box">
            <small className={errors.password && "err-message"}>{t(errors.password?.message)}</small>
          </div>
        </div>

        {/* <div className=" sm:flex items-center justify-between mb-6 sm:mb-8">
          <div className="form-checkbox p-field-checkbox flex items-center">
            <Checkbox inputId="rememberMe" checked={checked} onChange={(e) => setChecked(e.checked)} />
            <label htmlFor="rememberMe" className="ml-2.5 mt-0">
              {t("Remember me?")}
            </label>
          </div>
        </div> */}

        <div className=" p-field text-center ">
          <Button className="mb-0" type="submit" label={t("Login")} buttonType="primary" isLoading={loading} />
        </div>

        <div className="text-center mt-2 sm:m-0">
          <a href={`${Config.X_THIRD_PARTY_BASE_URL}/forgot-password`} className="forgot-pass underline">
            {t("Forgotten your password?")}
          </a>
        </div>
      </form>
    </div>
  );
}

FormLogin.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default FormLogin;
