import React from "react";
import "./style.scss";
import { Dialog } from "primereact/dialog";
import Clipboard from "react-clipboard.js";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Diamond from "assets/images/Diamond.png";
import { Icon } from "modules";

function ModalDetailHistory(props) {
  const created_at = new Date(props.orderHistoryDetail?.[0]?.created_at);
  const { t } = useTranslation();

  const valuePeriod = (period) => {
    switch (period) {
      case 1:
        return "One-Time";
      case 2:
        return "Week";
      case 3:
        return "Month";
      case 4:
        return "Quarter";
      case 5:
        return "Year";
      default:
        return;
    }
  };

  const copyText = () => {
    toast.success("Copy Successfully");
  };

  const itemTicket = (items) => {
    return items?.map((item, index) => {
      return (
        <div key={index} className="mt-4 container-tickets flex justify-between">
          <div className="img-ticket">
            <img src={item.image_url} alt="" />
          </div>
          <div className="ml-4 sm:w-4/5 w-3/4">
            <div className="">
              <div className="text-blue flex justify-end">
                <span>{item.phase_code}</span>
                <div className="ml-2">
                  <Clipboard data-clipboard-text={item.phase_code} onClick={() => copyText()}>
                    <Icon.Copy />
                  </Clipboard>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="price-ticket">
                {props.orderHistoryDetail[0].currency === "diamond" ? (
                  <div className="flex items-center">
                    <img src={Diamond} alt="" className="mr-1" style={{ height: "1.25rem", width: "1.25rem" }} />
                    <span>{Number(item.price).toFixed(2)}</span>
                  </div>
                ) : (
                  <span>${Number(item.price).toFixed(2)}</span>
                )}
              </div>
              <div>
                {item.quantity} {t("Tickets")}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="name-ticket">{`${process.env['REACT_APP_X_THIRD_PARTY_NAME']}`}</div>
              <div className="number-ticket">#{item.ticket_number}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <Dialog
      visible={props.visible}
      onHide={() => props.onClickCloseDialog()}
      dismissableMask={true}
      showHeader={false}
      closable={false}
      className="modal-detail__history"
    >
      <button className="btn-close p-2" onClick={() => props.onClickCloseDialog()}>
        <span className=" pi pi-times"></span>
      </button>
      <h2 className="title-modal">{t("Order details")}</h2>
      <div className="container-modal mt-4">
        <div className="flex justify-between border-bottom-item">
          <div className="text-item">
            <span>{t("Order ID")}</span>
          </div>
          <div className="text-blue flex">
            <span>{props.orderHistoryDetail?.[0].id}</span>
            <div className="ml-2 middle">
              <Clipboard data-clipboard-text={props.orderHistoryDetail?.[0].id} onClick={() => copyText()}>
                <Icon.Copy />
              </Clipboard>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-6 border-bottom-item">
          <div className="text-item">
            <span>{t("Receipt Number")}</span>
          </div>
          <div className="text-blue flex">
            <span>{props.orderHistoryDetail?.[0].receipt_number}</span>
            <div className="ml-2">
              <Clipboard data-clipboard-text={props.orderHistoryDetail?.[0].receipt_number} onClick={() => copyText()}>
                <Icon.Copy />
              </Clipboard>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-6 border-bottom-item">
          <div className="text-item">
            <span>{t("Quantity")}</span>
          </div>
          <div className="text-normal">
            <span>{props.orderHistoryDetail?.[0].Quantity === 0 ? 1 : props.orderHistoryDetail?.[0].Quantity}</span>
          </div>
        </div>

        <div className="flex justify-between mt-6 border-bottom-item">
          <div className="text-item">
            <span>{t("Time")}</span>
          </div>
          <div className="text-normal">
            <span>{moment.utc(created_at).local().format("HH:mm LL")}</span>
          </div>
        </div>

        <div className="flex justify-between mt-6 border-bottom-item">
          <div className="text-item">
            <span>{t("Period")}</span>
          </div>
          <div className="text-normal">
            <span>{valuePeriod(props.orderHistoryDetail?.[0].plan_type)}</span>
          </div>
        </div>

        <div className="flex justify-between mt-6 border-bottom-item">
          <div className="text-item">
            <span>{t("Total")}</span>
          </div>
          <div className="text-normal">
            {props.orderHistoryDetail?.[0].currency === "USD" ? (
              <span>${Number(props.orderHistoryDetail?.[0].order_total).toFixed(2)}</span>
            ) : (
              <span className="flex items-center justify-center">
                <img src={Diamond} alt="" />
                <p className="ml-1">{Number(props.orderHistoryDetail?.[0].order_total).toFixed(2)}</p>
              </span>
            )}
          </div>
        </div>
        {props.orderHistoryDetail?.[0].tickets.length > 0 && (
          <div className="mt-6">
            <div className="text-item">
              <span>{t("Tickets")}</span>
            </div>
            {itemTicket(props.orderHistoryDetail?.[0].tickets)}
          </div>
        )}
        {props.orderHistoryDetail?.[0].games.length > 0 && (
          <>
            <div className="flex justify-between mt-6 border-bottom-item">
              <div className="text-item">
                <span>{t("Game")}</span>
              </div>
              <div className="text-normal">
                <span>{Number(props.orderHistoryDetail?.[0].games[0].type_game) === 1 ? "Max/Min" : "Even/Odd"}</span>
              </div>
            </div>

            <div className="flex justify-between mt-6 border-bottom-item">
              <div className="text-item">
                <span>{t("Type")}</span>
              </div>
              <div className="text-normal">
                <span>{props.orderHistoryDetail?.[0].games[0].value}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default ModalDetailHistory;
