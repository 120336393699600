import React from "react";

function IconPartnerFail() {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="65" cy="65" r="65" fill="#FFF6E7" />
      <path
        d="M64 97C81.6731 97 96 82.6731 96 65C96 47.3269 81.6731 33 64 33C46.3269 33 32 47.3269 32 65C32 82.6731 46.3269 97 64 97Z"
        fill="url(#paint0_linear_1683:20854)"
        stroke="white"
        stroke-width="2"
      />
      <path
        opacity="0.3"
        d="M52.667 72.324L54.3245 70.6665H75.3337V53.6665H52.667V72.324ZM62.5837 56.4998H65.417V62.1665H62.5837V56.4998ZM62.5837 64.9998H65.417V67.8332H62.5837V64.9998Z"
        fill="white"
      />
      <path
        d="M75.333 50.8335H52.6663C51.108 50.8335 49.833 52.1085 49.833 53.6668V79.1668L55.4997 73.5002H75.333C76.8913 73.5002 78.1663 72.2252 78.1663 70.6668V53.6668C78.1663 52.1085 76.8913 50.8335 75.333 50.8335ZM75.333 70.6668H54.3238L52.6663 72.3243V53.6668H75.333V70.6668ZM62.583 65.0002H65.4163V67.8335H62.583V65.0002ZM62.583 56.5002H65.4163V62.1668H62.583V56.5002Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1683:20854"
          x1="64"
          y1="97"
          x2="64"
          y2="28.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F53B00" />
          <stop offset="1" stop-color="#FE6D1C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconPartnerFail;
