import React from "react";

function IconPartnerSuccess() {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="65" cy="65" r="65" fill="#FFF6E7" />
      <path
        d="M64 97C81.6731 97 96 82.6731 96 65C96 47.3269 81.6731 33 64 33C46.3269 33 32 47.3269 32 65C32 82.6731 46.3269 97 64 97Z"
        fill="url(#paint0_linear)"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M73.8832 56.3521C74.2414 56.059 74.6642 55.8291 75.1274 55.6754C75.5905 55.5218 76.0848 55.4475 76.5819 55.4568C77.0789 55.4662 77.5689 55.559 78.0236 55.7299C78.4783 55.9008 78.8888 56.1464 79.2314 56.4526C79.925 57.0726 80.3018 57.9007 80.2795 58.7561C80.2571 59.6115 79.8376 60.4246 79.1123 61.0179L62.2769 74.771C61.8662 75.1067 61.3715 75.359 60.8297 75.509C60.288 75.6589 59.7133 75.7026 59.1489 75.6368C58.5844 75.571 58.0448 75.3974 57.5706 75.1291C57.0963 74.8607 56.6998 74.5045 56.4106 74.0872L49.3955 64.0137C49.135 63.6506 48.9618 63.2473 48.8858 62.827C48.8097 62.4067 48.8325 61.9777 48.9526 61.5649C49.0728 61.1521 49.2879 60.7636 49.5857 60.4219C49.8835 60.0802 50.258 59.7921 50.6876 59.5741C51.1172 59.3561 51.5933 59.2127 52.0885 59.152C52.5836 59.0914 53.088 59.1147 53.5725 59.2208C54.057 59.3268 54.512 59.5133 54.9111 59.7697C55.3103 60.026 55.6458 60.3471 55.8981 60.7142L60.4837 67.2988L73.8832 56.3521Z"
        fill="#040302"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="64"
          y1="5.48"
          x2="64"
          y2="97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5C300" />
          <stop offset="1" stop-color="#FFE683" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconPartnerSuccess;
