import React from "react";
import "./style.scss";
import { Dialog } from "primereact/dialog";
import Clipboard from "react-clipboard.js";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import moment from "moment";
import Diamond from "assets/images/Diamond.png";
import { Icon } from "modules";

function ModalHistory(props) {
  const { t } = useTranslation();

  const copyText = () => {
    toast.success("Copy Successfully");
  };

  return (
    <Dialog visible={props.dialog} onHide={() => props.closeModal()} dismissableMask={true} showHeader={false} className="modal-wallet__history">
      <button className="btn-close p-2" onClick={() => props.closeModal()}>
        <span className=" pi pi-times"></span>
      </button>
      <h2 className="title-modal">{t("Transaction Details")}</h2>
      <div className="container-modal mt-4">
        <div className="border-bottom-item">
          <div className="text-item">
            <span>{t("Transaction Hash")}</span>
          </div>
          <div className="flex justify-between mt-3 container-copy">
            <div className="w-11/12 truncate ">
              <span className="idTransaction">{props.transactionDetail?.tran_hash}</span>
            </div>
            <div className="ml-2 middle">
              <Clipboard data-clipboard-text={props.transactionDetail?.tran_hash} onClick={() => copyText()}>
                <Icon.Copy />
              </Clipboard>
            </div>
          </div>
        </div>

        <div className="flex justify-between  border-bottom-item">
          <div className="text-item">
            <span>{t("Type")}</span>
          </div>
          <div className="text-normal container-type background-type text-center">
            <span>
              {t(
                props.transactionDetail?.tran_type === "buyticket"
                  ? "Buy Ticket"
                  : props.transactionDetail?.tran_type === "buyGameEvenOdd"
                  ? "Buy Game Even Odd"
                  : props.transactionDetail?.tran_type === "buyGameMinMax"
                  ? "Buy Game Min Max"
                  : props.transactionDetail?.tran_type === "buydiamond"
                  ? "Buy Diamond"
                  : props.transactionDetail?.tran_type === "winningcommission"
                  ? "Winning Commission"
                  : props.transactionDetail?.tran_type === "ticketcommission"
                  ? "Ticket Commission"
                  : props.transactionDetail?.tran_type === "vip5commissiom"
                  ? "VIP5 Commission"
                  : props.transactionDetail?.tran_type === "drawgame"
                  ? "Draw Game"
                  : props.transactionDetail?.tran_type
              )}
            </span>
          </div>
        </div>

        <div className="flex justify-between  border-bottom-item">
          <div className="text-item">
            <span>{t("Status")}</span>
          </div>
          <div className="text-normal container-type background-status text-center">
            <span>{t(props.transactionDetail?.status)}</span>
          </div>
        </div>

        <div className="flex justify-between items-center border-bottom-item">
          <div className="text-item">
            <span>{t("Time")}</span>
          </div>
          <div className="text-normal">
            <span> {moment.utc(props.transactionDetail?.created_at).format("HH:mm")}</span>

            <span className="ml-2 ">{moment.utc(props.transactionDetail?.created_at).format("LL")}</span>
          </div>
        </div>

        {(props.transactionDetail?.tran_from != null || props.transactionDetail?.email_from != null) && (
          <div className=" border-bottom-item">
            <div className="text-item">
              <span>{t("From")}</span>
            </div>
            <div className="flex justify-between mt-3 container-copy">
              <div className="w-11/12 truncate ">
                <span className="idTransaction">
                  {props.transactionDetail?.email_from != null ? props.transactionDetail?.email_from : props.transactionDetail?.tran_from}
                </span>
              </div>
              <div className="ml-2 middle">
                <Clipboard
                  data-clipboard-text={
                    props.transactionDetail?.email_from != null ? props.transactionDetail?.email_from : props.transactionDetail?.tran_from
                  }
                  onClick={() => copyText()}
                >
                  <Icon.Copy />
                </Clipboard>
              </div>
            </div>
          </div>
        )}

        {((props.transactionDetail?.tran_to != null && props.transactionDetail?.tran_to !== "") || props.transactionDetail?.email_to != null) && (
          <div className=" border-bottom-item">
            <div className="text-item">
              <span>{t("To")}</span>
            </div>
            <div className="flex justify-between mt-3 container-copy">
              <div className="w-11/12 truncate ">
                <span className="idTransaction">
                  {props.transactionDetail?.email_to != null ? props.transactionDetail?.email_to : props.transactionDetail?.tran_to}
                </span>
              </div>
              <div className="ml-2 middle">
                <Clipboard
                  data-clipboard-text={
                    props.transactionDetail?.email_to != null ? props.transactionDetail?.email_to : props.transactionDetail?.tran_to
                  }
                  onClick={() => copyText()}
                >
                  <Icon.Copy />
                </Clipboard>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between  border-bottom-item">
          <div className="text-item">
            <span>{t("Value")}</span>
          </div>
          <div className="text-normal">
            {Number(props.transactionDetail?.tran_value) !== 0 ? (
              <span>
                {props.transactionDetail?.coin_name === ""
                  ? "$" + Number(props.transactionDetail?.tran_value).toFixed(2)
                  : Number(props.transactionDetail?.tran_value).toFixed(2) + " " + props.transactionDetail?.coin_name}
              </span>
            ) : (
              <span className="flex items-center justify-center">
                <img src={Diamond} alt="" />
                <span className="ml-1">{Number(props.transactionDetail?.tran_diamond_value).toFixed(2)}</span>
              </span>
            )}
          </div>
        </div>

        {props.transactionDetail?.tran_fee && (
          <div className="flex justify-between  border-bottom-item">
            <div className="text-item">
              <span>Transaction Fee</span>
            </div>
            <div className="text-normal">
              <span>{props.transactionDetail?.tran_fee + " " + props.transactionDetail?.coin_name}</span>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default ModalHistory;
