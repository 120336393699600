import React, { useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import ModalPartner from "component/modalPartner";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { CLEAR_MESSAGE } from "actions/types";
import { Icon } from "modules";
// import { CLEAR_MESSAGE } from "actions/types";

function Affliate() {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const isRegisterPartner = useSelector((state) => state.user.isRegisterPartner);
  const message = useSelector((state) => state.message.message);
  const dispatch = useDispatch();

  const closeModal = () => {
    setDisplayModal(false);
  };

  useEffect(() => {
    if (message)
      isRegisterPartner
        ? toast.success(message, {
            style: {
              width: "fit-content",
              maxWidth: "none",
            },
          })
        : toast(message, {
            style: {
              width: "fit-content",
              maxWidth: "none",
            },
          });
  }, [isRegisterPartner, message]);

  return (
    <div className="Affliate">
      <div className="container text-center">
        <h3 className="font-title-section font-bold" data-aos="fade-up" data-aos-duration="600">
          {t("Affiliate On Home Page")}
        </h3>
        <p
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-delay="100"
          className="home-title xl:mx-72 mx-0"
          style={{ marginBottom: "2.875rem", marginTop: "0.813rem" }}
        >
          {t(
            "Tell a friend is our unique lucky gaming friend promotion club that enables you to earn amazing bonus money rewaerds for inviting friends to play the word's biggest jackpots!"
          )}
        </p>
        <div className="lg:flex justify-center margin-auto" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
          <div className="sm:flex justify-center">
            <div className="container-content xl:mr-9 mr-4 mt-4 lg:mt-0">
              <div className="imageCircle">
                <Icon.Revenue />
              </div>
              <div className="name-content">{t("High Revenues")}</div>
              {/* <div className="description-content">
                {t('Tell a friend is our unique lottery friend promotion club that enables')}
              </div> */}
            </div>
            <div className="container-content xl:mr-9 mr-4 mt-4 lg:mt-0 ">
              <div className="imageCircle">
                <Icon.CreditCard />
              </div>
              <div className="name-content">{t("Reliable Payments")}</div>
              {/* <div className="description-content">
                  {t('Tell a friend is our unique lottery friend promotion club that enables')}
              </div> */}
            </div>
          </div>
          <div className="sm:flex justify-center">
            <div className="container-content xl:mr-9 mr-4 mt-4 lg:mt-0 ">
              <div className="imageCircle">
                <Icon.AffiliateMarketing />
              </div>
              <div className="name-content">{t("Unlimited Affilicates")}</div>
              {/* <div className="description-content">
                  {t('Tell a friend is our unique lottery friend promotion club that enables')}
              </div> */}
            </div>
            <div className="container-content xl:mr-9 mr-4 mt-4 lg:mt-0 ">
              <div className="imageCircle">
                <Icon.HeadPhone />
              </div>
              <div className="name-content">{t("Dedicated Support")}</div>
              {/* <div className="description-content">
                  {t('Tell a friend is our unique lottery friend promotion club that enables')}
              </div> */}
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
          <p className="home-title xl:mx-72 mx-0" style={{ marginBottom: "1.75rem", marginTop: "2.875rem" }}>
            {t(
              "Tell a friend is our unique lucky gaming friend promotion club that enables you to earn amazing bonus money rewaerds for inviting friends to play the word's biggest jackpots!"
            )}
          </p>
          <button
            className="btn-register mb-36"
            onClick={() => {
              dispatch({
                type: CLEAR_MESSAGE,
              });
              setDisplayModal(true);
            }}
          >
            Go to
          </button>
        </div>
        <ModalPartner closeModal={closeModal} displayModal={displayModal} />
      </div>
    </div>
  );
}

export default Affliate;
