import React from "react";
import "./style.scss";
import Buy from "assets/images/Buy.svg";
import Win from "assets/images/Win.svg";
import Choose from "assets/images/Choose.svg";
import { useTranslation } from "react-i18next";
import Ex1 from "assets/images/Exclude1.svg";
import Ex2 from "assets/images/Exclude2.svg";

function Operate() {
  const { t } = useTranslation();

  return (
    <div className="operate">
      <div className="container text-center">
        <h3 className="font-title-section font-bold" data-aos="fade-up" data-aos-duration="600">{t("How It Works")}</h3>
        <p className="home-title xl:mb-36 mb-20" style={{ marginTop: "0.313rem" }} data-aos="fade-up" data-aos-duration="600" data-aos-delay="100">
          {t(
            "mmlotto is the best way to play these exciting lucky gaming from anywhere in the world."
          )}
        </p>
        <div className="lg:flex lg:justify-center lg:items-center xl:gap-14 lg:gap-4">
          <div className="operate-item" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
            <img src={Ex1} alt="" className="backgroud-image" />
            <img src={Choose} alt="Choose" className="image" />
            <h3>{t("Choose")}</h3>
            <p className="xl:px-20">{t("Choose your game & pick your lucky number")}</p>
          </div>
          <div className="operate-item xl:mt-0 mt-4" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
            <img src={Ex2} alt="" className="backgroud-image-2" />
            <img style={{ marginBottom: "4.5rem" }} src={Buy} alt="Buy" className="image-2" />
            <h3>{t("Play")}</h3>
            <p className="xl:px-24 lg:px-20">{t("Complete your purchase")}</p>
          </div>
          <div className="operate-item" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
            <img src={Ex1} alt="" className="backgroud-image" />
            <img src={Win} alt="Win" className="image" />
            <h3>{t("Win")}</h3>
            <p className="xl:px-20 lg:px-14">{t("Start dreaming, you're almost there")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Operate;
