export const useFormStructureItemDefaults = {
  isDisabled: false,
  isVisible: true,
  isMutilLocale: false,
};

export const inputPropsDefaults = {
  isDisabled: false,
  isMutilLocale: false,
  tabIndex: 0,
  showImage: false,
  isClear: false,
  limitSize: 0,
  limitWidth: 0,
  limitHeight: 0,
  temp: 0,
  decimalScale: 0,
  isSkeletonLoading: false,
};

export const useFormExportPropsDefaults = {
  isSubmitting: false,
  isValid: true,
};

export const useFormConfigsDefaults = {
  isDebug: false,
  enableReinitialize: false,
};

export const inputWraperPropsDefaults = {
  isVisible: true,
  isRequired: false,
  isDisabledAutoFill: false,
  isDisable: false,
  showImage: false,
  isClear: false,
  limitSize: 0,
  limitWidth: 0,
  limitHeight: 0,
  decimalScale: 0,
};