export const ELocale = {
  ENGLISH: "en-US",
  VIETNAM: "vi-VN",
  KOREAN: "ko-KR",
  CHINA: "zh-CN",
  INDIA: "hi-IN",
  INDONESIA: "id-ID",
  FRENCH: "fr-FR",
  SPAIN: "es-ES",
  RUSSIAN: "ru-RU",
  MYANMAR: "my-MM",
};

/**
 * Firebase configuration shape.
 * @typedef {Object} IFirebaseConfig
 * @property {string} apiKey
 * @property {string} authDomain
 * @property {string} databaseURL
 * @property {string} projectId
 * @property {string} storageBucket
 * @property {string} messagingSenderId
 * @property {string} appId
 * @property {string} measurementId
 */

export const EDeviceType = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile",
};

export const EOrderStatus = {
  OPEN: "OPEN",
  WIN: "WIN",
  LOSE: "LOSE",
  DRAW: "DRAW",
};

export const EOrderOption = {
  HIGHER: "HIGHER",
  LOWER: "LOWER",
};

/**
 * Order shape.
 * @typedef {Object} IOrder
 * @property {number} orderId
 * @property {number} userId
 * @property {number} symbolId
 * @property {number} coinId
 * @property {number} amount
 * @property {number} profit
 * @property {EOrderStatus} status
 * @property {EOrderOption} option
 * @property {Date} created
 * @property {Date} modified
 */

export const ETypeApplication = {
  DOC: "doc",
  DOCX: "docx",
  PDF: "pdf",
  JPG: "jpg",
  PNG: "png",
  JPEG: "jpeg",
};

export const ENetWork = {
  BEP20: "BEP20",
  TRC20: "TRC20",
  QCASH: "QCash",
};

export const EToken = {
  USDT: "USDT",
};