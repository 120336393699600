import {
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_FAIL,
  FETCH_RATE_FAIL,
  FETCH_RATE_SUCCESS,
  FETCH_PAYMENT_METHODS_FAIL,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_TRANSACTION_HISTORY_FAIL,
  FETCH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_TRANSACTION_DETAIL_HISTORY_SUCCESS,
  FETCH_TRANSACTION_DETAIL_HISTORY_FAIL,
  POST_WITHDRAW_SUCCESS,
  POST_WITHDRAW_FAIL,
  SET_MESSAGE,
  POST_WITHDRAW_VERIFY_SUCCESS,
  POST_WITHDRAW_VERIFY_FAIL,
  LOADING_FAIL,
  GET_FEE_TRANSFER,
  TRANSFER_SUCCESS,
  TRANSFER_FAIL,
  EXCHANGE_SUCCESS,
  GET_EXCHANGE_HISTORY,
  GET_CONTRACT_LOTTERY_SUCCESS,
} from "./types";

import WalletService from "../services/wallet.Service";
import { setMessage } from "./message";

export const getOrderHistoryUser = (from_date, to_date, page, pageSize) => (dispatch) => {
  return WalletService.getOrderHistory(from_date, to_date, page, pageSize).then(
    (response) => {
      dispatch({
        type: FETCH_ORDER_HISTORY_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          (error.response.data.message || error.response.data.errors)) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_ORDER_HISTORY_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getRate = () => (dispatch) => {
  return WalletService.getRate().then(
    (response) => {
      dispatch({
        type: FETCH_RATE_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.message || error.response.data.errors)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: FETCH_RATE_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getPaymentMethod = () => (dispatch) => {
  return WalletService.getPaymentMethod().then(
    (response) => {
      dispatch({
        type: FETCH_PAYMENT_METHODS_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.message || error.response.data.errors)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: FETCH_PAYMENT_METHODS_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getTransactionHistory =
  (from_date, to_date, page, pageSize, type, status, address) => (dispatch) => {
    return WalletService.getTransactionHistory(
      from_date,
      to_date,
      page,
      pageSize,
      type,
      status,
      address
    ).then(
      (response) => {
        dispatch({
          type: FETCH_TRANSACTION_HISTORY_SUCCESS,
          payload: response.data.data,
        });

        dispatch({
          type: LOADING_FAIL,
        });

        return Promise.resolve();
      },
      (error) => {
        // const message =
        //   (error.response &&
        //     error.response.data &&
        //     (error.response.data.message || error.response.data.errors)) ||
        //   error.message ||
        //   error.toString();

        dispatch({
          type: FETCH_TRANSACTION_HISTORY_FAIL,
        });

        dispatch({
          type: LOADING_FAIL,
        });

        return Promise.reject();
      }
    );
  };

export const getTransactionDetailHistory = (transaction_id) => (dispatch) => {
  return WalletService.getTransactionDetailHistory(transaction_id).then(
    (response) => {
      dispatch({
        type: FETCH_TRANSACTION_DETAIL_HISTORY_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        // (error.response &&
        //   error.response.data &&
        //   (error.response.data.message || error.response.data.errors)) ||
        // error.message ||
        // error.toString();

        (error.response && error.response.data && error.response.data.errors[0]) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_TRANSACTION_DETAIL_HISTORY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const postWithdraw = (chain, coin_id, address, amount) => (dispatch) => {
  return WalletService.postWithdraw(chain, coin_id, address, amount).then(
    (response) => {
      dispatch({
        type: POST_WITHDRAW_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const error_code =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.error_code || error.response.data.message)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: POST_WITHDRAW_FAIL,
      });

      // dispatch(setMessage("withdraw", error.response.data.message));

      dispatch({
        type: SET_MESSAGE,
        payload: error.response.data.message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const postWithdrawVerify = (chain, coin_id, address, amount) => (dispatch) => {
  return WalletService.postWithdrawVerify(chain, coin_id, address, amount).then(
    (response) => {
      dispatch({
        type: POST_WITHDRAW_VERIFY_SUCCESS,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.error_code || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_WITHDRAW_VERIFY_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch(setMessage("withdraw", error_code));

      return Promise.reject();
    }
  );
};

export const getFee = (type) => (dispatch) => {
  return WalletService.getFee(type).then(
    (response) => {
      dispatch({
        type: GET_FEE_TRANSFER,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const postTransfer = (mail_address, amount) => (dispatch) => {
  return WalletService.transfer(mail_address, amount).then(
    (response) => {
      dispatch({
        type: TRANSFER_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.errors || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: TRANSFER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: error_code,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const exchangeDiamon = (amount) => (dispatch) => {
  return WalletService.exchange(amount).then(
    (response) => {
      dispatch({
        type: EXCHANGE_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.errors || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: TRANSFER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: error_code,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const exchangeHistory = (amount) => (dispatch) => {
  return WalletService.exchangeHistory(amount).then(
    (response) => {
      dispatch({
        type: GET_EXCHANGE_HISTORY,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.errors || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: error_code,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getContractLottery = () => (dispatch) => {
  return WalletService.contractLottery().then(
    (response) => {
      dispatch({
        type: GET_CONTRACT_LOTTERY_SUCCESS,
        payload: response.data.data,
      });
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.reject();
    }
  );
};
