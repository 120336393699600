import {
  LOG_OUT_USER,
  FETCH_PROFILE_USER_FAIL,
  FETCH_PROFILE_USER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_KYC_SUCCESS,
  GET_KYC_FAIL,
  UPDATE_KYC_PERSONAL_SUCCESS,
  UPDATE_KYC_ADDRESS_SUCCESS,
  UPDATE_KYC_ADDRESS_FAIL,
  UPDATE_KYC_PERSONAL_FAIL,
  UPDATE_KYC_DOCUMENT_SUCCESS,
  UPDATE_KYC_DOCUMENT_FAIL,
  CREATE_TICKET,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAIL,
  UPDATE_AVA,
  GET_NOTIFY_WALLET,
  GET_NOTIFY_OTHER,
  IS_NOTIFY_SUCCESS,
  IS_NOTIFY_FAIL,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAIL,
  GET_LIST_REFERRAL_SUCCESS,
  GET_LIST_REFERRAL_FAIL,
  CHANGE_DISPLAY_NAME,
  REGISTER_PARTNER_SUCCESS,
  REGISTER_PARTNER_FAIL,
  ACTIVE_PARTNER,
} from "../actions/types";

const initialState = {
  user: null,
  resetPassword: null,
  kyc: null,
  ticket: [],
  isUploadAva: false,
  ava: null,
  device_token: null,
  notifyWallet: [],
  notifyOther: [],
  isNotify: null,
  countNotify: 0,
  referral: null,
  listReferral: [],
  displayname: null,
  isRegisterPartner: null,
  partner: "",
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_PROFILE_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        resetPassword: null,
      };
    case FETCH_PROFILE_USER_FAIL:
      return {
        ...state,
        user: null,
        resetPassword: null,
      };
    case LOG_OUT_USER:
      return {
        ...state,
        user: null,
        resetPassword: null,
        kyc: null,
        ticket: [],
        isUploadAva: false,
        ava: null,
        device_token: null,
        notifyWallet: [],
        notifyOther: [],
        isNotify: null,
        countNotify: 0,
        referral: null,
        listReferral: [],
        displayname: null,
        isRegisterPartner: false,
        partner: "",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPassword: false,
      };
    case GET_KYC_SUCCESS:
      return {
        ...state,
        kyc: payload,
      };
    case GET_KYC_FAIL:
      return {
        ...state,
        kyc: null,
      };
    case UPDATE_KYC_PERSONAL_SUCCESS:
      return {
        ...state,
        kyc: payload,
      };
    case UPDATE_KYC_PERSONAL_FAIL:
      return {
        ...state,
      };
    case UPDATE_KYC_ADDRESS_SUCCESS:
      return {
        ...state,
        kyc: payload,
      };
    case UPDATE_KYC_ADDRESS_FAIL:
      return {
        ...state,
      };
    case UPDATE_KYC_DOCUMENT_SUCCESS:
      return {
        ...state,
        kyc: payload,
      };
    case UPDATE_KYC_DOCUMENT_FAIL:
      return {
        ...state,
      };
    case CREATE_TICKET:
      const newTicket = {
        id: payload.idTicket,
        date: payload.date,
        subject: payload.subject,
        status: "Pending",
      };
      return {
        ...state,
        ticket: [...state.ticket, newTicket],
      };
    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        isUploadAva: true,
      };
    case UPLOAD_AVATAR_FAIL:
      return {
        ...state,
        isUploadAva: false,
      };
    case UPDATE_AVA:
      return {
        ...state,
        ava: payload,
      };

    case GET_NOTIFY_WALLET:
      return {
        ...state,
        notifyWallet: payload,
      };
    case GET_NOTIFY_OTHER:
      return {
        ...state,
        notifyOther: payload,
      };
    case IS_NOTIFY_SUCCESS:
      let newNotify = state.countNotify;
      newNotify++;
      return {
        ...state,
        isNotify: true,
        countNotify: newNotify,
      };
    case IS_NOTIFY_FAIL:
      return {
        ...state,
        isNotify: false,
        countNotify: 0,
      };
    case GET_REFERRAL_SUCCESS:
      return {
        ...state,
        referral: payload,
      };
    case GET_REFERRAL_FAIL:
      return {
        ...state,
        referral: null,
      };
    case GET_LIST_REFERRAL_SUCCESS:
      return {
        ...state,
        listReferral: payload,
      };
    case GET_LIST_REFERRAL_FAIL:
      return {
        ...state,
        listReferral: null,
      };
    case CHANGE_DISPLAY_NAME:
      return {
        ...state,
        displayname: payload,
      };
    case REGISTER_PARTNER_SUCCESS:
      return {
        ...state,
        isRegisterPartner: true,
      };
    case REGISTER_PARTNER_FAIL:
      return {
        ...state,
        isRegisterPartner: false,
      };
    case ACTIVE_PARTNER:
      return {
        ...state,
        partner: payload,
      };
    default:
      return state;
  }
}
