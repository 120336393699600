import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bannerPhone from "assets/images/bannerPhone.png";
import BannerTime from "component/bannerTime";
import "assets/svg/BackgroundPhone.svg";
import "assets/images/backgroundHeader.png";
import "./style.scss";

function BannerHome(props) {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <div className="home-banner">
        <div className="banner-phone" data-aos="fade-up" data-aos-duration="1000"  ></div>
        <div className="container-content container lg:pl-0 pt-32 pb-32 lg:pb-0 flex items-start">
          <div
            className="banner-content md:mt-36 sm:mt-16 mt-8
                          md:pr-16 lg:pr-10 xl:pr-16"
          >
            <h1 className="text-title font-bold" data-aos="fade-up" data-aos-duration="600">{t("Take the chance to change your life")}</h1>
            <p  data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
              {t(
                "The future of entertainment & online gaming platform. We bring a new level of availability and transparency in the gaming market."
              )}
            </p>
            {/* <Link to="/register" className="flex items-center" data-aos="fade-up" data-aos-duration="600" data-aos-delay="400" >
              <button className="banner-btn">{t("JOIN US")}</button>
            </Link> */}
          </div>
          <div className="banner-img relative" data-aos="fade-up" data-aos-duration="1000"  >
            <img src={bannerPhone} alt="Phone" />
          </div>
        </div>

        <div className="home-time absolute container">
          <div className="">
            <div className="grid grid-cols-11 gap-4 sm:items-center" >
              <BannerTime Countdown={props.Countdown} typeBanner={"Home"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerHome;
