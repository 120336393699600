import {
  FETCH_PHASE_SUCCESS,
  FETCH_PHASE_FAIL,
  FETCH_TIME_PHASE_SUCCESS,
  FETCH_TIME_PHASE_FAIL,
  // SET_MESSAGE,
  LOADING_FAIL,
  GET_GAME_MAX_MIN_SUCCEES,
  GET_GAME_MAX_MIN_FAIL,
  GET_GAME_EVEN_ODD_SUCCEES,
  GET_GAME_EVEN_ODD_FAIL,
} from "./types";

import PhaseService from "../services/phase.service";

export const getPhase = () => (dispatch) => {
  return PhaseService.getPhase().then(
    (response) => {
      dispatch({
        type: FETCH_PHASE_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.message || error.response.data.errors)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: FETCH_PHASE_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getTimePhase = () => (dispatch) => {
  return PhaseService.getTimePhase().then(
    (response) => {
      dispatch({
        type: FETCH_TIME_PHASE_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     (error.response.data.message || error.response.data.errors)) ||
      //   error.message ||
      //   error.toString();

      dispatch({
        type: FETCH_TIME_PHASE_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getGameMaxMin = () => (dispatch) => {
  return PhaseService.getGameMaxMin().then(
    (response) => {
      dispatch({
        type: GET_GAME_MAX_MIN_SUCCEES,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: GET_GAME_MAX_MIN_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getGameEvenOdd = () => (dispatch) => {
  return PhaseService.getGameEvenOdd().then(
    (response) => {
      dispatch({
        type: GET_GAME_EVEN_ODD_SUCCEES,
        payload: response.data.data,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: GET_GAME_EVEN_ODD_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.reject();
    }
  );
};
