import React, { useEffect, useState } from "react";
import { changeDisplayName, logoutUser, removeTokenDevice } from "actions/user";
import NoUser from "assets/images/NoUser.png";
import { Icon } from "modules";
import { Avatar } from "primereact/avatar";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "component/navlink";
import { useLocation, useHistory } from "react-router-dom";
import { Config } from "configs";

function UserMenu({}) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user, ava } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const dsName = useSelector((state) => state.user.displayname);
  const [name, setName] = useState();
  const token = localStorage.getItem("deviceToken");

  useEffect(() => {
    dsName !== null && dsName !== "" ? setName(dsName) : user?.displayname ? setName(user?.displayname) : setName(user?.name);
  }, [dsName, user]);

  return (
    <>
      {["/overview", "/order-history", "/settings", "/affiliate-member", "/kyc", "/deposit", "/withdraw", "/exchange"].includes(location.pathname) ? (
        <div className="header__title">DASHBOARD</div>
      ) : ["/history-jackpot"].includes(location.pathname) ? (
        <div className="header__title">HISTORY JACKPOT</div>
      ) : (
        <div className="wallet">
          <div className="wallet__info">
            <div className="wallet__info__list">
              <div className="wallet__info__list__item">
                <div className="wallet__info__list__item__value">
                  <div className="wallet__info__list__item__value__icon">
                    <img src="/assets/images/coins/usdt.png" alt="usdt" />
                  </div>
                  <div className="wallet__info__list__item__value__text">
                    {Number(user?.wallets[0].balance).toFixed(2)} <span>USDT</span>
                  </div>
                </div>
              </div>
              <div className="wallet__info__list__item">
                <div className="wallet__info__list__item__value">
                  <div className="wallet__info__list__item__value__icon">
                    <img src="/assets/images/coins/mut.png" alt="mut" />
                  </div>
                  <div className="wallet__info__list__item__value__text">
                    {Number(user?.wallets[0] ? user?.wallets[0].diamond : 0).toFixed(0)} <span>MUT</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wallet__action">
            <NavLink className="wallet__action__deposit-btn" path="/deposit">
              {t("Deposit")}
            </NavLink>
          </div>
        </div>
      )}
      {!name ? (
        <Icon.Loading />
      ) : (
        <div className="user-icon relative">
          <div className="flex items-center justify-center gap-x-2">
            <Avatar
              image={ava ? ava : user?.avatar !== `${Config.API_SERVER}/avatar/no-avatar.png` ? user?.avatar : NoUser}
              size="normal"
              shape="circle"
              onImageError={(e) => {
                e.target.onerror = null;
                e.target.src = NoUser;
              }}
            />
            <span className="name">{name}</span>
          </div>
          <div className="user-modal absolute">
            <div className="user-modal-content_top">
              <Avatar
                image={ava ? ava : user?.avatar !== `${Config.API_SERVER}/avatar/no-avatar.png` ? user?.avatar : NoUser}
                size="large"
                shape="circle"
                onImageError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoUser;
                }}
              />
              <span className="name block">{name}</span>
              {/* <span className="code">#{user?.id}</span> */}
            </div>
            <div className="user-modal-content_bottom">
              <div
                className="flex items-center justify-between item border-bottom-setting"
                onClick={() => {
                  history.push("/settings");
                }}
              >
                <span>{t("View Profile")}</span>
                <Icon.HollowArrow />
              </div>
              <div
                className="flex items-center justify-between item"
                onClick={() => {
                  dispatch(removeTokenDevice(token));
                  localStorage.removeItem("deviceToken");
                  dispatch(logoutUser());
                  dispatch(changeDisplayName(null));
                }}
              >
                <span>{t("Logout")}</span>
                <Icon.HollowArrow />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

UserMenu.propTypes = {};

export default UserMenu;
