import React from "react";
import { useTranslation } from "react-i18next";
import Footer1 from "assets/images/footer1.png";
import Footer2 from "assets/images/footer2.png";
import Footer3 from "assets/images/footer3.png";
import Footer4 from "assets/images/footer4.png";
import Footer5 from "assets/images/footer5.png";
import Footer6 from "assets/images/footer6.png";
import LogoFooter from "assets/images/LogoFooter.png";
import "./style.scss";
import { Icon } from "modules";

function FooterGlobal() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="brand grid grid-cols-2 md:grid-cols-6 items-center justify-center gap-4 md:py-4 md:px-24">
        <img src={Footer1} alt="LOT" />
        <img src={Footer2} alt="LOT" />
        <img src={Footer3} alt="LOT" />
        <img src={Footer4} alt="LOT" />
        <img src={Footer5} alt="LOT" />
        <img src={Footer6} alt="LOT" />
      </div>
      <div className="content md:py-4 md:px-24 grid grid-cols md:grid-cols-5">
        <div className="mb-4 md:mb-0 md:col-span-2">
          <img src={LogoFooter} alt="LOT" />
        </div>
        <div className="mb-4 md:mb-0 md:ml-auto w-fit">
          <div className="flex flex-col justify-center">
            <span>{t("About Us")}</span>
            <span>{t("Whitepaper")}</span>
            <span>{t("Contact Us")}</span>
          </div>
        </div>
        <div className="mb-4 md:mb-0 md:ml-auto w-fit">
          <div className="flex flex-col justify-center">
            <span>{t("Term of User")}</span>
            <span>{t("Terms for Fire Sale")}</span>
            <span>{t("Terms for Airdrop")}</span>
            <span>{t("Privacy Policy")}</span>
          </div>
        </div>
        <div className="md:ml-auto w-fit">
          <div className="flex items-center gap-3">
            <Icon.Youtube />
            <Icon.Facebook />
            <Icon.Instagram />
            <Icon.Twitter />
          </div>
        </div>
      </div>
      <div className="write md:py-4 md:px-24 text-center">
        <span>© 2024 MemeLotto. All Rights Reserved.</span>
      </div>
    </footer>
  );
}

export default FooterGlobal;
