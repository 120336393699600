import { Dialog } from "primereact/dialog";
import React from "react";
import "./style.scss";
import Congratulations from "assets/images/Congratulations.png";
import BgCongratulations from "assets/images/BgCongratulations.png";
import { useSelector } from "react-redux";

function ModalGameSuccess(props) {
  const ticket = useSelector((state) => state.game.ticket);
  return (
    <Dialog
      visible={props.displayModalSuccess}
      onHide={() => props.closeModalSuccess()}
      closable={false}
      showHeader={false}
      className="modal-game-success"
    >
      <div className="modal-game-success-content text-center">
        <div className="flex items-center justify-center relative">
          <img src={BgCongratulations} alt="BgCongratulations" />
          <img src={Congratulations} alt="Congratulations" className="img-success" />
        </div>
        <div className="container">
          <p className="modal-title mb-4">Congratulations!</p>

          {ticket > 0 && (
            <p className="modal-subtitle">
              You had been rewarded with <span> {ticket} </span> diamonds.
            </p>
          )}

          <div
            className="mt-6 btn-click mx-auto"
            onClick={() => {
              props.closeModalSuccess();
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ModalGameSuccess;
