import React, { useEffect, useState } from "react";
import "./styles.scss";
import CountDownTime from "component/countDownTime";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { buyTicket, changeCount, getCard, getChooseNumber, getTotalDraw, postIdCurrent } from "actions/game";
import { useLocation } from "react-router-dom";
import { Button } from "modules";
import { getPhase, getTimePhase } from "actions/phase";
import toast from "react-hot-toast";
import { LOADING_SUCCESS } from "actions/types";
import { getProfileUser } from "actions/user";
import { useIsMobile } from "utils/utils";
import ModalRulesGame from "component/modalRulesGame";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const location = useLocation();
  const timestamp = new Date();

  const error = (message) => toast.error(message);
  const success = (message) => toast.success(message);
  const info = () => toast("Please login", { icon: "❗" });

  const listPhase = useSelector((state) => state.phase.listPhase);
  const phase = useSelector((state) => state.phase);
  const getCards = useSelector((state) => state.game.cards);
  const userCode = useSelector((state) => state.user.user);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const totalDraw = useSelector((state) => state.game.totalDraw);

  const [isErr, setIsErr] = useState(false);
  const [Countdown, setCountDown] = useState();
  const [isActive, setIsActive] = useState(null);
  const [idCard, setIdCard] = useState(0);
  const [idCardCurrent, setIdCardCurrent] = useState(0);
  const [priceTicket, setPriceTicket] = useState(null);
  const [pickNumberArr, setPickNumberArr] = useState({
    location: [],
    colNumber: [],
    typeGame: "Pro",
  });
  const [listConfirm, setListConfirm] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [typeGame, setTypeGame] = useState(null);

  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const lookup = [
    ["M", 1000],
    ["CM", 900],
    ["D", 500],
    ["CD", 400],
    ["C", 100],
    ["XC", 90],
    ["L", 50],
    ["XL", 40],
    ["X", 10],
    ["IX", 9],
    ["V", 5],
    ["IV", 4],
    ["I", 1],
  ];

  const toRomanNumeral = (num) =>
    lookup.reduce((acc, [k, v]) => {
      acc += k.repeat(Math.floor(num / v));
      num = num % v;
      return acc;
    }, "");

  const getTicketNumber = (location) => {
    return location
      .sort()
      .map((locate) => {
        return locate.slice(1);
      })
      .join("");
  };

  const checkout = () => {
    if (!isLoggedIn) {
      info();
    } else {
      setIsErr(false);
      const phasePro = listPhase.find((item) => item?.name === "Mega6X Pro");
      let payload = listConfirm?.map((card) => {
        return {
          lottery_id: phasePro?.lottery_id,
          phase_id: phasePro?.id,
          ticket_number: getTicketNumber(card?.location),
          quantity: card?.quantity,
        };
      });
      dispatch(buyTicket([{ items: payload, plan: 1 }], 1))
        .then(() => {
          setIsErr(false);
          success("Checkout successful!");
          setListConfirm([]);
          setIsActive(null);
          dispatch(getProfileUser());
          dispatch(getPhase());
        })
        .catch((err) => {
          setIsErr(true);
        });
    }
  };

  const confirmNumber = () => {
    const addNUmber = pickNumberArr.location.length <= 0;
    const addFullNumber = pickNumberArr.location.length < 6;

    if (getCards.filter((item) => item.typeGame === "Pro").length <= 0) {
      error("Please add number before confirm");
    } else if (addNUmber) {
      error("Please add number");
    } else if (addFullNumber) {
      error("Please add full number");
    } else {
      setListConfirm((prev) => [...prev, pickNumberArr]);
      setIsActive(null);
      setPickNumberArr({ location: [], colNumber: [], typeGame: "Pro" });
      dispatch(
        getCard({
          idCard,
          status: false,
          typeGame: "Pro",
          price: priceTicket,
          plan_type: 1,
          idGood:
            idCard + " " + timestamp.toLocaleDateString() + " " + timestamp.toLocaleTimeString() + " - " + (userCode?.code ? userCode?.code : ""),
        })
      );
      setIdCardCurrent(idCard);
      setIdCard((prev) => prev + 1);
      success("Confirm number successful!");
    }
  };

  const renderSelectLine = () =>
    ["a", "b", "c", "d", "e", "f"].map((item, index) => (
      <div key={index} className="row-number flex lg:justify-start justify-center items-center">
        {renderSelectCol(item, index)}
      </div>
    ));

  const renderSelectCol = (number, no) =>
    [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) =>
      item === -1 ? (
        <span key={index} className="number inline-flex items-center justify-center mt-3.5">
          {toRomanNumeral(no + 1)}
        </span>
      ) : (
        <button
          key={index}
          disabled={false}
          className={`col-number inline-flex items-center justify-center mt-3.5 
      ${isActive?.includes(number + item) ? "number-focus" : ""}	
      `}
          onClick={() => {
            dispatch(
              getChooseNumber({
                locate: number + item,
                idGood:
                  idCard +
                  " " +
                  timestamp.toLocaleDateString() +
                  " " +
                  timestamp.toLocaleTimeString() +
                  " - " +
                  (userCode?.code ? userCode?.code : ""),
                price: priceTicket,
                idCard,
                colNumber: item,
                typeGame: "Pro",
              })
            );
          }}
        >
          {item}
        </button>
      )
    );

  const renderBackground = (location, colNumber, isCheckout) => {
    const array = ["a", "b", "c", "d", "e", "f"];
    return array?.map((item, index) => {
      return (
        <div key={index}>
          {!isCheckout && <div className="number-choose-header">{toRomanNumeral(index + 1)}</div>}
          <div className={`inline-flex items-center justify-center number-choose`}>{renderNumber(item, location)}</div>
        </div>
      );
    });
  };

  const renderNumber = (itemNumber, location) => {
    return location?.map((itemActive, index) => {
      return <span key={index}>{itemActive?.slice(0, 1) === itemNumber ? itemActive.slice(1) : ""}</span>;
    });
  };

  const renderRowNumber = () =>
    listConfirm
      ?.filter((item) => item.typeGame === "Pro")
      ?.map((item, index) => (
        <div
          key={index}
          className={`flex items-center justify-between gap-2 confirm__number--item`}
          onClick={() => {
            setIdCardCurrent(item.id);
          }}
        >
          <div className="space-x-2 flex flex-1 items-center">
            <span className="number">{index + 1}</span>
            {renderBackground(item.location, item.colNumber, true)}
          </div>
          <div className="flex justify-between md:justify-start w-full md:w-fit items-center gap-2">
            <div className="flex items-center justify-between btn-up-down">
              <button
                className={item?.location.length > 0 ? "cursor-pointer" : "cursor-default"}
                disabled={item?.location.length <= 0}
                onClick={() => {
                  if (item?.quantity === 1) {
                    setListConfirm((prev) => prev.filter((el) => el?.id !== item?.id));
                    setIsActive(null);
                  } else dispatch(changeCount(item?.id, false, true));
                }}
              >
                <i className="pi pi-minus"></i>
              </button>
              <span>{item?.quantity}</span>
              <button
                className={item?.location.length > 0 ? "cursor-pointer" : "cursor-default"}
                disabled={item?.location.length <= 0}
                onClick={() => dispatch(changeCount(item?.id, true, true))}
              >
                <i className="pi pi-plus"></i>
              </button>
            </div>
            <div>
              <span className="amount">
                {priceTicket * item?.quantity} <span>USDT</span>
              </span>
              <button
                style={{
                  color: "red",
                  marginLeft: "8px",
                }}
                onClick={() => {
                  setListConfirm((prev) => prev.filter((el) => el?.id !== item?.id));
                  setIsActive(null);
                }}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      ));

  useEffect(() => {
    isErr && error(message);
  }, [isErr, message]);

  useEffect(() => {
    dispatch(getPhase());
    dispatch(getTimePhase());
    dispatch({
      type: LOADING_SUCCESS,
    });
  }, [dispatch]);

  useEffect(() => {
    getCards.length > 0 && setPickNumberArr(getCards?.filter((item) => item.typeGame === "Pro")[getCards.length - 1]);
  }, [getCards.length]);

  useEffect(() => {
    getCards.forEach((item) => {
      if (item.id === idCardCurrent && item.typeGame === "Pro") setIsActive(item.location);
    });
    if (getCards.length < 1) {
      setIsActive(null);
      setIdCard(0);
      setIdCardCurrent(0);
    }
  }, [idCard, getCards, idCardCurrent]);

  useEffect(() => {
    dispatch(postIdCurrent({ idCardCurrent, typeGame: "Pro" }));
  }, [idCardCurrent, dispatch]);

  useEffect(() => {
    setIsActive(null);
    setPickNumberArr({ location: [], colNumber: [], typeGame: "Pro" });
    setListConfirm([]);
  }, [location.pathname, dispatch]);

  useEffect(() => {
    const newPhase = listPhase?.find((item) => item.name === "Mega6X Pro");
    setPriceTicket(newPhase?.price);
  }, [listPhase]);

  useEffect(() => {
    let start = phase.timePhase ? moment(phase.timePhase.time).utc() : moment().utc();
    let end = phase?.listPhase?.length > 0 ? moment(phase.listPhase[0].end).utc() : moment().utc();
    const duration = moment.duration(end.diff(start));
    let Milliseconds = duration.asMilliseconds();

    if (Milliseconds !== 0) {
      const timer = setInterval(() => {
        if (Milliseconds > 0) {
          Milliseconds = moment.duration(Milliseconds - 1000, "milliseconds");
        } else {
          if (Milliseconds === 0) {
            dispatch(getTimePhase());
            dispatch(getPhase());
          }
        }
        if (Milliseconds >= 0) setCountDown(moment(Milliseconds.asMilliseconds()).utc());
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [phase, dispatch]);

  return (
    <>
      <div className="home_page">
        <h1 className="title">Get Your Lucky Number</h1>
        <div className="subtitle">
          Buy lottery tickets online to the biggest lotteries in the world offering huge jackpot prizes that you can win when you play online lottery.
        </div>
        {phase &&
          phase.listPhase != null &&
          phase.listPhase
            .filter((game) => game.name === "Mega6X Pro")
            .map((item, idx) => (
              <div key={idx}>
                <div className="pick__number">
                  <div className="pick__number--top">
                    <div className="flex items-center pick__number--top--left">
                      <span>MMLotto</span>
                      <span
                        onClick={() => {
                          setDisplayModal(true);
                          setTypeGame("PickNumber");
                        }}
                      >
                        Rules play
                      </span>
                    </div>
                    <div className="flex items-center pick__number--top--right">
                      <span>TIME END</span>
                      <CountDownTime Countdown={Countdown} typeCountDown={"Game"} />
                    </div>
                  </div>
                  <div className="pick__number--bottom">
                    <div className="pick__number--bottom__top">
                      <div className="amount">
                        <span>MMLotto - Pool</span>
                        <span>{new Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(item.total)}</span>
                      </div>
                      <div className="content flex-1">
                        {/* “Not only is MMLotto your best chance to pocket , but it’s also only $2 to play. Best of all, if you win the top prize, you
                        won’t have to share it with anyone else.’ */}
                      </div>
                      <div className="time">
                        <span>
                          Jackpot:
                          <span className="value">{moment.utc(item?.start).local().format("dddd, MMMM YY, YYYY")}</span>
                        </span>
                        <span className="block">ID: #{item?.phase_code}</span>
                      </div>
                    </div>
                    <div className="pick__number--bottom__bottom">
                      <div>{renderSelectLine()}</div>
                      <div className="pick-container flex-1">
                        <div className="pick-container_title">Pick number to get ticket and win!!!</div>
                        <div className="pick-container_amount">{renderBackground(pickNumberArr.location, pickNumberArr.colNumber, false)}</div>
                        {!isMobile && (
                          <div className="flex items-center justify-center gap-2 mt-8">
                            <Button
                              label={t("Quick pick")}
                              buttonType="primary"
                              uppercaseLabel={false}
                              onClick={() => {
                                dispatch(
                                  getCard({
                                    idCard,
                                    status: true,
                                    typeGame: "Pro",
                                    price: priceTicket,
                                    plan_type: 1,
                                    idGood:
                                      idCard +
                                      " " +
                                      timestamp.toLocaleDateString() +
                                      " " +
                                      timestamp.toLocaleTimeString() +
                                      " - " +
                                      (userCode?.code ? userCode?.code : ""),
                                  })
                                );
                                setIdCardCurrent(idCard);
                                setIdCard((prev) => prev + 1);
                              }}
                            />
                            <Button label={t("Confirm")} buttonType="secondary" uppercaseLabel={false} onClick={confirmNumber} />
                          </div>
                        )}
                      </div>
                    </div>
                    {isMobile && (
                      <div className="flex items-center justify-center gap-2 pick-container_footer">
                        <Button
                          label={t("Quick pick")}
                          buttonType="primary"
                          uppercaseLabel={false}
                          onClick={() => {
                            dispatch(
                              getCard({
                                idCard,
                                status: true,
                                typeGame: "Pro",
                                price: priceTicket,
                                plan_type: 1,
                                idGood:
                                  idCard +
                                  " " +
                                  timestamp.toLocaleDateString() +
                                  " " +
                                  timestamp.toLocaleTimeString() +
                                  " - " +
                                  (userCode?.code ? userCode?.code : ""),
                              })
                            );
                            setIdCardCurrent(idCard);
                            setIdCard((prev) => prev + 1);
                          }}
                        />
                        <Button label={t("Confirm")} buttonType="secondary" uppercaseLabel={false} onClick={confirmNumber} />
                      </div>
                    )}
                  </div>
                </div>
                {listConfirm.length > 0 && (
                  <div className="confirm__number">
                    <div className="confirm__number--title">CHECKOUT:</div>
                    <div>{renderRowNumber()}</div>
                    <div className="flex items-center justify-center mt-8">
                      <Button label={t("Checkout")} buttonType="secondary" uppercaseLabel={false} onClick={checkout} />
                    </div>
                  </div>
                )}
              </div>
            ))}
      </div>
      <ModalRulesGame
        typeGame={typeGame}
        displayModal={displayModal}
        closeModal={() => {
          setDisplayModal(false);
        }}
      />
    </>
  );
};

export default Home;
