import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
// import BitcoinCasino from "assets/images/BitcoinCasino.png";
import CountDownTime from "component/countDownTime";

function BannerTime(props) {
  const { t } = useTranslation();
  const { Countdown, typeBanner } = props;

  return (
    <>
      <div
        className={`${
          typeBanner === "Home"
            ? "home-time__text lg:col-span-6 col-span-12 mt-8 lg:my-4 xl:my-0"
            : "banner-time__text lg:col-span-6 col-span-12 mt-8 lg:my-4 xl:my-0"
        } `}
      >
        <h2>{t("Get Your Lucky Number")}</h2>
        <p>
          {t(
            "Get your lucky number to the biggest gaming in the world offering huge jackpot prizes that you can win when you play online gaming."
          )}
        </p>
      </div>

      <div
        className={`
          ${typeBanner === "Home" ? "hidden" : "hidden"}
        `}
      ></div>

      <div
        className={`flex items-baseline justify-evenly ${
          typeBanner === "Home"
            ? "lg:col-span-5 sm:col-span-10 col-span-12 mt-4 md:mt-0"
            : "lg:col-span-5 col-span-12 mt-4 md:mt-0"
        }`}
      >
        <CountDownTime Countdown={Countdown} />
      </div>

      {/* <div
        className={`
          ${
            typeBanner === "Home"
              ? "lg:col-span-2 col-span-3 hidden sm:block margin-auto"
              : " col-span-6 sm:col-span-3 flex justify-center"
          }
        `}
      >
      </div> */}
    </>
  );
}

export default BannerTime;
