import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import ModalGame from "component/modalGame";
// import CardGame from "component/cardGame";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountDownTime from "component/countDownTime";
// import "assets/images/LotteryJackpotBackground2.png";
import ModalGameSuccess from "component/modalGameSuccess";

function LotteryJackpot(props) {
  const [typeGame, setTypeGame] = useState(null);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalSuccess, setDisplayModalSuccess] = useState(false);

  const openModal = (openTypeGame, openDisplayModal, openSelectedPhase) => {
    setTypeGame(openTypeGame);
    setDisplayModal(openDisplayModal);
    setSelectedPhase(openSelectedPhase);
  };

  const closeModal = (closeDisplayModal) => {
    setDisplayModal(closeDisplayModal);
    setTypeGame(null);
  };

  const openModalSuccess = () => {
    setDisplayModalSuccess(true);
  };

  const closeModalSuccess = () => {
    setDisplayModalSuccess(false);
  };

  const renderCardGamev2 = () => {
    return props.phase.listPhase
      .filter((game) => game.name === "Mega6X Pro")
      .map((game, index) => {
        return (
          <div key={index} className="md:w-5/6 w-full container-lottery grid grid-cols-2 mx-auto">
            <div className="lg:col-span-1 col-span-2 img-jackpot">
              <img className="lg:float-right lg:mt-4" src={game.image_url} alt="" />
            </div>
            <div className="lg:col-span-1 col-span-2 content-card">
              <div className="price-phase">
                {/* <span>${Number(game.total).toFixed(2)}</span> */}
                <span>{new Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(game.total)}</span>
              </div>
              <div className="name-phase">
                <span>{game.name}</span>
              </div>
              <div className="next-phase">{t("Next Drawing")}</div>
              <div className="ticket-card__hour flex justify-evenly">
                <CountDownTime Countdown={props.Countdown} typeCountDown={"Home"} />
              </div>
              <div>
                <button
                  onClick={() => {
                    openModal("Pro", true);
                    // openModal(
                    //   props.phase.listPhase?.[0].name === "Mega6X Pro" ? "Pro" : "Max",
                    //   true,
                    //   props.phase.listPhase?.[0]);
                  }}
                  className="button-buy"
                >
                  {t("Play Now")}
                </button>
              </div>
            </div>
          </div>
        );
      });
  };

  const renderLotteryJackpot = () => {
    return (
      props.phase &&
      props.phase.listPhase != null && (
        <>
          <div className=" items-center frame-buy-ticket" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
            {renderCardGamev2()}
          </div>
        </>
      )
    );
  };

  // if (props.phase && props.phase.listPhase != null) {
  return (
    <>
      <div className="lottery-jackpot">
        <div
          className="container text-center"
          style={{
            paddingTop: `${props.page !== "Game" ? "8.375rem" : ""}`,
          }}
        >
          <h3 className="font-title-section font-bold" style={{ marginBottom: "0rem" }} data-aos="fade-up" data-aos-duration="600">
            {props.page !== "Game" ? t("Lucky Jackpot") : t("Get Your Lucky Number")}
          </h3>
          <p
            className="home-title mx-0 xl:mx-80"
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="100"
            style={{
              marginBottom: "2.625rem",
              marginTop: "1.125rem",
            }}
          >
            {props.page !== "Game"
              ? t("Choose from the Powerball, Mega Millions, Lotto or Lucky Day Lotto and try for a chance to win a big cash prize")
              : t(
                  "Get your lucky number to the biggest gaming in the world offering huge jackpot prizes that you can win when you play online gaming."
                )}
          </p>
          {renderLotteryJackpot()}
        </div>
      </div>
      <ModalGame
        displayModal={displayModal}
        closeModal={closeModal}
        typeGame={typeGame}
        selectedPhase={selectedPhase}
        phaseTime={props.phase.timePhase}
        Countdown={props.Countdown}
        openModalSuccess={openModalSuccess}
      />

      <ModalGameSuccess displayModalSuccess={displayModalSuccess} closeModalSuccess={closeModalSuccess} />
    </>
  );
  // } else return <div className="mb-40"></div>;
}

export default LotteryJackpot;
