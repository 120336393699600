import _ from "lodash";
import { Icon } from "modules";
import React, { useEffect, useState } from "react";

export const CheckboxCompnt = (props) => {
  const [checked, setChecked] = useState(_.isNil(props.checked) ? false : props.checked);
  useEffect(() => {
    setChecked(_.isNil(props.checked) ? false : props.checked);
  }, [props.checked]);

  const handleChange = (newValue) => {
    setChecked(newValue);
    if (props.onChange) {
      props.onChange(_.isNil(props.value) ? "" : props.value, newValue);
    }
  };

  return (
    <div className="checkbox-compnt">
      {checked === true && (
        <span className="checkbox-compnt__icon" onClick={() => handleChange(false)}>
          <Icon.CheckboxChecked />
        </span>
      )}
      {checked === false && (
        <span className="checkbox-compnt__icon" onClick={() => handleChange(true)}>
          <Icon.CheckboxUnchecked />
        </span>
      )}
    </div>
  );
};
