import { Dialog } from "primereact/dialog";
import PropTypes from "prop-types";
import React from "react";
import "../styles.scss";
import FormLogin from "component/formLogin";
import { Icon } from "modules";

function ModalLogin(props) {
  return (
    <Dialog
      visible={props.open}
      onHide={props.closeModal}
      closeOnEscape
      blockScroll
      dismissableMask={true}
      closable={false}
      showHeader={false}
      className="modal-login"
    >
      <FormLogin onFinish={props.closeModal} />
      <span className="icon-close" onClick={props.closeModal}>
        <Icon.Close />
      </span>
    </Dialog>
  );
}

ModalLogin.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalLogin;
