import React, { useEffect, useState } from "react";

import ReactSelect, { components } from "react-select";
import { ENetWork, EToken } from "types";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { ObjectUtils, useForm, InputWraper } from "modules";
import * as Yup from "yup";
import { InputNumber } from "component";
import { Button } from "modules";
import { useSelector, useDispatch } from "react-redux";
import WalletService from "services/wallet.Service";
import { getProfileUser } from "actions/user";
import { dashboardLayout } from "component";

let tokenSendOptions = [{ label: "USDT", value: "USDT" }];
let tokenReceiveOptions = [{ label: "MUT", value: "MUT" }];

const ExchangePage = dashboardLayout((props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [popupPromotionAlert, setPopupPromotionAlert] = useState(false);
  const [tokenSendSelected, setTokenSendSelected] = useState(tokenSendOptions[0]);
  const [tokenReceiveSelected, setTokenReceiveSelected] = useState(tokenReceiveOptions[0]);
  const [network, setNetwork] = useState({
    label: `USDT - BEP20`,
    value: "BEP20",
  });
  const [isShowMaxButton, setIsShowMaxButton] = useState(true);
  const [feeConfig, setFeeConfig] = useState(null);
  // const userInternalWallets = useSelector((state) => state.userInternalWallets);
  // const userNetworkOptions = useSelector((state) => state.main.networkOptions);

  // useEffect(() => {
  // BankService.getTokenStandard(store).then((res) => {
  //     let defaultNetwork = res?.payload?.[0];
  //     setNetwork({
  //         label: `${tokenSelected != null ? `${tokenSelected?.label} - ${defaultNetwork?.label}` : `${defaultNetwork?.label}`}`,
  //         value: defaultNetwork?.value,
  //     });
  // });
  // }, []);

  // if (!isVisible) return null;

  // if (!userInternalWallets) return <Message type="loading" />;
  // if (userInternalWallets.error)
  //     return (
  //         <Message
  //             type="error"
  //             {...userInternalWallets.error}
  //         />
  //     );

  // const internalWallet = userInternalWallets.data.find(x => x.code === coinCode);
  // const internalWalletByNetwork = ObjectUtils.getIn(internalWallet, network?.value?.toLowerCase());

  // if (!ObjectUtils.getIn(internalWalletByNetwork, "address"))
  //     return (
  //         <div className="text-center">
  //             <Icon.Loading />
  //         </div>
  //     );

  const displayNetworkName = () => {
    switch (network?.value) {
      case ENetWork.BEP20: {
        return "BEP-20";
      }
      default: {
        return "BEP-20";
      }
    }
  };

  let CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="custom-option__container">
          <div className="custom-option__icon">
            <img src={`/assets/images/coins/${props?.data?.value?.toLowerCase()}.png`} alt="" />
          </div>
          <div className="custom-option__label">{props?.data?.label}</div>
        </div>
      </components.Option>
    );
  };

  let SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div className="single-value__container">
          <div className="single-value__icon">
            <img src={`/assets/images/coins/${props?.data?.value?.toLowerCase()}.png`} alt="" />
          </div>
          <div className="single-value__label">{props?.data?.label}</div>
        </div>
      </components.SingleValue>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.86198 0.195312L3.99998 3.05731L1.13798 0.195312L0.195312 1.13798L3.99998 4.94265L7.80465 1.13798L6.86198 0.195312Z"
            fill="black"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const CustomIndicatorSeparator = (props) => null;

  const { getInputProps, handleSubmit, isSubmitting, isValid } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "amount",
        defaultValue: Number(user?.wallets[0].balance).toFixed(2),
        validate: Yup.number()
          .required("Must be provided")
          .min(0, "Minimum is 0")
          .max(Number(user?.wallets[0].balance).toFixed(2), "The amount in the asset is not enough"),
      },
    ],
    onSubmit: async (values) => {
      return WalletService.exchange(values?.amount)
        .then(() => {
          dispatch(getProfileUser());
          history.push("/overview");
          toast.success(`Buy successfully $${values?.amount} to ${values?.amount * feeConfig?.quantityDiamond}`, {
            style: {
              width: "fit-content",
              maxWidth: "none",
            },
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            style: {
              width: "fit-content",
              maxWidth: "none",
            },
          });
        });
    },
  });

  useEffect(() => {
    WalletService.getFee(2).then((res) => {
      setFeeConfig(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    getInputProps("amount").value ? setIsShowMaxButton(false) : setIsShowMaxButton(true);
  }, [getInputProps("amount")]);

  return (
    <div className="exchange-form-page">
      <div className="indicator-back" onClick={() => history.push("/overview")}>
        <div className="indicator-back__icon">
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.64814 17.0484C9.17951 17.5171 8.41971 17.5171 7.95108 17.0484L0.751078 9.84843C0.282449 9.3798 0.282449 8.62 0.751078 8.15137L7.95108 0.951375C8.41971 0.482746 9.17951 0.482746 9.64814 0.951375C10.1168 1.42 10.1168 2.1798 9.64814 2.64843L4.49666 7.7999L18.3996 7.7999C19.0623 7.7999 19.5996 8.33716 19.5996 8.9999C19.5996 9.66264 19.0623 10.1999 18.3996 10.1999L4.49666 10.1999L9.64814 15.3514C10.1168 15.82 10.1168 16.5798 9.64814 17.0484Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="indicator-back__label">{"BUY MUT"}</div>
      </div>
      <div className="deposit-form container-fluid">
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="deposit-form-wrapper sm:col-span-7">
            <div className="deposit-form__step-1">
              <div className="deposit-form__step-1__title">
                <div className="deposit-form__step-1__title__icon">1</div>
                <div className="deposit-form__step-1__title__label">{"SELECT TOKEN"}</div>
              </div>
              <div className="deposit-form__step-1__content">
                <div className="deposit-form__step-1__content__select-send">
                  <ReactSelect
                    options={tokenSendOptions}
                    value={tokenSendSelected}
                    onChange={setTokenSendSelected}
                    className="token-select"
                    classNamePrefix="token-select"
                    placeholder={"Please select currency..."}
                    components={{
                      Option: CustomOption,
                      SingleValue: SingleValue,
                      DropdownIndicator,
                      IndicatorSeparator: CustomIndicatorSeparator,
                    }}
                    isSearchable={false}
                  />
                </div>
                <div className="deposit-form__step-1__content__amount-send">
                  <InputWraper
                    className="mb-1"
                    // label={"Amount"}
                    placeholder={`Min: 10`}
                    inputProps={getInputProps("amount")}
                    renderInput={(state) => {
                      return (
                        <>
                          <InputNumber {...state} decimalScale={4} />
                          {isShowMaxButton && (
                            <div className="fill-max" onClick={() => getInputProps("amount").onChange(Number(user?.wallets[0].balance).toFixed(2))}>
                              {"MAX"}
                            </div>
                          )}
                        </>
                      );
                    }}
                    customSuffix={"USDT"}
                  />
                </div>
                <div className="deposit-form__step-1__content__extra">
                  <div className="deposit-form__step-1__content__extra__available">{`Available: ${Number(user?.wallets[0].balance).toFixed(
                    2
                  )} USDT`}</div>
                  <div className="deposit-form__step-1__content__extra__fee">{`Fee: ${+getInputProps("amount").value * feeConfig?.fee} USDT`}</div>
                </div>
              </div>
            </div>
            <div className="deposit-form__step-2">
              <div className="deposit-form__step-2__title">
                <div className="deposit-form__step-2__title__icon">2</div>
                <div className="deposit-form__step-2__title__label">{`TOKEN RECEIVE`}</div>
              </div>
              <div className="deposit-form__step-2__content">
                <div className="deposit-form__step-2__content__select-receive">
                  <ReactSelect
                    options={tokenReceiveOptions}
                    value={tokenReceiveSelected}
                    onChange={setTokenReceiveSelected}
                    className="token-select"
                    classNamePrefix="token-select"
                    placeholder={"Please select currency..."}
                    components={{
                      Option: CustomOption,
                      SingleValue: SingleValue,
                      DropdownIndicator,
                      IndicatorSeparator: CustomIndicatorSeparator,
                    }}
                    isSearchable={false}
                  />
                </div>
                <div className="deposit-form__step-2__content__amount-receive">
                  <div className="deposit-form__step-2__content__amount-receive__value">
                    {+getInputProps("amount").value * feeConfig?.quantityDiamond}
                  </div>
                  <div className="deposit-form__step-2__content__amount-receive__symbol">MUT</div>
                </div>
              </div>
            </div>
            <div className="deposit-form__main-action">
              <Button
                className="deposit-form__main-action__exchange"
                label={"BUY MUT"}
                buttonType="secondary"
                isLoading={isSubmitting}
                disabled={isSubmitting}
                onClick={handleSubmit}
              />
            </div>
          </div>
          <div className="deposit-form__info-wrapper sm:col-span-5">
            <div className="deposit-form__info">
              <div className="deposit-form__info__list">
                <div className="deposit-form__info__list__item">
                  <div className="deposit-form__info__list__item__title">USDT</div>
                  <div className="deposit-form__info__list__item__value">
                    <div className="deposit-form__info__list__item__value__icon">
                      <img src="/assets/images/coins/usdt.png" alt="usdt" />
                    </div>
                    <div className="deposit-form__info__list__item__value__text">{Number(user?.wallets[0].balance).toFixed(2)}</div>
                  </div>
                </div>
                <div className="deposit-form__info__list__item">
                  <div className="deposit-form__info__list__item__title">MUT</div>
                  <div className="deposit-form__info__list__item__value">
                    <div className="deposit-form__info__list__item__value__icon">
                      <img src="/assets/images/coins/mut.png" alt="mut" />
                    </div>
                    <div className="deposit-form__info__list__item__value__text">
                      {Number(user?.wallets[0] ? user?.wallets[0].diamond : 0).toFixed(0)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
});

export default ExchangePage;
