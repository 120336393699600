import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from "primereact/confirmdialog";
import {
  deleteCard,
  getChooseNumber,
  getCard,
  changeCount,
  postIdCurrent,
  addToCart,
  claerValidationMaxGame,
  buyTicket,
  getTotalDraw,
} from "actions/game";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import CountDownTime from "component/countDownTime";
import { CLEAR_CART_SHOP } from "actions/types";
import Diamond from "assets/images/Diamond.png";
import { getProfileUser } from "actions/user";

const PERIOD = [
  { label: "One-Time", value: 1 },
  { label: "Week", value: 2 },
  { label: "Month", value: 3 },
  { label: "Quarter", value: 4 },
  { label: "Year", value: 5 },
];

const PAYMENT_TYPE = [
  { label: "USDT", value: 1 },
  { label: "Diamond", value: 2 },
];

function ModalGame(props) {
  const { closeModal } = props;

  const [idCard, setIdCard] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [idCardCurrent, setIdCardCurrent] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [priceTicket, setPriceTicket] = useState(null);
  const [period, setPeriod] = useState(1);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [payment, setPayment] = useState(1);

  const mess = useSelector((state) => state.game.mess);
  const userCode = useSelector((state) => state.user.user);
  const getCards = useSelector((state) => state.game.cards);
  const validationMax = useSelector((state) => state.game.validationMax);
  const listPhase = useSelector((state) => state.phase.listPhase);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message: messErr } = useSelector((state) => state.message);
  const totalDraw = useSelector((state) => state.game.totalDraw);
  const [iSMsg, setIsMsg] = useState();

  const error = (message) => toast.error(message);
  const success = (message) => toast.success(message);
  const info = () => toast("Please login", { icon: "❗" });

  const timestamp = new Date();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    displayModal && dispatch(getTotalDraw(period));
  }, [dispatch, period, displayModal]);

  useEffect(() => {
    if (isAdd === true) {
      if (mess === true) success("Successfully add shopping cards!");
      else {
        switch (period) {
          case 2:
            return error("Only 1 weekly ticket can be added to the cart");
          case 3:
            return error("Only 1 monthly ticket can be added to the cart");
          case 4:
            return error("Only 1 quarterly ticket can be added to the cart");
          case 5:
            return error("Only 1 annual ticket can be added to the cart");
          default:
            return;
        }
      }

      setIsAdd(false);
    }
    if (iSMsg) messErr && messErr === "Insufficient funds" ? toast(messErr) : toast.error("Ticket purchase failed!");
  }, [mess, isAdd, iSMsg, messErr, period]);

  useEffect(() => {
    getCards.forEach((item) => {
      if (item.id === idCardCurrent && item.typeGame === props.typeGame) setIsActive(item.location);
    });
    if (getCards.length < 1) {
      setIsActive(null);
      setIdCard(0);
      setIdCardCurrent(0);
    }
  }, [idCard, getCards, idCardCurrent, props.typeGame]);

  useEffect(() => {
    dispatch(postIdCurrent({ idCardCurrent, typeGame: props.typeGame }));
  }, [idCardCurrent, dispatch, props.typeGame]);

  useEffect(() => {
    if (!displayModal) {
      setIsActive(null);
      dispatch(claerValidationMaxGame());
    }
  }, [displayModal, dispatch]);

  useEffect(() => {
    setDisplayModal(props.displayModal);
    setSelectedPhase(props.selectedPhase);
  }, [props.displayModal, props.typeGame, props.selectedPhase]);

  useEffect(() => {
    const newPhase = listPhase?.find((item) => item.name === "Mega6X Pro");
    setPriceTicket(payment === 1 ? newPhase?.price : newPhase?.diamond);
  }, [listPhase, payment]);

  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const totalPricePro = getCards
    .filter((item) => item.typeGame === "Pro")
    ?.reduce((acc, cur) => {
      return acc + priceTicket * cur.quantity * totalDraw;
    }, 0);

  const quantity = getCards
    .filter((item) => item.typeGame === "Pro")
    ?.reduce((acc, cur) => {
      return acc + cur.quantity;
    }, 0);

  const addCard = () => {
    const addArray = getCards.filter((item) => {
      return item.typeGame === props.typeGame;
    });
    const addGame = addArray.filter((item) => {
      return item.location.length <= 0;
    });
    const addFullGame = addArray.filter((item) => {
      return item.location.length < 6;
    });
    const listAddTicket = getCards
      ?.filter((item) => {
        return item.typeGame === props.typeGame;
      })
      .map((item) => {
        return { ...item, period, totalDraw };
      });
    if (addArray.length <= 0) {
      error("Please add ticket before adding to cart");
    } else if (addGame.length > 0) {
      error("Please add number for ticket");
    } else if (addFullGame.length > 0 && props.typeGame === "Pro") {
      error("Please add full number for ticket");
    } else if (userCode && validationMax !== "") {
      error(validationMax);
    } else {
      dispatch(addToCart(listAddTicket));
      setIsAdd(true);
    }
  };

  const buyNow = async () => {
    if (!isLoggedIn) {
      info();
      history.push("/login");
    } else {
      const buyArray = getCards.filter((item) => {
        return item.typeGame === props.typeGame;
      });
      const buyGame = buyArray.filter((item) => {
        return item.location.length <= 0;
      });
      const buyFullGame = buyArray.filter((item) => {
        return item.location.length < 6;
      });
      if (buyArray.length <= 0) {
        error("Please add ticket before purchasing");
      } else if (buyGame.length > 0) {
        error("Please add number for ticket");
      } else if (buyFullGame.length > 0 && props.typeGame === "Pro") {
        error("Please add full number for ticket");
      } else if (userCode && validationMax !== "") {
        error(validationMax);
      } else {
        let payload = getCards
          ?.filter((card) => card.typeGame === props.typeGame)
          ?.map((card) => {
            return {
              lottery_id: selectedPhase?.lottery_id,
              phase_id: selectedPhase?.id,
              ticket_number: getTicketNumber(card?.location),
              quantity: card?.quantity,
            };
          });
        setLoading(true);
        setIsMsg(false);
        dispatch(buyTicket([{ items: payload, plan: period }], payment))
          .then(() => {
            props.openModalSuccess();
            setIsMsg(false);
            setLoading(false);
            dispatch(getProfileUser());
          })
          .catch(() => {
            setIsMsg(true);
            setLoading(false);
          });
      }
    }
  };

  const renderSelectCol = (number) => {
    const col = ["a", "b", "c", "d", "e", "f"];
    return col.map((item, index) => {
      return (
        <button
          key={index}
          disabled={false}
          className={`col-number inline-flex items-center justify-center mt-3.5 
        ${isActive?.includes(item + number) ? "number-focus" : ""}	
        `}
          onClick={() => {
            dispatch(
              getChooseNumber({
                locate: item + number,
                idGood:
                  idCard +
                  " " +
                  timestamp.toLocaleDateString() +
                  " " +
                  timestamp.toLocaleTimeString() +
                  " - " +
                  (userCode?.code ? userCode?.code : ""),
                price: priceTicket,
                idCard,
                colNumber: item,
                typeGame: props.typeGame,
              })
            );
            const arrayType = getCards.map((item) => {
              return item.typeGame;
            });
            if (!arrayType.includes(props.typeGame)) {
              setIdCard((prev) => prev + 1);
              setIdCardCurrent(idCard);
            }
          }}
        >
          {number}
        </button>
      );
    });
  };

  const renderSelectLine = () => {
    const row = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    return row.map((item, index) => {
      return (
        <div key={index} className="row-number flex lg:justify-start justify-center items-center gap-x-3.5">
          {renderSelectCol(item)}
        </div>
      );
    });
  };

  const getTicketNumber = (location) => {
    return location
      .sort()
      .map((locate) => {
        return locate.slice(1);
      })
      .join("");
  };

  const renderBackground = (location, colNumber) => {
    const array = ["a", "b", "c", "d", "e", "f"];
    return array?.map((item, index) => {
      return (
        <div key={index} className={`inline-flex items-center justify-center  ${colNumber?.includes(item) ? "isColActive" : "modal-scroll__number"}	`}>
          {renderNumber(item, location)}
        </div>
      );
    });
  };

  const renderNumber = (itemNumber, location) => {
    return location?.map((itemActive, index) => {
      return <span key={index}>{itemActive?.slice(0, 1) === itemNumber ? itemActive.slice(1) : ""}</span>;
    });
  };

  const renderRowNumber = () => {
    return getCards
      ?.filter((item) => item.typeGame === props.typeGame)
      ?.map((item, index) => {
        return (
          <div
            key={index}
            className={`flex items-center justify-between gap-2 mt-2 ${index === getCards.length - 1 ? "" : "border-b-2 pb-1"}`}
            onClick={() => {
              setIdCardCurrent(item.id);
            }}
          >
            <div className="space-x-2 flex flex-1 items-center">{renderBackground(item.location, item.colNumber)}</div>
            {item.typeGame === "Pro" && (
              <div className="modal-scroll__result flex-1 text-center">
                {payment === 1 ? (
                  <span className="modal-scroll__input input-price block">${Number(priceTicket).toFixed(2)}</span>
                ) : (
                  <span className="flex items-center justify-center modal-scroll__input input-price">
                    <img src={Diamond} alt="" />
                    <span className="ml-2">{Number(priceTicket).toFixed(2)}</span>
                  </span>
                )}
              </div>
            )}
            <button
              className={`btn-trash py-2.5 pr-6 ${payment === 2 && "pl-2"}
          `}
              onClick={() => {
                dispatch(deleteCard({ id: item?.id, typeGame: props.typeGame }));
                setIsActive(null);
              }}
            >
              <i className="pi pi-trash"></i>
            </button>
            {item.typeGame === "Pro" && (
              <div className="flex items-center justify-between btn-up-down py-2.5">
                <button
                  className={item?.location.length > 0 ? "cursor-pointer" : "cursor-default"}
                  disabled={item?.location.length <= 0}
                  onClick={() => dispatch(changeCount(item?.id, false, true))}
                >
                  <i className="pi pi-minus"></i>
                </button>
                <span className="mx-3">{item?.quantity}</span>
                <button
                  className={item?.location.length > 0 ? "cursor-pointer" : "cursor-default"}
                  disabled={item?.location.length <= 0}
                  onClick={() => dispatch(changeCount(item?.id, true, true))}
                >
                  <i className="pi pi-plus"></i>
                </button>
              </div>
            )}
          </div>
        );
      });
  };

  const onChangePeriod = (value) => {
    setPeriod(value);
    setVisibleConfirm(value !== 1 && getCards.length > 1);
  };

  const clearAllTicket = () => {
    dispatch({
      type: CLEAR_CART_SHOP,
      payload: [],
    });
  };

  const reject = () => {
    setPeriod(1);
  };

  return (
    <div>
      <ConfirmDialog
        target={document.getElementById("button")}
        closable={false}
        visible={visibleConfirm}
        onHide={() => setVisibleConfirm(false)}
        message="Just one ticket for Period! Are you sure clear all ticket?"
        icon="pi pi-exclamation-triangle"
        accept={clearAllTicket}
        reject={reject}
      />
      <Dialog
        visible={displayModal}
        onHide={() => closeModal(false)}
        dismissableMask={true}
        closable={false}
        showHeader={false}
        className="modal-game"
      >
        <div className="modal-game-content text-center">
          <button
            className="btn-close"
            onClick={() => {
              closeModal(false);
            }}
          >
            <span className=" pi pi-times"></span>
          </button>
          <div className="container">
            <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-end">
              <div className="lg:col-span-5">{renderSelectLine()}</div>
              <div className="lg:col-span-7 text-left">
                <h3 className="modal-title">MEGA6X {props.typeGame && props.typeGame} Jackpot</h3>
                <p className="modal-text">
                  {props.typeGame === "Max"
                    ? t(
                        "Not only is Mega 6X Max your best chance to pocket , but it’s also only $2 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else."
                      )
                    : t(
                        "Not only is Mega 6X Pro your best chance to pocket , but it’s also only $2 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else."
                      )}
                </p>
                <div className="flex items-center justify-between modal-date">
                  <p>Jackpot:</p>
                  <span>
                    {props.selectedPhase?.name === "Mega6X " + props.typeGame &&
                      new Date(props.selectedPhase?.start.replace(/-/g, "/")).toLocaleDateString(t("en-US"), optionsDate)}
                  </span>
                </div>
                <div className="flex items-center justify-between modal-count-down">
                  <p className="">{t("Nex Drawing")}:</p>
                  <div className="flex items-center justify-between modal-time">
                    <CountDownTime Countdown={props.Countdown} typeCountDown={"Game"} />
                  </div>
                </div>
                <div className="modal-id flex items-center justify-between">
                  <p>ID:</p>
                  <span>#{props.selectedPhase?.name === "Mega6X " + props.typeGame && props.selectedPhase?.phase_code}</span>
                </div>
                <div className="flex items-center justify-between">
                  <Dropdown value={period} options={PERIOD} onChange={(e) => onChangePeriod(e.value)} />
                  <Dropdown
                    value={payment}
                    options={PAYMENT_TYPE}
                    onChange={(e) => {
                      setPayment(e.value);
                    }}
                  />
                </div>
                <div className="modal-result p-5 mt-4">
                  <div className="flex items-center justify-between">
                    <button
                      className="btn-quick-pick px-3"
                      disabled={period !== 1 && getCards.length === 1}
                      onClick={() => {
                        dispatch(
                          getCard({
                            idCard,
                            status: true,
                            typeGame: props.typeGame,
                            price: priceTicket,
                            plan_type: period,
                            idGood:
                              idCard +
                              " " +
                              timestamp.toLocaleDateString() +
                              " " +
                              timestamp.toLocaleTimeString() +
                              " - " +
                              (userCode?.code ? userCode?.code : ""),
                          })
                        );
                        setIdCardCurrent(idCard);
                        setIdCard((prev) => prev + 1);
                      }}
                    >
                      {t("Quick Pick")}
                    </button>
                    <button
                      className="btn-plus px-3"
                      disabled={period !== 1 && getCards.length === 1}
                      onClick={() => {
                        dispatch(
                          getCard({
                            idCard,
                            status: false,
                            typeGame: props.typeGame,
                            price: priceTicket,
                            plan_type: period,
                            idGood:
                              idCard +
                              " " +
                              timestamp.toLocaleDateString() +
                              " " +
                              timestamp.toLocaleTimeString() +
                              " - " +
                              (userCode?.code ? userCode?.code : ""),
                          })
                        );
                        setIdCardCurrent(idCard);
                        setIdCard((prev) => prev + 1);
                      }}
                    >
                      <i className="pi pi-plus"></i>
                    </button>
                  </div>
                  <ScrollPanel className="modal-scroll">{renderRowNumber()}</ScrollPanel>
                  <div>
                    <div>
                      {props.typeGame === "Pro" && (
                        <div className="flex items-center justify-between gap-2 sm:gap-10 mt-4 ">
                          <div className="space-x-2 flex items-center w-56 font-bold">{t("Total")}</div>
                          <div>
                            {payment === 1 ? (
                              <span className="modal-scroll__input input-total-price">${Number(totalPricePro).toFixed(2)}</span>
                            ) : (
                              <span className="flex items-center  modal-scroll__input input-total-price">
                                <img src={Diamond} alt="" />
                                <span className="ml-2">{Number(totalPricePro).toFixed(2)}</span>
                              </span>
                            )}
                          </div>
                          <div className="text-center w-24 mx-3.5 font-medium text-lg">{quantity}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container flex items-center justify-center lg:justify-end mt-6 gap-x-8 md:gap-x-12 lg:gap-x-20">
            <button className="btn-add rounded-full" onClick={addCard}>
              {t("Add To Cart")}
            </button>
            <button className="btn-buy rounded-full" onClick={buyNow} disabled={loading}>
              {t("Buy Now")}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ModalGame;
