import { gameAPI } from "configs/const";
import customAxios from "../configs/axios";

const getWinners = (from_date, to_date, page, pageSize) => {
  return customAxios.post(gameAPI.getWinners, {
    from_date,
    to_date,
    page,
    pageSize,
  });
};

const getDraw = (from_date, to_date, page, pageSize) => {
  return customAxios.post(gameAPI.getDraw, {
    from_date,
    to_date,
    page,
    pageSize,
  });
};

const buyTicket = (list_item, type) => {
  return customAxios.post(gameAPI.buyTicket, {
    list_item,
    type,
  });
};

const getTotalDraw = (period) => {
  return customAxios.get(gameAPI.getTotalDraw + period, {});
};

const getTicketRemain = () => {
  return customAxios.get(gameAPI.getTicketRemain);
};

const buyGame = ({ lottery_id, phase_id, choose, amount }) => {
  return customAxios.post(gameAPI.buyGame, {
    lottery_id,
    phase_id,
    choose,
    amount,
  });
};

export default { getWinners, buyTicket, getDraw, getTotalDraw, getTicketRemain, buyGame };
