import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { setStyleHeading } from "actions/styleHeading";
import { getGameEvenOdd, getGameMaxMin, getPhase, getTimePhase } from "actions/phase";
import { Toaster } from "react-hot-toast";
import Loading from "page/loading";
import BannerHome from "component/bannerHome";
import LotteryJackpot2 from "component/lotteryJackpot2";
import NewCommingProduct from "component/NewProduct";
import Operate from "component/operate";
import Affliate from "component/affliate";
import Advertisement from "component/advertisement";
import WalletPayment from "component/walletPayment";
import { LOADING_SUCCESS } from "actions/types";
import moment from "moment";
// import PopupDiamond from "component/popUpDiamond";
import ModalPartnerActive from "component/modalPartnerActive";
import LotteryResult from "component/lotteryResult";
import { getDraw, getWinners } from "actions/game";
import { getListPost } from "actions/post";
import GameMaxMinEvenOdd from "component/gameMaxMinEvenOdd";
import ModalGame from "component/modalGame";

const Home2 = (props) => {
  const dispatch = useDispatch();
  const [Countdown, setCountDown] = useState();
  const history = useHistory();
  const phase = useSelector((state) => state.phase);
  const { isLoading } = useSelector((state) => state.loading);
  const winners = useSelector((state) => state.game.winners);
  const draw = useSelector((state) => state.game.draw);
  const [newWinners, setNewWinners] = useState(0);
  const [newDraw, setNewDraw] = useState(0);

  useEffect(() => {
    setNewWinners(winners?.result?.filter((item) => item.lottery === "Mega6X Pro"));
  }, [winners]);

  useEffect(() => {
    dispatch(
      getWinners(
        moment().subtract(1, "month").utc().set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
        moment().utc().set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
        0,
        10
      )
    );
    dispatch(
      getDraw(
        moment().subtract(1, "month").utc().set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
        moment().utc().set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
        0,
        10
      )
    );
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(setStyleHeading(props.match.path));
  }, [dispatch, props.match.path]);

  useEffect(() => {
    dispatch(getPhase());
    dispatch(getTimePhase());
    dispatch(getListPost(0, 6));
    dispatch(getGameMaxMin());
    dispatch(getGameEvenOdd());
    dispatch({
      type: LOADING_SUCCESS,
    });
  }, [dispatch]);

  useEffect(() => {
    let start = phase.timePhase ? moment(phase.timePhase.time).utc() : moment().utc();
    let end = phase?.listPhase?.length > 0 ? moment(phase.listPhase[0].end).utc() : moment().utc();
    const duration = moment.duration(end.diff(start));
    let Milliseconds = duration.asMilliseconds();

    if (Milliseconds !== 0) {
      const timer = setInterval(() => {
        if (Milliseconds > 0) {
          Milliseconds = moment.duration(Milliseconds - 1000, "milliseconds");
        } else {
          if (Milliseconds === 0) {
            dispatch(getTimePhase());
            dispatch(getPhase());
          }
        }
        if (Milliseconds >= 0) setCountDown(moment(Milliseconds.asMilliseconds()).utc());
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [phase, dispatch]);

  useEffect(() => {
    setNewWinners(winners?.result?.filter((item) => item.lottery === "Mega6X Pro"));
    setNewDraw(draw?.result?.filter((item) => item.lottery === "Mega6X"));
  }, [winners, draw]);

  if (isLoading) return <Loading />;
  else
    return (
      <div className="home-page">
        Dashboard
        <BannerHome Countdown={Countdown} />
        <LotteryJackpot2 Countdown={Countdown} phase={phase} />
        <GameMaxMinEvenOdd Countdown={Countdown} phase={phase} />
        <LotteryResult winners={newWinners && newWinners} draw={newDraw && newDraw} />
        <Operate />
        <Affliate />
        <WalletPayment />
        <NewCommingProduct />
        <Advertisement />
        {/* <PopupDiamond /> */}
        <ModalPartnerActive />
        <Toaster />
      </div>
    );
};

export default Home2;
