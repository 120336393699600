import { verifyPartner } from "actions/auth";
import { getLocalCart } from "actions/game";
import { ACTIVE_PARTNER, IS_NOTIFY_SUCCESS } from "actions/types";
import { getContractLottery } from "actions/wallet";
import AOS from "aos";
import "aos/dist/aos.css";
import IconNotify from "assets/svg/IconNotify";
import { token } from "firebase.js";
import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Router } from "react-router-dom";
import { clearMessage } from "./actions/message";
import { addTokenDevice, getProfileUser, logoutUser, removeTokenDevice } from "./actions/user";
import 'react-datetime-picker/dist/DateTimePicker.css';
import "./App.css";
import "./modules/styles/index.scss";
import "./component/index.scss";
import "./page/index.scss";
import "./firebase";
import { history } from "./helpers/history";
import Routes from "router";

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const tokenDevice = localStorage.getItem("deviceToken");
  const isNotify = useSelector((state) => state.user.isNotify);
  const url = window.location.href;

  const codeRef = url.split(`/referral/`)[1]?.slice(0, 6) || "";
  localStorage.setItem("codeRef", codeRef);

  if (url.includes("/verify-partner")) {
    const emailPartner = url.split(`/verify-partner?email=`)[1]?.split(`%40`)[0] + `@` + url.split(`%40`)[1]?.split(`&code=`)[0] || "";
    const codePartner = url.split(`/verify-partner?email=`)[1]?.split(`&code=`)[1] || "";

    dispatch(verifyPartner(codePartner, emailPartner))
      .then(() => {
        dispatch({
          type: ACTIVE_PARTNER,
          payload: true,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIVE_PARTNER,
          payload: false,
        });
      });
  }

  useEffect(() => {
    if (currentUser) {
      dispatch(getProfileUser())
        .then(() => {
          token.then((data) => {
            dispatch(addTokenDevice(data));
            localStorage.setItem("deviceToken", data);
          });
        })
        .catch(() => {
          dispatch(removeTokenDevice(tokenDevice));
          dispatch(logoutUser());
          history.push("/home");
        });
    }
  }, [currentUser, dispatch, isNotify, tokenDevice]);

  useEffect(() => {
    isSupported().then((value) => {
      if (value) {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          dispatch({
            type: IS_NOTIFY_SUCCESS,
          });
          localStorage.setItem("notify", JSON.stringify(true));
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              }  p-4 bg-white shadow-lg rounded-lg pointer-events-auto flex items-center ring-0`}
            >
              <IconNotify />
              <div className="ml-4 flex-1 flex flex-col items-center justify-center">
                <span className="text-lg font-bold text-black">{payload.notification.title}</span>
                <span className="text-sm font-medium text-gray-900">{payload.notification.body}</span>
              </div>
            </div>
          ));
        });
      }
    });

    history.listen(() => {
      dispatch(clearMessage()); // clear message when changing location
    });
    dispatch(getLocalCart());
    dispatch(getContractLottery());
  }, [dispatch]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

export default hot(App);
