import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import "./style.scss";

function Loading() {
  return (
    <div className="loading-page flex items-center justify-center">
      <ProgressSpinner />
    </div>
  );
}

export default Loading;
