import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.scss";
import { Button, Icon } from "modules";
import ModalLogin from "./components/login";
import UserMenu from "./components/userInformation";
import { useIsMobile } from "utils/utils";
import LogoPage from "assets/images/LogoPage.png";
import MenuMobile from "./components/menuMobile";

HeaderGlobal.propTypes = {
  classBackground: PropTypes.string,
};

function HeaderGlobal(props) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const [openModal, setOpenModal] = useState(false);
  const [header, setHeader] = useState(true);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  const listenScrollEvent = (event) => {
    window.scrollY > 0 ? setHeader(false) : setHeader(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return !isMobile ? (
    <header
      className={`flex header items-center fixed px-10 py-8 ${isLoggedIn ? "justify-between" : "justify-end"}  ${
        header ? "home-heading " : "page-heading "
      }`}
    >
      {isLoggedIn ? <UserMenu /> : <Button label={t("Login")} buttonType="secondary" onClick={() => setOpenModal(true)} />}
      <ModalLogin open={openModal} closeModal={() => setOpenModal(false)} />
    </header>
  ) : (
    <header className={`flex header items-center fixed justify-between ${header ? "home-heading " : "page-heading "}`}>
      <span
        onClick={() => {
          setOpenMenuMobile(true);
        }}
      >
        <Icon.Nav />
      </span>
      <img src={LogoPage} alt="LOT" className="logo" />

      <MenuMobile
        open={openMenuMobile}
        closeModal={() => setOpenMenuMobile(false)}
        openModalLogin={() => {
          setOpenMenuMobile(false);
          setOpenModal(true);
        }}
      />
      <ModalLogin open={openModal} closeModal={() => setOpenModal(false)} />
    </header>
  );
}

export default HeaderGlobal;
