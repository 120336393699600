let TranslationVI = {
  translations: {
    "en-US": "vi-VN",
    Home: "Trang chủ",
    Games: "Trò Chơi",
    "Winner Users": "Người Thắng cuộc",
    "Winner Numbers": "Kết Quả",
    "Winning Users": "Người Thắng cuộc",
    "Winning Numbers": "Kết Quả",
    News: "Tin Tức",
    "Contact Us": "Liên Hệ",
    About: "Về Chúng Tôi",
    "About Us": "Về Chúng Tôi",
    FAQ: "FAQ",
    "Term Of Service": "Điều khoản",
    "User Profile": "Hồ sơ",
    "Take the chance to change your life": "Nắm bắt cơ hội để thay đổi cuộc sống của bạn",
    "The world's hottest gaming platform. We bring a new level of availability and transparency in the gaming market.":
      "Nền tảng trò chơi hot nhất thế giới. Chúng tôi mang đến một mức độ khả dụng và minh bạch mới trong thị trường trò chơi.",
    "JOIN US": "THAM GIA",
    "Buy Lottery Tickets Online": "Mua Vé Số Trực Tuyến",
    "Buy lottery tickets online to the biggest lotteries in the world offering huge jackpot prizes that you can win when you play online lottery.":
      "Mua vé số trực tuyến để nhận được các giải thưởng xổ số lớn nhất trên thế giới mà bạn có thể giành được khi chơi xổ số trực tuyến.",
    hrs: "Giờ",
    mins: "Phút",
    secs: "Giây",
    "Lottery Jackpot": "Xổ số Jackpot",
    "Choose from the Powerball, Mega Millions, Lotto or Lucky Day Lotto and try for a chance to win a big cash prize":
      "Chọn Powerball, Mega Millions, Lotto hoặc Lucky Day Lotto để có cơ hội giành giải thưởng tiền mặt lớn",
    "Next Drawing": "Kỳ sổ số tiếp theo",
    "Buy Ticket": "Mua Vé",
    "Latest Lottery Results": "Kết Quả Xổ Số Mới Nhất",
    "Check your lotto results online, find all the lotto winning numbers and see if you won the latest lotto jackpots!":
      "Kiểm tra kết quả xổ số trực tuyến, kiểm tra tất cả các số trên vé số của bạn để có cơ hội trúng giải đặc biệt xổ số mới nhất hay không!",
    "Lottery Winning Numbers": "Kết Quả",
    "Lottery Winning Users": "Người Thắng cuộc",
    "See more": "Xem thêm",
    "How It Works": "Cách Thức Hoạt Động",
    "mmlotto is the best way to play these exciting lotteries from anywhere in the world.":
      "mmlotto là cách tốt nhất để chơi những loại xổ số thú vị từ mọi nơi trên thế giới.",
    Choose: "Lựa Chọn",
    "Choose your lottery & pick your numbers": "Chọn loại xổ số và chọn số của bạn muốn",
    Buy: "Mua",
    "Complete your purchase": "Hoàn thành giao dịch của bạn",
    "Start dreaming, you're almost there": "Đạt được điều ước của bạn",
    "Wallet Payment": "Thanh Toán Qua Ví",
    "Exchange Rawallet-paymentte": "Trao đổi Rawallet-paymentte",
    "Buy international lottery tickets online using any of the payment methods available on mmlotto Play now and win big!":
      "Mua vé số quốc tế trực tuyến bằng bất kỳ phương thức thanh toán nào có trên mmlotto và giành ngay chiến thắng lớn!",
    "Buy Game": "Mua Trò Chơi",
    "Our mmlotto lottery games are available on mobile app, please download and enjoy your time":
      "Trò chơi xổ số mmlotto của chúng tôi có sẵn trên ứng dụng di động, vui lòng tải xuống và tận hưởng thời gian của bạn",
    "Subscribe to Our Newsletter": "Theo dõi bản tin của chúng tôi",
    "Time is the most precious thing you have when bootstrapping. You can't take time.":
      "Thời gian là thứ quý giá nhất. Hãy xem tin tức của chúng tôi đễ giúp bạn không mất nhiều thời gian",
    "Enter your email": "Nhập Email của bạn",
    "Learn more": "Xem thêm",
    "Help Menu": "Menu",
    "View Profile": "Hồ Sơ",
    Setting: "Cài Đặt",
    Dashboard: "Thông Tin Chung",
    "Order History": "Lịch Sử Mua",
    "Winning History": "Lịch Sử Thắng",
    Wallet: "Ví",
    KYC: "KYC",
    "Account Security": "Bảo Vệ Tài Khoản",
    "Affiliate Member": "Liên Kết Thành Viên",
    "Support Ticket": "Hỗ Trợ",
    "Available Balance": "Số dư khả dụng",
    Wins: "Thắng",
    Losts: "Mất",
    "Read More": "Đọc thêm",
    "A few word about us": "Vài Điều Về Chúng Tôi",
    "Our goal is to provide our customers with the most convenient and enjoyable lottery purchasing experience.":
      "Mục tiêu của chúng tôi là mang đến cho khách hàng trải nghiệm mua xổ số thuận tiện và thú vị nhất.",
    "It’s not just what we do, it’s who we are. From past to present, we are a success story still in the making.With an enthusiasm to serve, commitment to providing fun and fair games is matched by our desire to make a positive difference in the community.":
      "Đó không chỉ là những gì chúng tôi làm, mà còn là con người của chúng tôi. Từ xưa đến nay, chúng tôi là một câu chuyện thành công vẫn đang được thực hiện.",
    "Playing It Global for Over a Decade": "Xuất hiện trên toàn cầu trong hơn một thập kỷ",
    "Play from Anywhere in the World": "Chơi từ mọi nơi trên thế giới",
    "Absolutely No Commissions Taken": "Hoàn toàn không thu phí hoa hồng",
    "A Simple and Secure Service": "Một dịch vụ đơn giản và an toàn",
    "Winning Made Easy": "Giành chiến thắng dễ dàng",
    "A bit of History": "Xem thêm lịch sử hình thành",
    "Why Choose Us?": "Tại Sao Chọn Chúng Tôi?",
    "Description Why":
      "Với các giải độc đắc tốt nhất trên thế giới để bạn lựa chọn, đã có hàng triệu lý do tại sao bạn nên chơi với xổ số megabing. Tất cả những gì bạn cần là sáu số - và mỗi một trong số chúng đều chiến thắng:",
    "Biggest lottery jackpots": "Giải đặc biệt xổ số lớn nhất",
    "Performance Bonuses": "Tiền thưởng hiệu suất",
    "Dedicated Support": "Hỗ trợ nhiệt tình",
    "Reliable Payment": "Thanh toán đáng tin cậy",
    "Unlimited Affiliates": "Không giới hạn liên kết",
    "Instant payout system": "Hệ thông thanh toán nhanh chóng",
    "Buying date": "Ngày mua",
    "Buying Date": "Ngày Mua",
    Number: "Vé số",
    Price: "Giá",
    Win: "Thắng",
    Withdraw: "Rút tiền",
    Deposit: "Nạp tiền",
    History: "Lịch sử",
    "Amount to withdraw": "Giá trị rút",
    "Amount to deposit": "Giá trị nạp",
    "Withdraw from My Wallet to": "Rút tiền từ Ví của tôi đến",
    "Exchange rate": "Tỷ giá",
    Source: "Nguồn",
    "last update": "cập nhật lần cuối",
    "Wallet Address": "Địa chỉ ví",
    Fee: "Phí",
    Note: "Lưu ý",
    "Minimum deposit amount is 10$": "Số tiền nạp tối thiểu là 10$",
    "Minimum withdraw amount is 10$": "Số tiền rút tối thiểu là 10$",
    "Know Your Customers": "Biết Thêm Về Bạn",
    "Let’s start with getting to know your profession": "Hãy bắt đầu với việc cập nhật thêm thông tin của bạn",
    "Personal Information": "Thông tin cá nhân",
    "First name": "Tên",
    "Middle name": "Tên đệm",
    "Last name": "Họ",
    Gender: "Giới tính",
    Male: "Nam",
    Female: "Nữ",
    "Phone number": "SĐT",
    "Date of birth": "Ngày sinh",
    "ID card": "Số chứng minh",
    "Date of issue": "Ngày phát hành",
    Address: "Địa chỉ",
    Country: "Quốc gia",
    "State (Optional)": "Bang (Không bắt buộc)",
    "Postcode (Ip code)": "Mã bưu điện (Không bắt buộc)",
    "Address 2 (Optional)": "Địa chỉ 2 (Không bắt buộc)",
    City: "Thành phố",
    "Upload your document": "Tải lên thông tin của bạn",
    "Your sefile": "Ảnh sefile",
    "Indentify Card": "Thẻ Chứng Minh",
    "Show Indentify Card": "Hiện thẻ chứng minh",
    "(Front)": "(Trước)",
    "(Back)": "(Sau)",
    Save: "Lưu",
    Submit: "Hoàn Thành",
    "First name is required.": "Vui lòng nhập tên.",
    "Middle name is required.": "Vui lòng nhập tên đệm.",
    "Last name is required.": "Vui lòng nhập họ.",
    "Gender is required.": "Vui lòng chọn giới tính.",
    "Phone number is required.": "Vui lòng nhập SĐT.",
    "Only numbers are allowed": "Chỉ được nhập số",
    "No less than 8 numbers": "Nhiều hơn 8 số",
    "ID card is required.": "Vui lòng nhập số chứng minh",
    "City is required.": "Vui lòng nhập tên thành phố",
    "Address is required.": "Vui lòng nhập địa chỉ",
    "Wallet address is required.": "Vui lòng nhập địa chỉ ví",
    "Birthday is required.": "Vui lòng chọn ngày sinh.",
    "Issue date is required.": "Vui lòng chọn ngày phát hành.",
    "Enter your first name": "Nhập tên của bạn",
    "Enter your middle name": "Nhập tên đệm của bạn",
    "Enter your last name": "Nhập họ của bạn",
    "Select your gender": "Chọn giới tính",
    "Enter your phone number": "Nhập số điện thoại của bạn",
    "Choose country": "Chọn quốc gia",
    State: "Bang",
    "Identify number": "Số chứng minh",
    Great: "Tuyệt vời",
    Succesfull: "Thành Công",
    "Your checkout is successful.": "Thanh toán của bạn thành công.",
    "Thank for using our services.": "Cảm ơn đã sử dụng dịch vụ của chúng tôi.",
    Sorry: "Xin Lỗi",
    "Your payment is currently unavailable.": "Thanh toán của bạn hiện không Khả dụng.",
    "Your account has insufficient funds!": "Tài khoản của bạn không đủ tiền!",
    "Data not found": "Không có dữ liệu",
    Languages: "Ngôn ngữ",
    Notification: "Thông báo",
    "Push Notifications": "Bật thông báo",
    "Email Notifications": "Thông báo Email",
    "Change Login Password": "Đổi mật khẩu",
    "Current Password": "Mật khẩu hiện tại",
    "New Password": "Mật khẩu mới",
    "Confirm Password": "Nhập lại mật khẩu",
    "Enter your current password": "Nhập mật khẩu hiện tại",
    "Enter your new password": "Nhập mật khẩu mới",
    "Current password is required.": "Vui lòng nhập mật khẩu hiện tại.",
    "New password is required.": "Vui lòng nhập mật khẩu mới.",
    "Confirm password is required.": "Vui lòng nhập lại mật khẩu mới.",
    "Password must be between 8 and 40 characters.": "Mật khẩu phải từ 8 đến 40 kí tự.",
    "Password's not match": "Mật khẩu nhập lại không giống mật khẩu mới",
    "Change password": "Đổi mật khẩu",
    "Members by country": "Thành viên theo quốc gia",
    "Country with the larggest number of members": "Quốc gia có số lượng thành viên đông nhất",
    "No. Of Members": "Số thành viên",
    Income: "Thu Nhập",
    "Income from member": "Thu nhập từ thành viên",
    "You have no income from the f1": "Bạn không có thu nhập từ f1",
    "Membership Growth": "Tăng trưởng thành viên",
    "New members": "Thành viên mới",
    Earning: "Thu nhập",
    "1 week": "1 tuần",
    "1 month": "1 tháng",
    "1 year": "1 năm",
    "Earning by Campaign Types": "Thu nhập theo các loại chiến dịch",
    All: "Tất cả",
    Personal: "Cá nhân",
    Referral: "Giới thiệu",
    "Your team": "Đội của bạn",
    "Active members": "Thành viên HĐ",
    Member: "Thành viên",
    "Persional Income": "Thu nhập cá nhân",
    Commisions: "Hoa hồng",
    "Ticket ID": "ID vé",
    "Sending date": "Ngày gửi",
    Subject: "Chủ đề",
    Status: "Trạng thái",
    Options: "Tùy chọn",
    "Create A Ticket": "Tạo vé",
    "Terms & Conditions": "Điều khoản sử dụng",
    "Dummy text is also used to demostate the aprreasrance of different typefaces and layouts":
      "Văn bản giả cũng được sử dụng để giảm bớt sự sai lệch của các kiểu chữ và bố cục khác nhau",
    "1. How to successful our business plan": "1. Làm thế nào để thành công kế hoạch kinh doanh của chúng tôi",
    "When replacing a multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained. multi-lined selection of text, the generated dummy text maintains the amount of lines.":
      "Khi thay thế một lựa chọn văn bản có nhiều dòng, văn bản giả được tạo sẽ duy trì số lượng dòng. Khi thay thế một lựa chọn văn bản trong một dòng, số lượng từ gần như được duy trì. lựa chọn nhiều dòng của văn bản, văn bản giả được tạo ra duy trì số lượng dòng.",
    "2. Clients satisfaction make company value": "2. Sự hài lòng của khách hàng làm nên giá trị của công ty",
    "3. World class creative firm": "3. Công ty sáng tạo đẳng cấp thế giới",
    "4. We are the best online platform": "4. Chúng tôi là nền tảng trực tuyến tốt nhất",
    "5. Clients satisfaction make company value": "5. Sự hài lòng của khách hàng làm nên giá trị của công ty",
    "Games Policy": "Chính sách trò chơi",
    "When replacing a multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained. multi-lined selection of text, the generated dummy text maintains the amount of lines. When replacing a selection of text within a single line, the amount of words is roughly being maintained.multi-lined selection of text":
      "Khi thay thế một lựa chọn văn bản có nhiều dòng, văn bản giả được tạo sẽ duy trì số lượng dòng. Khi thay thế một lựa chọn văn bản trong một dòng, số lượng từ gần như được duy trì. lựa chọn nhiều dòng của văn bản, văn bản giả được tạo ra duy trì số lượng dòng. Khi thay thế một lựa chọn văn bản trong một dòng, số lượng từ gần như được duy trì.",
    "The amount of words is world": "Số lượng từ thế giới",
    "When replacing a selection": "Khi thay thế một lựa chọn",
    "Maintains the amount of lines": "Duy trì số lượng dòng",
    "Words is roughly being main": "Từ ngữ đại khái là chính",
    "Back to news": "Trở về trang tin tức",
    "SIGN IN": "ĐĂNG NHẬP",
    "Not a member?": "Chưa có tài khoản?",
    "now!": "ngay!",
    "Email Address": "Địa chỉ Email",
    "Password is required.": "Vui lòng nhập mật khẩu.",
    "Your Password": "Mật khẩu",
    "Remember me?": "Nhớ tài khoản?",
    "Forgotten your password?": "Bạn quên mật khẩu?",
    "Sign In": "Đăng Nhập",
    "Email is required.": "Vui lòng nhập Email.",
    "This is not a valid email.": "Đây không phải email.",
    Login: "Đăng Nhập",
    "No account logged in": "Chưa có tài khoản đăng nhập",
    Logout: "Đăng Xuất",
    "Get In Touch": "Liên Lạc",
    "If you have any questions or queries our helpful support team will be more than happy to assist.":
      "Nếu bạn có bất kỳ câu hỏi thắc mắc nào, chúng tôi sẽ rất sẵn lòng hỗ trợ.",
    "Please see our Frequent Asked Questions (FAQ) page to read more information":
      "Vui lòng xem trang Câu hỏi thường gặp (FAQ) của chúng tôi để đọc thêm thông tin",
    "Full name is required.": "Vui lòng điền đầy đủ họ tên.",
    Message: "Lời Nhắn",
    "Check FAQs": "FAQs",
    Send: "Gửi",
    "Password Recovery": "Khôi phục mật khẩu",
    "Enter your email address to reset your password.": "Nhập địa chỉ email của bạn để thiết lập lại mật khẩu của bạn.",
    "Send Code": "Gửi Code",
    "OTP Verification": "Xác minh OTP",
    "We'll send a verification code to the email address you used to reset the password.":
      "Chúng tôi sẽ gửi mã xác minh đến địa chỉ email bạn đã sử dụng để đặt lại mật khẩu",
    "Code expires in": "Mã hết hạn sau",
    "Code has expired": "Mã đã hết hạn",
    Verify: "Xác nhận",
    "The code is not correct": "Mã OTP không chính xác",
    "We'll send a verification code to the email address you used to create the account.":
      "Chúng tôi sẽ gửi mã xác minh đến địa chỉ email bạn đã sử dụng để tạo tài khoản.",
    "Enter your password": "Nhập mật khẩu",
    "Enter your confirm Password": "Nhập lại mật khẩu",
    "The email has already been taken": "The email has already been taken",
    "Already have an account? Please go to": "Bạn đã có tải khoản? Vui lòng tới trang",
    "Full Name": "Họ và Tên",
    "Enter full name": "Nhập đầy đủ họ và tên",
    "Your Email": "Địa chỉ Email",
    REGISTER: "ĐĂNG KÝ",
    "Country is required.": "Vui lòng chọn quốc gia.",
    Register: "Đăng ký",
    "Select your country": "Chọn quốc gia của bạn",
    "Referral Code": "Mã giới thiệu",
    "Referral By": "Người giới thiệu",
    Password: "Mật khẩu",
    Remove: "Xóa",
    Upload: "Tải lên",
    "Frequently Asked Questions": "Câu hỏi thường gặp",
    Winning: "Chiến thắng",
    Result: "Kết quả",
    "Page not found": "Trang không được tìm thấy",
    "Sorry, but the page you are looking is not found. Please, make sure you have typed the current URL":
      "Xin lỗi, nhưng không tìm thấy trang bạn đang tìm. Vui lòng kiểm tra lại đường dẫn hiện tại của bạn",
    "Go To HomePage": "Trở về Trang chủ",
    "Descriptopn buy ticket":
      "Up is opinion message manners correct hearing husband my. Dispos commanded dashwoods cordially depending at at. Its strangers who you certainty earnestly resources suffering she. Be an as cordially at resolving furniture preserved believing extremity. Easy mr pain felt in. Too northward affection additions nay.",
    Jackpot: "Jackpot",
    "Nex Drawing": "Kỳ sổ số tiếp theo",
    "Add To Card": "Add To Card",
    Clear: "Xóa",
    Filter: "Lọc",
    "From date": "Từ ngày",
    "Address Wallet": "Địa chỉ ví",
    "Enter your address wallet": "Nhập địa chỉ ví",
    "Select status transaction": "Chọn trạng thái giao dịch",
    Type: "Loại",
    "Select type transaction": "Chọn loại giao dịch",
    Pending: "Chờ",
    Success: "Thành công",
    Rejected: "Từ chối",
    "Receipt Number": "Số biên nhận",
    Quantity: "Số lượng",
    withdraw: "Rút tiền",
    Time: "Thời gian",
    To: "Đến",
    Amount: "Số lượng",
    Value: "Giá trị",
    "Transaction Fee": "Phí giao dịch",
    "Transaction Hash": "Hash giao dịch",
    Confirm: "Đồng ý",
    "Transaction Details": "Chi tiết giao dịch",
    "Order details": "Chi tiết đơn hàng",
    "Order ID": "ID đơn hàng",
    Total: "Tổng cộng",
    Tickets: "Vé",
    deposit: "Nạp tiền",
    "Add To Cart": "Thêm vào giỏ",
    "Buy Now": "Mua ngay",
    "Quick Pick": "Chọn nhanh",
    "Add Cart": "Giỏ Hàng",
    Cart: "Giỏ hàng",
    "Affliate On Home Page": "Liên Kết Trang",
    "Tell a friend is our unique lottery friend promotion club that enables you to earn amazing bonus money rewaerds for inviting friends to play the word's biggest jackpots!":
      "Giới thiệu với bạn bè đến với trang xổ số của chúng tôi để bạn có thể kiếm được phần thưởng tiền thưởng đáng kinh ngạc khi mời bạn bè chơi giải độc đắc lớn nhất thế giới!",
    "Tell a friend is our unique lottery friend promotion club that enables":
      "Giới thiệu với bạn bè là cách giúp bạn được nhiều ưu đãi trong xổ số của chúng tôi",
    "Reliable Payments": "Thanh toán đáng tin cậy",
    "Exchange Rate": "Tỷ giá",
    "Buy international lottery tickets online using any of the payment methods available on SuperLuck Play now and win big!":
      "Mua vé số quốc tế trực tuyến bằng bất kỳ phương thức thanh toán nào có trên SuperLuck và thắng lớn!",
    "All Rights Reserved © 2020 by mmlotto": "Bản quyền thuộc mmlotto © 2020",
    "New Coming Product": "Sản Phẩm Sắp Ra Mắt",
    "Learn More": "Xem thêm",
    "Reserve now for 58% off": "Đặt trước để được giảm giá 58%",
    "Mega6X Max": "Mega6X Max",
    "COMING SOON TO KICKSTARTER": "SẮP TỚI KICKSTARTER",
    "Not only is Mega 6X Pro your best chance to pocket , but it’s also only $0 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else.":
      "Mega 6X Pro không chỉ là cơ hội tốt nhất để bạn bỏ túi mà còn chỉ có $ 0 để chơi. Hơn hết, nếu bạn giành được giải thưởng cao nhất, bạn sẽ không phải chia sẻ nó với bất kỳ ai khác.",
    "Not only is Mega 6X Max your best chance to pocket , but it’s also only $0 to play. Best of all, if you win the top prize, you won’t have to share it with anyone else.":
      "Mega 6X Max không chỉ là cơ hội tốt nhất để bạn bỏ túi mà còn chỉ có $ 0 để chơi. Hơn hết, nếu bạn giành được giải thưởng cao nhất, bạn sẽ không phải chia sẻ nó với bất kỳ ai khác.",
    "Up is opinion message manners correct hearing husband my. Dispos commanded dashwoods cordially depending at at. Its strangers who you certainty earnestly resources suffering she. Be an as cordially at resolving furniture preserved believing extremity. Easy mr pain felt in. Too northward affection additions nay.":
      "Lên là quan điểm cách cư xử đúng nghe chồng tôi. Dispos thân mật chỉ huy dashwoods vào lúc. Những người xa lạ của nó, những người mà bạn chắc chắn đang kiếm tìm nguồn lực làm khổ cô ấy. Hãy là một người thân thiện trong việc giải quyết đồ đạc được bảo tồn tin tưởng cực đoan. Dễ dàng cảm thấy đau đớn ông bà. Quá hướng bắc tình cảm thêm nay.",
    "High Revenues": "Doanh thu cao",
    "Unlimited Affilicates": "Liên kết không giới hạn",
    By: "Bởi",
    "No tickets": "Không có vé",
    "Other News": "Tin Tức Khác",
    "Scan address to receive payment": "Quét địa chỉ để nhận thanh toán",
    "You can't withdraw!": "Bạn không thể rút tiền!",
    "You can't deposit!": "Bạn không thể nạp tiền!",
    "Table View": "Dạng Bảng",
    "Tree View": "Dạng Cây",
    Max: "Tài",
    Min: "Xỉu",
    Even: "Chẵn",
    Odd: "Lẻ",
    MMLotto: "MMLotto",
    "Get Lucky Number": "Nhận Số May Mắn",
    "History Jackpot": "Lịch sử giải độc đắc",
    Whitepaper: "Whitepaper",
    "Term of User": "Điều khoản người dùng",
    "Terms for Fire Sale": "Điều khoản bán hàng cháy",
    "Terms for Airdrop": "Điều khoản dành cho Airdrop",
    "Privacy Policy": "Chính sách quyền riêng tư",
    "Welcome back!": "Chào mừng trở lại!",
    "We're so excited to see you again!": "Chúng tôi rất vui mừng được gặp lại bạn!",
    "Log In to your MMLotto !!": "Đăng nhập vào MMLotto của bạn !!",
  },
};

export default TranslationVI;
