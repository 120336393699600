import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import TableUserWinning from "component/tableUserWinning";
import TableNumberWinning from "component/tableNumberWinning";

function LotteryResult(props) {
  const { t } = useTranslation();
  return (
    <div className="lottery-result">
      <div className="container text-center pt-12 pb-10">
        <h3 className="font-title-section font-bold" data-aos="fade-up" data-aos-duration="600">{t("Latest Results")}</h3>
        <p className="home-title xl:mx-80 mx-0" style={{ marginBottom: "2.5rem" }} data-aos="fade-up" data-aos-duration="600" data-aos-delay="100">
          {t(
            "Check your game results online, find all the winning numbers and see if you won the latest jackpots!"
          )}
        </p>
        <div className="lg:flex lg:items-start lg:justify-center gap-x-8">
          <TableNumberWinning draw={props.draw} />
          <TableUserWinning winners={props.winners} />
        </div>
      </div>
    </div>
  );
}

export default LotteryResult;
