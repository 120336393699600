import React from "react";
import LogoPage from "assets/images/LogoPage.png";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Icon } from "modules";
import { NavLink } from "component/navlink";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

export const MenuGlobal = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const listMenu = [
    {
      icon: <Icon.Cherry />,
      label: t("MMLotto"),
      path: "/",
    },
    {
      icon: <Icon.Lucky />,
      label: t("Get Lucky Number"),
      path: "/get-lucky-number",
    },
    {
      icon: <Icon.History />,
      label: t("History Jackpot"),
      path: "/history-jackpot",
    },
    {
      icon: <Icon.Dashboard />,
      label: t("Dashboard"),
      path: "/overview",
    },
  ];

  return (
    <div className="menu-global relative">
      <div className="sticky top-12">
        <div className="flex algin-center justify-center mb-25">
          <img src={LogoPage} alt="LOT" />
        </div>

        {listMenu
          .filter((item) => (isLoggedIn ? item : item.path !== "/overview"))
          .map((item, idx) => (
            <NavLink
              key={idx}
              className={`flex items-center gap-3 menu-item ${
                location.pathname === item.path ||
                (item.path === "/overview" &&
                  ["/overview", "/order-history", "/settings", "/affiliate-member", "/kyc", "/deposit", "/withdraw", "/exchange"].includes(
                    location.pathname
                  ))
                  ? "active"
                  : ""
              }`}
              path={item.path}
            >
              {item.icon}
              <span>{item.label}</span>
            </NavLink>
          ))}
      </div>
    </div>
  );
};
