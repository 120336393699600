import React from "react";
import { Dialog } from "primereact/dialog";
import "./style.scss";

function ModalRulesGame({ typeGame, displayModal, closeModal }) {
  return (
    <Dialog
      visible={displayModal}
      onHide={() => closeModal()}
      className="modal-rules"
      showHeader={false}
      dismissableMask={true}
      closable={false}
      style={{ width: "50rem" }}
    >
      <div className="modal-rules-content text-center">
        <button
          className="btn-close"
          onClick={() => {
            closeModal();
          }}
        >
          <span className=" pi pi-times"></span>
        </button>
        <div className="container">
          <h1>Rules</h1>
          {typeGame === "PickNumber" ? (
            <div className="text-left">
              <p>MMLotto is one of the most popular lottery games in the world with the appeal of big prizes, attracting players everywhere.</p>
              <p>Simple to play but brings extremely valuable rewards to lucky players.</p>
              <p>How to play:</p>
              <ul className="ml-2">
                <li>
                  1. Number selection:
                  <ul className="ml-4">
                    <li>Player chooses 6 lucky numbers from 1 to 9.</li>
                  </ul>
                </li>
                <li>
                  2. Buy tickets:
                  <ul className="ml-4">
                    <li>Each ticket is worth 2 USDT.</li>
                    <li>You can buy multiple Tickets with the same number sequence.</li>
                  </ul>
                </li>
                <li>
                  3. Drawing process:
                  <ul className="ml-4">
                    <li>The drawing will be completely random.</li>
                    <li>Each number drawing time is every 4 hours and 24/7.</li>
                  </ul>
                </li>
                <li>
                  4. Jackpot reward:
                  <ul className="ml-4">
                    <li>Jackpot prize can be up to hundreds of millions of USDT, depending on the bet amount and number of participating players.</li>
                  </ul>
                </li>
              </ul>
            </div>
          ) : typeGame === "MaxMin" ? (
            <div className="text-left">
              <p>Over and Under is the hottest game in the world today because the rules are very simple, easy to play and easy to win.</p>
              <p>Results will be based entirely on MMLotto game results. Players will predict whether it is Over or Under.</p>
              <p>Formula to calculate the result:</p>
              <ul className="ml-2">
                <li>- Sum 6 digits of game results</li>
                <li>
                  - Divide that sum by 2
                  <ul className="ml-4">
                    <li>- Over: Result {`>`} 27</li>
                    <li>- Under: Result {`<`} 27</li>
                    <li>- Draw: Result = 27</li>
                  </ul>
                </li>
              </ul>
              <p>Play time is counted from the time the MMLotto game is opened until the result.</p>
              <p>The game is played with MUT only and rewards with MUT.</p>
              <p>Odds of Over and Under is 1 to 1.95</p>
            </div>
          ) : (
            <div className="text-left">
              <p>Even and Odd is the hottest game in the world today because the rules are very simple, easy to play and easy to win.</p>
              <p>Results will be based entirely on MMLotto game results. Players will predict whether it will be Even or Odd.</p>
              <p>Formula to calculate the result:</p>
              <ul className="ml-2">
                <li>
                  - Sum 6 digits of game results
                  <ul className="ml-4">
                    <li>- Even: The result is an even number</li>
                    <li>- Odd: The result is an odd number</li>
                  </ul>
                </li>
              </ul>
              <p>Play time is counted from the time the MMLotto game is opened until the result.</p>
              <p>The game only plays with MUT and pays with MUT.</p>
              <p>Odds of Even and Odd is 1 to 1.95</p>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default ModalRulesGame;
