import React, { FC } from "react";
import Select from "react-select";

import { ObjectUtils } from "modules";

export const TableFilterInputSelect = (props) => {
  const { options, isClearable = true } = props;
  const key = props.paramKey;

  return (
    <Select
      options={options.filter((v) => v.value !== props.params[key])}
      className="TableFilterInputSelect"
      classNamePrefix="TableFilterInputSelect"
      placeholder={"-- " + "Select" + " --"}
      onChange={(option) => {
        props.onChange({ [key]: ObjectUtils.getIn(option, "value", "") });
      }}
      defaultValue={options.find((v) => v.value === props.params[key])}
      isClearable={isClearable}
      components={{ IndicatorSeparator }}
    />
  );
};

const IndicatorSeparator = (props) => null;
