import { combineReducers } from "redux";
import auth from "./auth";
import directory from "./directory";
import message from "./message";
import styleHeading from "./styleHeading";
import phase from "./phase";
import game from "./game";
import user from "./user";
import wallet from "./wallet";
import language from "./language";
import post from "./post";
import loading from "./loading";

export default combineReducers({
  auth,
  directory,
  message,
  styleHeading,
  phase,
  game,
  user,
  wallet,
  language,
  post,
  loading,
});
