import React, { useState, useEffect } from "react";
import "./style.scss";
import { Avatar } from "primereact/avatar";
import { useTranslation } from "react-i18next";
// import { Toaster } from "react-hot-toast";
import NoUser from "assets/images/NoUser.png";
import { TabPanel, TabView } from "primereact/tabview";

function FormUserDocument(props) {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(props?.activeIdx || 0);

  useEffect(() => {
    if (props?.activeIdx !== undefined && props?.activeIdx !== activeIndex) {
      setActiveIndex(props.activeIdx);
    }
  }, [props?.activeIdx, activeIndex]);

  return (
    <div className="form-document">
      <h3 className="form-title mt-12">{"UPLOAD YOUR DOCUMENT"}</h3>

      <div className={`xl:flex items-center justify-center gap-8 text-center`}>
        <div className="kyc-card text-center mx-auto mb-6 xl:mb-0">
          <h2 className="mb-10">{t("Your selfie")}</h2>
          <Avatar
            shape="circle"
            image={
              props.imgSelfie
                ? URL.createObjectURL(props.imgSelfie)
                : props.kyc?.selfie
                ? props.kyc?.selfie
                : NoUser
            }
            size="large"
          />
          <div
            className={`${props.verify && "hidden"} flex items-center justify-center gap-2.5 mt-14`}
          >
            <div
              className={`${props.remove ? "btn-upload cursor-pointer" : "btn-remove "}  `}
              onClick={(e) => props.handleSelfie(e, false)}
            >
              <span>{t("Remove")}</span>
            </div>
            <label className="cursor-pointer btn-upload  m-0" htmlFor="upload-selfie">
              {t("Upload file")}
            </label>
            <input
              onChange={(e) => props.handleSelfie(e, true)}
              type="file"
              className="hidden"
              id="upload-selfie"
            />
          </div>
        </div>

        <TabView className="flex-1 xl:mt-0 tab-form" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={t("Indentify Card")}>
            <div className="sm:flex items-center gap-2 xl:gap-8">
              <div className="kyc-card text-center mt-6 mx-auto sm:mr-4 inline-block xl:m-0">
                <h2 className="mb-10">
                  {t("Indentify Card")} {t("(Front)")}
                </h2>
                {props.indentifyCardFront || props.kyc?.front_identity_card ? (
                  <img
                    className="img-indentify mx-auto"
                    src={
                      props.indentifyCardFront
                        ? URL.createObjectURL(props.indentifyCardFront)
                        : props.kyc?.front_identity_card
                    }
                    alt="front_identity_card"
                  />
                ) : (
                  <div className="kyc-card__content">
                    <span>
                      {t("Show Indentify Card")} {t("(Front)")}
                    </span>
                  </div>
                )}
                <div
                  className={`${
                    props.verify && "hidden"
                  } flex items-center justify-center gap-2.5 mt-14`}
                >
                  <div
                    className={`${
                      props.removeFront ? "btn-upload cursor-pointer" : "btn-remove "
                    }  `}
                    onClick={(e) => props.handleFront(e, false)}
                  >
                    <span>{t("Remove")}</span>
                  </div>
                  <label
                    className="cursor-pointer btn-upload  m-0"
                    htmlFor="upload-front_identity_card"
                  >
                    {t("Upload file")}
                  </label>
                  <input
                    onChange={(e) => props.handleFront(e, true)}
                    type="file"
                    className="hidden"
                    id="upload-front_identity_card"
                  />
                </div>
              </div>

              <div className="kyc-card text-center mt-6 mx-auto sm:ml-4 inline-block xl:m-0">
                <h2 className="mb-10">
                  {t("Indentify Card")} {t("(Back)")}
                </h2>
                {props.indentifyCardBack || props.kyc?.back_identity_card ? (
                  <img
                    className="img-indentify mx-auto"
                    src={
                      props.indentifyCardBack
                        ? URL.createObjectURL(props.indentifyCardBack)
                        : props.kyc?.back_identity_card
                    }
                    alt="Back_identity_card"
                  />
                ) : (
                  <div className="kyc-card__content">
                    <span>
                      {t("Show Indentify Card")} {t("(Back)")}
                    </span>
                  </div>
                )}
                <div
                  className={` ${
                    props.verify && "hidden"
                  } flex items-center justify-center gap-2.5 mt-14`}
                >
                  <div
                    className={`${
                      props.removeBack ? "btn-upload cursor-pointer" : "btn-remove "
                    }  `}
                    onClick={(e) => props.handleBack(e, false)}
                  >
                    <span>{t("Remove")}</span>
                  </div>
                  <label
                    className="cursor-pointer btn-upload  m-0"
                    htmlFor="upload-Back_identity_card"
                  >
                    {t("Upload file")}
                  </label>
                  <input
                    onChange={(e) => props.handleBack(e, true)}
                    type="file"
                    className="hidden"
                    id="upload-Back_identity_card"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header={t("Passport")}>
            <div className="kyc-card flex flex-col mt-6 mx-auto inline-block xl:m-0">
              <h2 className="mb-10">
                {t("Passport")} {t("(Front)")}
              </h2>
              {props.passportFront || props.kyc?.front_passport ? (
                <img
                  className="img-indentify mx-auto"
                  src={
                    props.passportFront
                      ? URL.createObjectURL(props.passportFront)
                      : props.kyc?.front_passport
                  }
                  alt="front_identity_card"
                />
              ) : (
                <div className="kyc-card__content">
                  <span>
                    {t("Show Indentify Card")} {t("(Front)")}
                  </span>
                </div>
              )}
              <div
                className={` ${
                  props.verify && "hidden"
                } flex items-center justify-center gap-2.5 mt-14`}
              >
                <div
                  className={`${props.removePass ? "btn-upload cursor-pointer" : "btn-remove "}  `}
                  onClick={(e) => props.handlePaspost(e, false)}
                >
                  <span>{t("Remove")}</span>
                </div>
                <label className="cursor-pointer btn-upload  m-0" htmlFor="upload-front_passport">
                  {t("Upload file")}
                </label>
                <input
                  onChange={(e) => props.handlePaspost(e, true)}
                  type="file"
                  className="hidden"
                  id="upload-front_passport"
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}

export default FormUserDocument;
