import {
    FETCH_PROFILE_USER_FAIL,
    FETCH_PROFILE_USER_SUCCESS,
    LOG_OUT_USER,
    LOGOUT,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    SET_MESSAGE,
    GET_KYC_SUCCESS,
    GET_KYC_FAIL,
    UPDATE_KYC_PERSONAL_SUCCESS,
    UPDATE_KYC_PERSONAL_FAIL,
    UPDATE_KYC_ADDRESS_SUCCESS,
    UPDATE_KYC_ADDRESS_FAIL,
    UPDATE_KYC_DOCUMENT_SUCCESS,
    UPDATE_KYC_DOCUMENT_FAIL,
    CREATE_TICKET,
    CLEAR_CART_SHOP,
    UPDATE_AVA,
    UPLOAD_AVATAR_FAIL,
    UPLOAD_AVATAR_SUCCESS,
    LOADING_FAIL,
    GET_NOTIFY_OTHER,
    PUSH_NOTiFY,
    GET_NOTIFY_WALLET,
    ADD_TOKEN,
    REMOVE_TOKEN,
    GET_REFERRAL_SUCCESS,
    GET_REFERRAL_FAIL,
    GET_LIST_REFERRAL_SUCCESS,
    GET_LIST_REFERRAL_FAIL,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_UPDATE_FAIL,
    CHANGE_DISPLAY_NAME,
    REGISTER_PARTNER_SUCCESS,
    REGISTER_PARTNER_FAIL,
} from "./types";
import toast from "react-hot-toast";
import UserService from "../services/user.service";

export const getProfileUser = () => (dispatch) => {
    return UserService.getProfileUser().then(
        (response) => {
            dispatch({
                type: FETCH_PROFILE_USER_SUCCESS,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const message =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.message || error.response.data.errors)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: FETCH_PROFILE_USER_FAIL,
            });

            // dispatch({
            //   type: SET_MESSAGE,
            //   payload: message,
            // });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const logoutUser = () => (dispatch) => {
    return UserService.logoutUser().then(
        (response) => {
            dispatch({
                type: LOG_OUT_USER,
                payload: response.data.data,
            });

            dispatch({
                type: LOGOUT,
            });

            dispatch({
                type: CLEAR_CART_SHOP,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            localStorage.removeItem("user");
            localStorage.removeItem("ticketsCart");
            return Promise.resolve();
        },
        (error) => {
            // const message =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.message || error.response.data.errors)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: LOGOUT,
            });

            // dispatch({
            //   type: SET_MESSAGE,
            //   payload: message,
            // });

            dispatch({
                type: CLEAR_CART_SHOP,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            localStorage.removeItem("user");
            localStorage.removeItem("ticketsCart");
        }
    );
};

export const resetPassword = (currentPassword, newPassword) => (dispatch) => {
    return UserService.resetPassword(currentPassword, newPassword).then(
        (response) => {
            // dispatch({
            //   type: SET_MESSAGE,
            //   payload: response.data.message,
            // });

            toast.success(response.data.message);

            dispatch({
                type: RESET_PASSWORD_SUCCESS,
            });
            dispatch({
                type: LOGOUT,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: RESET_PASSWORD_FAIL,
            });

            // dispatch({
            //   type: SET_MESSAGE,
            //   payload: error.response.data.message,
            // });

            toast.error(error.response.data.message);

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getKYC = () => (dispatch) => {
    return UserService.getKYC().then(
        (response) => {
            dispatch({
                type: GET_KYC_SUCCESS,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: GET_KYC_FAIL,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            // dispatch(setMessage("verifyEmail", error_code));

            return Promise.reject();
        }
    );
};

export const updateKYCPersonalInfo =
    (first_name, middle_name, last_name, dob, gender, identity_card, issue_date, phone_number) =>
    (dispatch) => {
        return UserService.updateKYCPersonalInfo(
            first_name,
            middle_name,
            last_name,
            dob,
            gender,
            identity_card,
            issue_date,
            phone_number
        ).then(
            (response) => {
                dispatch({
                    type: UPDATE_KYC_PERSONAL_SUCCESS,
                    payload: response.data.data,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                return Promise.resolve();
            },
            (error) => {
                // const error_code =
                //   (error.response &&
                //     error.response.data &&
                //     (error.response.data.error_code || error.response.data.message)) ||
                //   error.message ||
                //   error.toString();

                dispatch({
                    type: UPDATE_KYC_PERSONAL_FAIL,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                // dispatch(setMessage("verifyEmail", error_code));

                return Promise.reject();
            }
        );
    };

export const updateKYCAddressInfo =
    (country_id, state_province, city, postal_code, address1, address2) => (dispatch) => {
        return UserService.updateKYCAddressInfo(
            country_id,
            state_province,
            city,
            postal_code,
            address1,
            address2
        ).then(
            (response) => {
                dispatch({
                    type: UPDATE_KYC_ADDRESS_SUCCESS,
                    payload: response.data.data,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                return Promise.resolve();
            },
            (error) => {
                // const error_code =
                //   (error.response &&
                //     error.response.data &&
                //     (error.response.data.error_code || error.response.data.message)) ||
                //   error.message ||
                //   error.toString();

                dispatch({
                    type: UPDATE_KYC_ADDRESS_FAIL,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                // dispatch(setMessage("verifyEmail", error_code));

                return Promise.reject();
            }
        );
    };

export const uploadKYCDocument =
    (selfie, front_identity_card, back_identity_card, front_passport) => (dispatch) => {
        return UserService.uploadKYCDocument(
            selfie,
            front_identity_card,
            back_identity_card,
            front_passport
        ).then(
            (response) => {
                dispatch({
                    type: UPDATE_KYC_DOCUMENT_SUCCESS,
                    payload: response.data.data,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                return Promise.resolve();
            },
            (error) => {
                // const error_code =
                //   (error.response &&
                //     error.response.data &&
                //     (error.response.data.error_code || error.response.data.message)) ||
                //   error.message ||
                //   error.toString();

                dispatch({
                    type: UPDATE_KYC_DOCUMENT_FAIL,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                // dispatch(setMessage("verifyEmail", error_code));

                return Promise.reject();
            }
        );
    };

export const newTicket = (data) => (dispatch) => {
    dispatch({
        type: CREATE_TICKET,
        payload: data,
    });
};

export const uploadAvatar = (image) => (dispatch) => {
    return UserService.uploadAvatar(image).then(
        (response) => {
            dispatch({
                type: UPLOAD_AVATAR_SUCCESS,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: UPLOAD_AVATAR_FAIL,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            // dispatch(setMessage("verifyEmail", error_code));

            return Promise.reject();
        }
    );
};

export const updateAva = (ava) => (dispatch) => {
    dispatch({
        type: UPDATE_AVA,
        payload: ava,
    });
};

export const addTokenDevice = (device_token) => (dispatch) => {
    return UserService.addToken(device_token).then(
        (response) => {
            dispatch({
                type: ADD_TOKEN,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            // dispatch(setMessage("verifyEmail", error_code));

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const removeTokenDevice = (device_token) => (dispatch) => {
    return UserService.removeToken(device_token).then(
        (response) => {
            dispatch({
                type: REMOVE_TOKEN,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            // dispatch(setMessage("verifyEmail", error_code));

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const changeNotify = (enable) => (dispatch) => {
    return UserService.pushNotify(enable).then(
        (response) => {
            dispatch({
                type: PUSH_NOTiFY,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            // dispatch(setMessage("verifyEmail", error_code));

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getNotifyWallet = (from_date, to_date) => (dispatch) => {
    return UserService.getNotify(from_date, to_date, "wallet").then(
        (response) => {
            dispatch({
                type: GET_NOTIFY_WALLET,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            // dispatch(setMessage("verifyEmail", error_code));

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getNotifyOther = (from_date, to_date) => (dispatch) => {
    return UserService.getNotify(from_date, to_date, "other").then(
        (response) => {
            dispatch({
                type: GET_NOTIFY_OTHER,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            // dispatch(setMessage("verifyEmail", error_code));

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const getReferral = (referral_code) => (dispatch) => {
    return UserService.getReferral(referral_code).then(
        (response) => {
            dispatch({
                type: GET_REFERRAL_SUCCESS,
                payload: response.data.data,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: GET_REFERRAL_FAIL,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            // dispatch(setMessage("verifyEmail", error_code));

            return Promise.reject();
        }
    );
};

export const getListReferral = (user_id) => (dispatch) => {
    return UserService.getListReferal(user_id).then(
        (response) => {
            console.log("getListReferral ~ response:", response)
            dispatch({
                type: GET_LIST_REFERRAL_SUCCESS,
                payload: [],
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            // const error_code =
            //   (error.response &&
            //     error.response.data &&
            //     (error.response.data.error_code || error.response.data.message)) ||
            //   error.message ||
            //   error.toString();

            dispatch({
                type: LOADING_FAIL,
            });

            dispatch({
                type: GET_LIST_REFERRAL_FAIL,
            });

            // dispatch(setMessage("verifyEmail", error_code));

            return Promise.reject(error);
        }
    );
};

export const profileUpdate =
    (name, displayname, gender, dob, phone, email, country_id) => (dispatch) => {
        return UserService.profileUpdate(name, displayname, gender, dob, phone, email, country_id).then(
            (response) => {
                dispatch({
                    type: PROFILE_UPDATE_SUCCESS,
                    payload: response.data.data,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                return Promise.resolve();
            },
            (error) => {
                // const error_code =
                //   (error.response &&
                //     error.response.data &&
                //     (error.response.data.error_code || error.response.data.message)) ||
                //   error.message ||
                //   error.toString();

                dispatch({
                    type: PROFILE_UPDATE_FAIL,
                });

                dispatch({
                    type: LOADING_FAIL,
                });

                // dispatch(setMessage("verifyEmail", error_code));

                return Promise.reject();
            }
        );
    };

export const changeDisplayName = (displayname) => (dispatch) => {
    dispatch({
        type: CHANGE_DISPLAY_NAME,
        payload: displayname,
    });
};

export const registerPartner = (partner_type) => (dispatch) => {
    return UserService.registerPartner(partner_type).then(
        (response) => {
            dispatch({
                type: REGISTER_PARTNER_SUCCESS,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            dispatch({
                type: LOADING_FAIL,
            });

            return Promise.resolve();
        },
        (error) => {
            const msg =
                (error.response &&
                    error.response.data &&
                    (error.response.data.errors || error.response.data.message)) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_PARTNER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: msg,
            });

            return Promise.reject();
        }
    );
};