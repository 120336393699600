// import { useTranslation } from "react-i18next";
import { SET_LANGUAGE } from "../actions/types";


const initialState = {
  listLanguage : [
    {
      name: 'ENG',
      value: 'en'
    },
    {
      name: 'VI',
      value: 'vi'
    },
  ],
  language : "en"};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE:
      return {...state, language: payload, };
    default:
      return state;
  }
}