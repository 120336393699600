import { 
    FETCH_LIST_POSTS_SUCCESS,
    FETCH_LIST_POSTS_FAIL,
    FETCH_POST_DETAIL_SUCCESS,
    FETCH_POST_DETAIL_FAIL
  } from "../actions/types";
  
  const initialState = { listPost: null , postDetail: null};
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case FETCH_LIST_POSTS_SUCCESS:
        return {
          ...state,
          listPost: payload,
        };
      case FETCH_LIST_POSTS_FAIL:
        return {
          ...state,
          listPost: null,
        };
      case FETCH_POST_DETAIL_SUCCESS:
        return {
          ...state,
          postDetail: payload,
        };
      case FETCH_POST_DETAIL_FAIL:
        return {
          ...state,
          postDetail: null,
        };
      default:
        return state;
    }
  }
  