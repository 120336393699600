import React, { Fragment } from "react";

import { ClassNames, Icon } from "modules";

import { useForm } from "./useForm";
import { InputWraper } from "./InputWraper";

export const Form = (props) => {
  const structure = props.structure.filter((v) => v.isVisible !== false);
  const {
    handleSubmit: onSubmit,
    isSubmitting,
    getInputProps,
  } = useForm({
    structure,
    onSubmit: props.onSubmit,
    isDebug: props.isDebug,
    onSuccess: props.onSuccess,
  });

  return (
    <div
      className={ClassNames({
        Form: true,
        [props.className]: !!props.className,
      })}
    >
      <form onSubmit={onSubmit} className="form">
        <div className="row">
          {structure.map((structureInputProps, key) => {
            const { label, col, name, renderInput: input } = structureInputProps;

            return (
              <div className={`col-${col || 12}`} key={key}>
                <InputWraper label={label} inputProps={getInputProps(name)} renderInput={input} />
              </div>
            );
          })}
        </div>

        <div className="actions">
          {(() => {
            if (isSubmitting)
              return (
                <div className="loading">
                  <Icon.Loading />
                </div>
              );

            return (
              <Fragment>
                {(() => {
                  if (!props.buttonClose || !props.buttonClose.onClick) return null;

                  return (
                    <button
                      type="button"
                      className="buttonClose"
                      onClick={() => (props.buttonClose && props.buttonClose.onClick ? props.buttonClose.onClick() : null)}
                    >
                      {props.buttonClose.label || "Close"}
                    </button>
                  );
                })()}

                <button type="submit">{props.labelSubmit || "Submit"}</button>
              </Fragment>
            );
          })()}
        </div>
      </form>
    </div>
  );
};
