// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/font/PixelOperator.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/font/PixelOperator-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/font/Manga11.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "html {\n  scroll-behavior: smooth;\n}\n\n@font-face {\n  font-family: \"Pixel Operator\";\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Pixel Operator\";\n  font-style: normal;\n  font-weight: 700;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Mega11\";\n  font-style: normal;\n  font-weight: normal;\n  src: local(\"Manga11\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n}\n\nbody {\n  margin: 0;\n  font-family: \"Pixel Operator\" !important;\n  background: #26003e;\n  background-image: url(/assets/images/dashboard-background.png);\n}\n.p-inputtext {\n  font-family: \"Pixel Operator\" !important;\n}\n\n.p-component {\n  font-family: \"Pixel Operator\" !important;\n}\n\ncode {\n  font-family: \"Pixel Operator\" !important;\n}\n\n::-webkit-scrollbar {\n  width: 0;\n}\n", ""]);
// Exports
module.exports = exports;
