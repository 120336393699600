import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import TranslationVI  from './TranslationVi';
import TranslationEN  from './TranslationEN';


i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources: {
    en: TranslationEN,
    vi: TranslationVI
  },
  lng: "en",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: true
  }
});


export default i18n;