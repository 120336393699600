const authAPI = {
    register: "register",
    verifyEmail: "verify-email",
    login: "login",
    forgotPassword: "forgot-password",
    changeForgotPassword: "change-password-forgot",
    resendCodeRegister: "resend-code-register",
    verifyPartner: "verify-partner",
};

const directoryAPI = {
    getCountries: "country/list",
};

const emailAPI = {
    subscribeEmail: "subscribe",
};

const gameAPI = {
    getDraw: "draw/history",
    getWinners: "winner/history",
    buyTicket: "ticket/add",
    getTotalDraw: "ticket/total-draw/",
    getTicketRemain: "ticket/total",
    buyGame: "game/add",
};

const phaseAPI = {
    getPhase: "phase",
    getTimePhase: "phase/time",
    getGameMaxMin: "game/3",
    getGameEvenOdd: "game/4",
};

const postAPI = {
    getListPost: "posts",
    getPostDetail: "posts/details",
};

const userAPI = {
    getProfileUser: "user/profile",
    logoutUser: "user/logout",
    resetPassword: "user/reset-password",
    getKYC: "user/kyc",
    updateKYCPersonalInfo: "user/kyc-personal-info",
    updateKYCAddressInfo: "user/kyc-address-info",
    uploadKYCDocument: "user/kyc-upload-image",
    uploadAvatar: "user/upload-avatar",
    addToken: "user/device-token",
    removeToken: "user/disable-token",
    pushNotify: "user/push-notification",
    getNotify: "user/notify",
    getReferral: "user/referral",
    getListReferal: "user/getReferral",
    profileUpdate: "user/update-profile",
    registerPartner: "user/register-partner",
};

const walletAPI = {
    getDepositeList: "user/deposite-list",
    getDepositeDetail: "user/deposite-details",
    getWithdrawList: "user/withdraw-list",
    getWithdrawDetail: "user/withdraw-details",
    getPaymentMethod: "user/payments",
    getPrepareWithdraw: "user/prepare-withdraw",
    getTransactionHistory: "user/transaction-history",
    getTransactionDetailHistory: "user/transaction-detail-history",
    getRate: "rate/1",
    postWithdraw: "user/withdraw",
    postWithdrawVerify: "user/verify-withdrawal",
    getOrderHistory: "user/order-history",
    checkIsvalidAddress: "isvalid-address",
    getFee: "fee-type/",
    transfer: "user/transfer",
    exchange: "wallet/exchange-diamond",
    exchangeHistory: "wallet/exchange-diamond-history",
    contractLottery: "contract-lottery",
};

export { authAPI, directoryAPI, emailAPI, gameAPI, phaseAPI, postAPI, userAPI, walletAPI };