import { LIST_COUNTRIES_FAIL, LIST_COUNTRIES_SUCCESS } from "../actions/types";

const initialState = { countries: null };

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload,
      };
    case LIST_COUNTRIES_FAIL:
      return {
        ...state,
        countries: null,
      };
    default:
      return state;
  }
}
