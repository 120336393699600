import {
  FETCH_ORDER_HISTORY_FAIL,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_RATE_SUCCESS,
  FETCH_RATE_FAIL,
  FETCH_PAYMENT_METHODS_FAIL,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_TRANSACTION_HISTORY_FAIL,
  FETCH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_TRANSACTION_DETAIL_HISTORY_FAIL,
  FETCH_TRANSACTION_DETAIL_HISTORY_SUCCESS,
  POST_WITHDRAW_SUCCESS,
  POST_WITHDRAW_FAIL,
  POST_WITHDRAW_VERIFY_SUCCESS,
  POST_WITHDRAW_VERIFY_FAIL,
  LOG_OUT_USER,
  GET_FEE_TRANSFER,
  TRANSFER_SUCCESS,
  TRANSFER_FAIL,
  EXCHANGE_SUCCESS,
  EXCHANGE_FAIL,
  GET_EXCHANGE_HISTORY,
  GET_CONTRACT_LOTTERY_SUCCESS,
  GET_CONTRACT_LOTTERY_FAIL,
} from "../actions/types";

const initialState = {
  orderHistory: null,
  rate: null,
  paymentMethod: null,
  transactionHistory: null,
  transactionDetail: null,
  ispostWithdraw: false,
  isPostWithdrawVerify: false,
  fee: null,
  transfer: null,
  exchange: null,
  exchangeHistory: null,
  contractLottery: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: payload,
      };
    case FETCH_ORDER_HISTORY_FAIL:
      return {
        ...state,
        orderHistory: null,
      };
    case FETCH_RATE_SUCCESS:
      return {
        ...state,
        rate: payload,
      };
    case FETCH_RATE_FAIL:
      return {
        ...state,
        rate: null,
      };
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethod: payload,
      };
    case FETCH_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        paymentMethod: null,
      };
    case FETCH_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistory: payload,
      };
    case FETCH_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        transactionHistory: null,
      };
    case FETCH_TRANSACTION_DETAIL_HISTORY_SUCCESS:
      return {
        ...state,
        transactionDetail: payload,
      };
    case FETCH_TRANSACTION_DETAIL_HISTORY_FAIL:
      return {
        ...state,
        transactionDetail: null,
      };
    case POST_WITHDRAW_SUCCESS:
      return {
        ...state,
        ispostWithdraw: true,
      };
    case POST_WITHDRAW_FAIL:
      return {
        ...state,
        ispostWithdraw: false,
      };

    case POST_WITHDRAW_VERIFY_SUCCESS:
      return {
        ...state,
        isPostWithdrawVerify: true,
      };

    case POST_WITHDRAW_VERIFY_FAIL:
      return {
        ...state,
        isPostWithdrawVerify: false,
      };
    case LOG_OUT_USER:
      return {
        ...state,
        orderHistory: null,
        rate: null,
        paymentMethod: null,
        transactionHistory: null,
        transactionDetail: null,
        ispostWithdraw: false,
        isPostWithdrawVerify: false,
        transfer: null,
        exchange: null,
        exchangeHistory: null,
      };

    case GET_FEE_TRANSFER:
      return {
        ...state,
        fee: payload,
      };
    case TRANSFER_SUCCESS:
      return {
        ...state,
        transfer: payload,
      };
    case TRANSFER_FAIL:
      return {
        ...state,
        transfer: null,
      };
    case EXCHANGE_SUCCESS:
      return {
        ...state,
        exchange: payload,
      };
    case EXCHANGE_FAIL:
      return {
        ...state,
        exchange: null,
      };
    case GET_EXCHANGE_HISTORY:
      return {
        ...state,
        exchangeHistory: payload,
      };
    case GET_CONTRACT_LOTTERY_SUCCESS:
      return {
        ...state,
        contractLottery: payload,
      };
    case GET_CONTRACT_LOTTERY_FAIL:
      return {
        ...state,
        contractLottery: null,
      };
    default:
      return state;
  }
}
