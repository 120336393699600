import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
// import { Swiper, SwiperSlide } from "swiper/swiper-react";
// import { FreeMode } from "swiper/modules";
// import 'swiper/css';
// import 'swiper/css/free-mode';
import { register } from 'swiper/swiper-element-bundle';

export const DashboardHeader = (props) => {
    const history = useHistory();
    const location = useLocation();
    const swiperRef = useRef(null);

    const menuList = [
        { 
            label: "Overview", 
            path: '/overview',
            activePath: '/overview',
            // activePath: ['/overview', '/deposit', '/withdraw', '/exchange'],
        },
        { 
            label: "Order History", 
            path: '/order-history',
            activePath: '/order-history',
        },
        { 
            label: "KYC", 
            path: '/kyc',
            activePath: '/kyc',
        },
        { 
            label: "Setting", 
            path: '/settings',
            activePath: '/settings',
        },
        { 
            label: "Affiliate Member", 
            path: '/affiliate-member',
            activePath: '/affiliate-member',
        }
    ];

    // useEffect(() => {
    //     register();

    //     swiperRef.current.addEventListener('swiperslidechange', (e) => {
    //         console.log(e.detail);
    //     });

    //     const params = {
    //         on: {
    //             slideChange(s) {
    //                 console.log(s);
    //             },
    //         },
    //     };

    //     Object.assign(swiperRef.current, params);

    //     swiperRef.current.initialize();
    // }, []);

    useEffect(() => {
        register();
    }, []);

    return (
        <div className={`dashboard-header ${props?.scrolled ? "animated fadeInDown dashboard-header--scrolled" : ""}`}>
            <div className="dashboard-header__horizontal-navbar">
                <swiper-container
                    // ref={swiperRef}
                    class="dashboard-header__horizontal-navbar__swiper"
                    slides-per-view="auto"
                    free-mode="true"
                >
                    {menuList?.map((element, idx) => (
                        <swiper-slide 
                            key={idx} 
                            class={`dashboard-header__horizontal-navbar__slide ${_.concat([], element?.activePath).includes(location?.pathname) ? "active" : ""}`}
                            // active-path={element?.activePath}
                            onClick={() => {
                                history.push(element?.path);
                            }}
                            
                        >
                            {element?.label}
                            {/* <div 
                                className={`dashboard-header__horizontal-navbar__item ${_.concat([], element?.activePath).includes(location?.pathname) ? "active" : ""}`}
                                onClick={() => {
                                    history.push(element?.path);
                                }}
                            >
                                {element?.label}
                            </div> */}
                        </swiper-slide>
                    ))}
                </swiper-container>
            </div>
        </div>
    );
};
