import {
  GET_WINNERS_SUCCESS,
  GET_WINNERS_FAIL,
  CHOOSE_NUMBER,
  NUMBER_CARD,
  DELETE_CARD,
  CHANGE_QUANTITY,
  POST_ID_CARD,
  BUY_TICKET_SUCCESS,
  BUY_TICKET_FAIL,
  CHANGE_PRICE,
  VALIDATION_MAX_GAME,
  ADD_TO_CART,
  CLEAR_VALIDATION_MAX_GAME,
  CLEAR_CART_SHOP,
  GET_LOCAL_CART,
  LOG_OUT_USER,
  GET_DRAW_SUCCESS,
  GET_DRAW_FAIL,
  GET_TOTAL_DRAW,
  GET_TICKET_REMAIN,
  BUY_GAME_SUCCESS,
  BUY_GAME_FAIL,
} from "../actions/types";

const initialState = {
  winners: null,
  draw: null,
  cards: [],
  ticket: null,
  validationMax: "",
  ticketsCart: [],
  mess: true,
  totalDraw: null,
  ticketRemain: [],
  buyGame: null,
};

export default function (state = initialState, { type, payload, typeGame }) {
  switch (type) {
    case GET_WINNERS_SUCCESS:
      return {
        ...state,
        winners: payload,
      };
    case GET_WINNERS_FAIL:
      return {
        ...state,
        winners: null,
      };
    case NUMBER_CARD:
      if (payload.status) {
        let locationRandom = [];
        let colNumberRandom = [];
        for (let i = 97; i < 103; i++) {
          locationRandom = [
            ...locationRandom,
            String.fromCharCode(i) + Math.floor(Math.random() * 10),
          ];
          colNumberRandom = [...colNumberRandom, String.fromCharCode(i)];
        }
        const randomCard = {
          id: payload.idCard,
          idCurrent: payload.idCard,
          idGood: payload.idGood,
          quantity: 1,
          price: payload.price,
          location: locationRandom,
          colNumber: colNumberRandom,
          typeGame: payload.typeGame,
        };
        return {
          ...state,
          cards: [...state.cards, randomCard],
        };
      } else {
        const newCard = {
          id: payload.idCard,
          idCurrent: 0,
          idGood: payload.idGood,
          quantity: 1,
          price: payload.price,
          location: [],
          colNumber: [],
          typeGame: payload.typeGame,
        };
        return {
          ...state,
          cards: [...state.cards, newCard],
        };
      }

    case POST_ID_CARD:
      const newID = state.cards.map((item) => {
        item.idCurrent = payload;
        return item;
      });
      return {
        ...state,
        cards: newID,
      };

    case CHOOSE_NUMBER:
      const arrayType = state.cards.map((item) => {
        return item.typeGame;
      });

      if (!arrayType.includes(payload.typeGame)) {
        const newCardNumber = {
          id: payload.idCard,
          idCurrent: payload.idCard,
          idGood: payload.idGood,
          quantity: 1,
          price: payload.price,
          location: [payload.locate],
          colNumber: [payload.colNumber],
          typeGame: payload.typeGame,
        };
        return {
          ...state,
          cards: [...state.cards, newCardNumber],
        };
      }

      const arrayLocation = state.cards.map((item) => {
        if (item.id === item.idCurrent) {
          const changeColNumber = item?.location?.findIndex((itemCol) => {
            return itemCol.slice(0, 1) === payload.locate.slice(0, 1);
          });
          if (changeColNumber !== -1) {
            const a = item?.location;
            a.splice(changeColNumber, 1);
            item.location.push(payload.locate);
          } else {
            item.location.push(payload.locate);
          }
          item.colNumber.push(payload.colNumber);
        }
        return item;
      });
      return {
        ...state,
        cards: arrayLocation,
      };

    case CHANGE_QUANTITY:
      if (!payload.modal) {
        const idx = state.ticketsCart.findIndex((item) => {
          return item.idGood === payload.id;
        });
        if (idx !== -1) {
          if (payload.bol) {
            const _newTicketCart = [...state.ticketsCart];
            _newTicketCart[idx].quantity += 1;
            return {
              ...state,
              ticketsCart: _newTicketCart,
            };
          } else {
            if (state.ticketsCart[idx].quantity > 1) {
              const _newTicketCart = [...state.ticketsCart];
              _newTicketCart[idx].quantity -= 1;
              return {
                ...state,
                ticketsCart: _newTicketCart,
              };
            }
          }
        }
      } else {
        const index = state.cards.findIndex((item) => {
          return item.id === payload.id;
        });
        if (index !== -1) {
          if (payload.bol) {
            const newTicket = [...state.cards];
            newTicket[index].quantity += 1;
            return {
              ...state,
              cards: newTicket,
            };
          } else {
            if (state.cards[index].quantity > 1) {
              const newTicket = [...state.cards];
              newTicket[index].quantity -= 1;
              return {
                ...state,
                cards: newTicket,
              };
            }
          }
        }
      }
      return {
        ...state,
      };

    case DELETE_CARD:
      const newIdCards = state.cards.filter((item) => {
        return item.id !== payload;
      });
      const _newTicketsCart = [...state.ticketsCart].filter((item) => {
        return item.idGood !== payload;
      });
      localStorage.setItem("ticketsCart", JSON.stringify(_newTicketsCart));
      return {
        ...state,
        cards: newIdCards,
        ticketsCart: _newTicketsCart,
      };

    case CHANGE_PRICE:
      const newPrice = state.cards.map((item) => {
        if (item.id === payload.id) item.price = payload.price;
        return item;
      });

      const _newTicketCart = [...state.ticketsCart].map((item) => {
        if (item.id === payload.id && item.typeGame === typeGame) item.price = payload.price;
        return item;
      });
      return {
        ...state,
        cards: newPrice,
        ticketsCart: _newTicketCart,
      };

    case BUY_TICKET_SUCCESS:
      return {
        ...state,
        ticket: payload,
        cards: [],
      };

    case BUY_TICKET_FAIL:
      return {
        ...state,
        ticket: null,
      };

    case VALIDATION_MAX_GAME:
      const maxGameArray = state.cards.filter((item) => {
        return item.typeGame === "Max";
      });

      const numberArrayMaxGame = maxGameArray.map((item) => {
        return item.location
          .sort()
          .map((locate) => {
            return locate.slice(1);
          })
          .join("");
      });

      const colMaxGame = maxGameArray.map((item) => {
        return item.location
          .sort()
          .map((locate) => {
            return locate.slice(0, 1);
          })
          .join("");
      });
      const duplicate = numberArrayMaxGame.filter(
        (item, index) => numberArrayMaxGame.indexOf(item) !== index
      );

      const colValidation = colMaxGame.filter((item) => {
        return (
          item === "abcdef" ||
          item === "bcdef" ||
          item === "cdef" ||
          item === "def" ||
          item === "ef" ||
          item === "f"
        );
      });
      if (colValidation.length !== colMaxGame.length)
        return {
          ...state,
          validationMax: "Please choose numbers from right to left consecutively",
        };
      else if (duplicate.length > 0)
        return {
          ...state,
          validationMax: "Please do not buy two identical tickets",
        };
      else
        return {
          ...state,
          validationMax: "",
        };

    case CLEAR_VALIDATION_MAX_GAME:
      return {
        ...state,
        validationMax: "",
      };

    case ADD_TO_CART:
      const newTicketsCart = [...state.ticketsCart, payload].flat();

      const duplicateArray = newTicketsCart
        .filter((item) => item.period !== 1)
        .map((item) => {
          return item.period;
        });

      const duplicatePeriod = duplicateArray.filter(
        (item, index) => duplicateArray.indexOf(item) !== index
      );

      if (duplicatePeriod.length > 0) {
        return {
          ...state,
          mess: false,
        };
      } else {
        localStorage.setItem("ticketsCart", JSON.stringify(newTicketsCart));
        return {
          ...state,
          ticketsCart: newTicketsCart,
          mess: true,
          cards: [],
        };
      }
    case CLEAR_CART_SHOP:
      return {
        ...state,
        cards: [],
        ticketsCart: [],
      };
    case GET_LOCAL_CART:
      const localCart = JSON.parse(localStorage.getItem("ticketsCart"))
        ? JSON.parse(localStorage.getItem("ticketsCart"))
        : [];
      return {
        ...state,
        ticketsCart: localCart,
      };

    case GET_DRAW_SUCCESS:
      return {
        ...state,
        draw: payload,
      };
    case GET_DRAW_FAIL:
      return {
        ...state,
        draw: null,
      };

    case LOG_OUT_USER:
      return {
        ...state,
        cards: [],
        ticket: null,
        validationMax: "",
        ticketsCart: [],
        mess: true,
        totalDraw: null,
        ticketRemain: [],
        buyGame: null,
      };

    case GET_TOTAL_DRAW:
      return {
        ...state,
        totalDraw: payload,
      };

    case GET_TICKET_REMAIN:
      return {
        ...state,
        ticketRemain: payload,
      };

    case BUY_GAME_SUCCESS:
      return {
        ...state,
        buyGame: payload,
      };
    case BUY_GAME_FAIL:
      return {
        ...state,
        buyGame: null,
      };

    default:
      return state;
  }
}
