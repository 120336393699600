import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { Button, Icon } from "modules";
import { Sidebar } from "primereact/sidebar";
import { useTranslation } from "react-i18next";
import { NavLink } from "component/navlink";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Config } from "configs";
import NoUser from "assets/images/NoUser.png";
import { changeDisplayName, logoutUser, removeTokenDevice } from "actions/user";

function MenuMobile(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user, ava } = useSelector((state) => state.user);
  const dsName = useSelector((state) => state.user.displayname);
  const token = localStorage.getItem("deviceToken");

  const [name, setName] = useState();

  useEffect(() => {
    dsName !== null && dsName !== "" ? setName(dsName) : user?.displayname ? setName(user?.displayname) : setName(user?.name);
  }, [dsName, user]);

  const listMenu = [
    {
      icon: <Icon.Cherry />,
      label: t("MMLotto"),
      path: "/",
    },
    {
      icon: <Icon.Lucky />,
      label: t("Get Lucky Number"),
      path: "/get-lucky-number",
    },
    {
      icon: <Icon.History />,
      label: t("History Jackpot"),
      path: "/history-jackpot",
    },
    {
      icon: <Icon.Dashboard />,
      label: t("Dashboard"),
      path: "/overview",
    },
  ];

  return (
    <Sidebar visible={props.open} position="left" onHide={props.closeModal} fullScreen blockScroll showCloseIcon={false}>
      <div className="mb-6" onClick={props.closeModal}>
        <Icon.ArrowLeft />
      </div>
      {isLoggedIn ? (
        <>
          <div
            className="profile"
            onClick={() => {
              history.push("/settings");
              props.closeModal();
            }}
          >
            <div className="flex-1 flex items-center gap-2">
              <Avatar
                image={ava ? ava : user?.avatar !== `${Config.API_SERVER}/avatar/no-avatar.png` ? user?.avatar : NoUser}
                size="normal"
                shape="circle"
                onImageError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoUser;
                }}
              />
              <span className="name truncate">{name}</span>
            </div>
            <span>
              <Icon.HollowArrow />
            </span>
          </div>
          <div className="wallet">
            <div className="flex items-center justify-between wallet-top">
              <div className="flex-1 flex flex-col items-center justify-center ">
                <img src="/assets/images/coins/usdt.png" alt="usdt" />
                <span>
                  {Number(user?.wallets[0].balance).toFixed(2)} <span>USDT</span>
                </span>
              </div>
              <div className="flex-1 flex flex-col items-center justify-center">
                <img src="/assets/images/coins/mut.png" alt="mut" />
                <span>
                  {Number(user?.wallets[0] ? user?.wallets[0].diamond : 0).toFixed(0)} <span>MUT</span>
                </span>
              </div>
            </div>
            {/* <div className="flex items-center justify-between">
              <NavLink className="flex-1 text-center" path="/deposit" onClick={props.closeModal}>
                {t("Deposit")}
              </NavLink>
              <div className="flex-1"></div>
            </div> */}
          </div>
        </>
      ) : (
        <Button label={t("Login")} buttonType="secondary" onClick={props.openModalLogin} />
      )}
      <div className="menu-global-mobile flex flex-col justify-between">
        {listMenu
          .filter((item) => (isLoggedIn ? item : item.path !== "/overview"))
          .map((item, idx) => (
            <NavLink
              key={idx}
              className={`flex items-center gap-3 menu-item ${
                location.pathname === item.path ||
                (item.path === "/overview" &&
                  ["/overview", "/order-history", "/settings", "/affiliate-member", "/kyc", "/deposit", "/withdraw", "/exchange"].includes(
                    location.pathname
                  ))
                  ? "active"
                  : ""
              }`}
              path={item.path}
              onClick={props.closeModal}
            >
              {item.icon}
              <span>{item.label}</span>
            </NavLink>
          ))}
        <div
          className="logout menu-item"
          onClick={() => {
            dispatch(removeTokenDevice(token));
            localStorage.removeItem("deviceToken");
            dispatch(logoutUser());
            dispatch(changeDisplayName(null));
            props.closeModal();
          }}
        >
          <span>{t("Logout")}</span>
        </div>
      </div>
    </Sidebar>
  );
}

MenuMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModalLogin: PropTypes.func.isRequired,
};

export default MenuMobile;
