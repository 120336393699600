import { authAPI } from "configs/const";
import customAxios from "../configs/axios";

// const CUS_API = process.env.REACT_APP_CUS_API_ENDPOINT;

const register = (name, displayname, email, password, country_id, referral_by) => {
  return customAxios.post(authAPI.register, {
    name,
    displayname,
    email,
    password,
    country_id,
    referral_by,
  });
};

const verifyEmail = (email, code) => {
  return customAxios.post(authAPI.verifyEmail, {
    email,
    code,
  });
};

const login = (email, password) => {
  return customAxios
    .post(authAPI.login, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data.data;
    });
};

const forgotPassword = (email) => {
  return customAxios.post(authAPI.forgotPassword, { email });
};

const changeForgotPassword = (code, email, password) => {
  return customAxios.post(authAPI.changeForgotPassword, { code, email, password });
};

const resendCodeRegister = (email) => {
  return customAxios.post(authAPI.resendCodeRegister, { email });
};

const verifyPartner = (code, email) => {
  return customAxios.post(authAPI.verifyPartner, { code, email });
};

export default {
  register,
  verifyEmail,
  login,
  forgotPassword,
  changeForgotPassword,
  resendCodeRegister,
  verifyPartner,
};
