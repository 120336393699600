import React, { useState, useEffect, useRef } from "react";
import { useForm, InputWraper, Button } from "modules";
import { dashboardLayout, InputText, InputNumber, InputSelect, InputSelectAsyncData, InputDateTime } from "component";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Config } from "configs";
import NoUser from "assets/images/NoUser.png";
import { updateAva, uploadAvatar } from "actions/user";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getCountries } from "actions/directory";
import { changeDisplayName, profileUpdate } from "actions/user";
import moment from "moment";
import FormUserDocument from "component/formUserDocument";
import { updateKYCAddressInfo, updateKYCPersonalInfo, uploadKYCDocument } from "actions/user";
import { getKYC } from "actions/user";
import { Icon } from "modules";

const KycPage = dashboardLayout(() => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const genderOption = [
        { label: t("Male"), value: "M" },
        { label: t("Female"), value: "F" },
    ];

    const { user, ava } = useSelector((state) => state.user);
    const { countries } = useSelector((state) => state.directory);
    const kyc = useSelector((state) => state.user.kyc);

    const [imgSelfie, setImgSelfie] = useState(null);
    const [indentifyCardFront, setIndentifyCardFront] = useState(null);
    const [indentifyCardBack, setIndentifyCardBack] = useState(null);
    const [passportFront, setPassportFront] = useState(null);

    const [remove, setRemove] = useState(false);
    const [removeFront, setRemoveFront] = useState(false);
    const [removeBack, setRemoveBack] = useState(false);
    const [removePass, setRemovePass] = useState(false);

    const [activeIdx, setActiveIdx] = useState(0);

    const { getInputProps, handleSubmit, isSubmitting, isValid } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "first_name",
                validate: Yup.string()
                    .required("First Name is required")
                    .typeError("First Name is required"),
                defaultValue: kyc?.first_name,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "last_name",
                validate: Yup.string()
                    .required("Last Name is required")
                    .typeError("Last Name is required"),
                defaultValue: kyc?.last_name,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "middle_name",
                defaultValue: kyc?.middle_name,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "gender",
                validate: Yup.string()
                    .required("Gender is required")
                    .typeError("Gender is required"),
                defaultValue: kyc?.gender,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "phone_number",
                validate: Yup.string()
                    .required("Phone Number is required")
                    .typeError("Phone Number is required")
                    .matches(/^\d+$/, "Phone Number must be only digits"),
                defaultValue: kyc?.phone_number,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "dob",
                validate: Yup.string()
                    .required("Date of Birth is required")
                    .typeError("Date of Birth is required"),
                defaultValue: (kyc?.dob != null) ? moment(kyc?.dob).utcOffset(0, false) : '',
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "identity_card",
                validate: Yup.string()
                    .required("ID Card is required")
                    .typeError("ID Card is required"),
                defaultValue: kyc?.identity_card,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "issue_date",
                validate: Yup.string()
                    .required("Date of Issue is required")
                    .typeError("Date of Issue is required"),
                defaultValue: (kyc?.issue_date != null) ? moment(kyc?.issue_date).utcOffset(0, false) : '',
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "country_id",
                validate: Yup.string()
                    .required("Country is required")
                    .typeError("Country is required"),
                defaultValue: kyc?.country_id,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "state_province",
                defaultValue: kyc?.state_province,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "city",
                validate: Yup.string()
                    .required("City is required")
                    .typeError("City is required"),
                defaultValue: kyc?.city,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "postal_code",
                defaultValue: kyc?.postal_code,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "address1",
                validate: Yup.string()
                    .required("Address 1 is required")
                    .typeError("Address 1 is required"),
                defaultValue: kyc?.address1,
                isDisabled: user?.kyc_status !== "unverified"
            },
            {
                name: "address2",
                defaultValue: kyc?.address2,
                isDisabled: user?.kyc_status !== "unverified"
            },
        ],
        onSubmit: async (values) => {

            setRemove(false);
            setRemoveFront(false);
            setRemovePass(false);
            setRemoveBack(false);
            
            let metadata = {
                type: "image/jpeg",
            };

            let responseAva = await fetch(kyc?.selfie);
            let dataSelfie = await responseAva.blob();
            let selfie = imgSelfie !== null ? imgSelfie : new File([dataSelfie], "test.jpg", metadata);

            let responseFront = await fetch(kyc?.front_identity_card);
            let dataFront = await responseFront.blob();
            let front = indentifyCardFront;
            if (kyc?.front_identity_card && indentifyCardFront === null)
            front = new File([dataFront], "test.jpg", metadata);

            let responseBack = await fetch(kyc?.back_identity_card);
            let dataBack = await responseBack.blob();
            let back = indentifyCardBack;
            if (kyc?.back_identity_card && indentifyCardBack === null)
            back = new File([dataBack], "test.jpg", metadata);

            let responsePass = await fetch(kyc?.front_passport);
            let dataPass = await responsePass.blob();
            let pass = passportFront;
            if (kyc?.front_passport && passportFront === null)
            pass = new File([dataPass], "test.jpg", metadata);

            if (((front == null) || (back == null)) && (pass == null)) {
                toast.error(`Please Upload ${(activeIdx === 0) ? 'Front and Back Identity Card' : 'Passport'}`);
                return;
            }

            let commonPromiseArr = [
                new Promise((resolve, reject) => {
                    dispatch(updateKYCPersonalInfo(
                        values?.first_name, 
                        values?.middle_name, 
                        values?.last_name, 
                        moment(values?.dob).utcOffset(0, true).format('L'),
                        values?.gender, 
                        values?.identity_card, 
                        moment(values?.issue_date).utcOffset(0, true).format('L'),
                        values?.phone_number
                    )).then(response => resolve(response)).catch(error => reject(error));
                }),
                new Promise((resolve, reject) => {
                    dispatch(updateKYCAddressInfo(
                        values?.country_id, 
                        values?.state_province, 
                        values?.city, 
                        values?.postal_code, 
                        values?.address1, 
                        values?.address2
                    )).then(response => resolve(response)).catch(error => reject(error));
                }),
            ];
            return Promise.all((remove || removeFront || removeBack || removePass) ? [
                ...commonPromiseArr,
                dispatch(
                    uploadKYCDocument(
                        selfie, 
                        front, 
                        back, 
                        pass
                    )
                )
            ] : [
                ...commonPromiseArr
            ]).then( async ([personalInfoResponse, addressInfoResponse, documentResponse]) => {
                toast.success("KYC Updated Successfully");
            }).catch((err) => {
                toast.error("Failed To Update KYC");
            });
        },
    });

    const handleSelfie = (e, status) => {
        if (status) {
            setImgSelfie(e.target?.files[0]);
            setRemove(true);
        } else {
            setImgSelfie(null);
            setRemove(false);
        }
    };

    const handleFront = (e, status) => {
        if (status) {
            setIndentifyCardFront(e.target?.files[0]);
            setRemoveFront(true);
        } else {
            setIndentifyCardFront(null);
            setRemoveFront(false);
        }
    };

    const handleBack = (e, status) => {
        if (status) {
            setIndentifyCardBack(e.target?.files[0]);
            setRemoveBack(true);
        } else {
            setIndentifyCardBack(null);
            setRemoveBack(false);
        }
    };

    const handlePaspost = (e, status) => {
        if (status) {
            setPassportFront(e.target?.files[0]);
            setRemovePass(true);
        } else {
            setPassportFront(null);
            setRemovePass(false);
        }
    };

    useEffect(() => {
        dispatch(getKYC());
        dispatch(getCountries());
    }, []);

    return (
        <div className="kyc-page">
            <div className="kyc-page__title">Let’s start with getting to know your profession</div>
            <div className="kyc">
                <div className="kyc__choose">
                    <div className="kyc__choose__title">CHOOSE YOUR DOCUMENT</div>
                    <div className="kyc__choose__input">
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-4 sm:gap-x-8">
                            <div className={`kyc__choose__input__item ${(activeIdx === 0) ? 'active' : ''}`} onClick={() => setActiveIdx(0)}>
                                <div className="kyc__choose__input__item__icon">
                                    <Icon.IdentifyCardIcon />
                                </div>
                                <div className="kyc__choose__input__item__text">Identify Card</div>
                            </div>
                            <div className={`kyc__choose__input__item ${(activeIdx === 1) ? 'active' : ''}`} onClick={() => setActiveIdx(1)}>
                                <div className="kyc__choose__input__item__icon">
                                    <Icon.PassportIcon />
                                </div>
                                <div className="kyc__choose__input__item__text">Passport</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kyc__form">
                    <div className="kyc__form__personal-info">
                        <div className="kyc__form__personal-info__title">PERSONAL INFORMATION</div>
                        <div className="kyc__form__personal-info__input">
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-8">
                                <InputWraper
                                    label={'FIRST NAME*'}
                                    placeholder={'Enter your First Name'}
                                    inputProps={getInputProps('first_name')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'MIDDLE NAME'}
                                    placeholder={'Enter your Middle Name'}
                                    inputProps={getInputProps('middle_name')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'LAST NAME*'}
                                    placeholder={'Enter your Last Name'}
                                    inputProps={getInputProps('last_name')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'GENDER*'}
                                    placeholder={'Choose your Gender'}
                                    inputProps={getInputProps('gender')}
                                    renderInput={(props) => <InputSelect {...props} options={genderOption} />}
                                />
                                <InputWraper
                                    label={'PHONE NUMBER*'}
                                    placeholder={'Enter your Phone Number'}
                                    inputProps={getInputProps('phone_number')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'DATE OF BIRTH*'}
                                    placeholder={'Enter your Date of Birth'}
                                    inputProps={getInputProps('dob')}
                                    renderInput={(props) => <InputDateTime {...props} format={'MM/dd/yyyy'}  />}
                                />
                                <InputWraper
                                    label={'ID CARD*'}
                                    placeholder={'Enter your ID Card'}
                                    inputProps={getInputProps('identity_card')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'DATE OF ISSUE*'}
                                    placeholder={'Enter your Date of Issue'}
                                    inputProps={getInputProps('issue_date')}
                                    renderInput={(props) => <InputDateTime {...props} format={'MM/dd/yyyy'}  />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="kyc__form__address-info">
                        <div className="kyc__form__address-info__title">ADDRESS</div>
                        <div className="kyc__form__address-info__input">
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-8">
                                <InputWraper
                                    label={'COUNTRY*'}
                                    placeholder={'Choose your Country'}
                                    inputProps={getInputProps('country_id')}
                                    renderInput={(state) => (
                                        <InputSelectAsyncData
                                            {...state}
                                            isClearable={false}
                                            asyncData={{
                                                response: {data: countries},
                                                pathLabel: "name",
                                                pathValue: "id",
                                            }}
                                        />
                                    )}
                                />
                                <InputWraper
                                    label={'STATE (OPTIONAL)'}
                                    placeholder={'Enter your State'}
                                    inputProps={getInputProps('state_province')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'CITY*'}
                                    placeholder={'Choose your City'}
                                    inputProps={getInputProps('city')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'POSTCODE (IP CODE)'}
                                    placeholder={'Enter your Postcode'}
                                    inputProps={getInputProps('postal_code')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'ADDRESS 1*'}
                                    placeholder={'Enter your Address 1'}
                                    inputProps={getInputProps('address1')}
                                    component={InputText}
                                />
                                <InputWraper
                                    label={'ADDRESS 2 (OPTIONAL)'}
                                    placeholder={'Enter your Address 2'}
                                    inputProps={getInputProps('address2')}
                                    component={InputText}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="kyc__form__upload">
                    <FormUserDocument
                        kyc={kyc}
                        imgSelfie={imgSelfie}
                        remove={remove}
                        indentifyCardFront={indentifyCardFront}
                        removeFront={removeFront}
                        removePass={removePass}
                        indentifyCardBack={indentifyCardBack}
                        removeBack={removeBack}
                        passportFront={passportFront}
                        handleSelfie={handleSelfie}
                        handleFront={handleFront}
                        handleBack={handleBack}
                        verify={user?.kyc_status !== "unverified"}
                        handlePaspost={handlePaspost}
                        activeIdx={activeIdx}
                    />
                    </div>
                    {(user?.kyc_status != null && user?.kyc_status === "unverified") && (
                        <div className="kyc__form__main-action">
                            <Button
                                className="kyc__form__main-action__save"
                                label={'Submit'}
                                buttonType="secondary"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Toaster />
        </div>
    );
});

export default KycPage;