import { postAPI } from "configs/const";
import customAxios from "../configs/axios";

const getListPost = (page, pageSize) => {
  return customAxios.post(postAPI.getListPost, {
    page,
    pageSize,
  });
};

const getPostDetail = (post_id) => {
  return customAxios.post(postAPI.getPostDetail, {
    post_id,
  });
};

export default { getListPost, getPostDetail };
