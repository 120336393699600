export const message = (name, value) => {
  if (name === "register")
    switch (value) {
      case 10:
        return "An unknown error";
      case 20:
        return "The email has already been taken";
      default:
        return;
    }

  if (name === "login")
    switch (value) {
      case 1:
        return "Email not found";
      case 2:
        return "Unverified email";
      case 3:
        return "User has been deleted";
      case 4:
        return "User is disabled active";
      case 5:
        return "Login too many times so locked 60 minutes";
      case 6:
        return "Password is wrong";
      case 10:
        return "An unknown error";
      case 20:
        return "Validation error";
      default:
        return;
    }

  if (name === "verifyEmail")
    switch (value) {
      case 1:
        return "Verify code has expired. Code is valid for 5 minutes";
      case 2:
        return "The code is not correct";
      case 3:
        return "Email verified";
      case 10:
        return "An unknown error";
      case 20:
        return "Validation error";
      default:
        return;
    }

  if (name === "forgotPassword")
    switch (value) {
      case 1:
        return "Email not found";
      case 20:
        return "Validation error";
      default:
        return;
    }

  if (name === "changeForgotPassword")
    switch (value) {
      case 1:
        return "Verify code has expired. Code is valid for 5 minutes";
      case 2:
        return "The code is not correct";
      case 10:
        return "An unknown error";
      case 20:
        return "Validation error";
      default:
        return;
    }

  if (name === "withdraw")
    switch (value) {
      case 1:
        return "Insufficient funds.";
      case 20:
        return "Validation error";
      default:
        return;
    }

  if (name === "buyTicket")
    switch (value) {
      case 1:
        return "Insufficient funds";
      case 2:
        return "Phase or lottery not found";
      case 20:
        return "Validation error";
      default:
        return;
    }
};
