import React, { useState, useEffect } from "react";
import Clipboard from "react-clipboard.js";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Icon, Table } from "modules";
import WalletService from "services/wallet.Service";
import moment from "moment";
import { dashboardLayout } from "component";
import { TableFilterInputSelect } from "component/table-filter-inputs/select";
import { TableFilterInputText } from "component/table-filter-inputs/text";
import { TableFilterRangeTimeInput } from "component/table-filter-inputs/range-time";
import ModalHistory from "component/modalHistory";
import { useTranslation } from "react-i18next";
import { Config } from "configs";

const OverviewPage = dashboardLayout((props) => {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const { t } = useTranslation();

  const [dialog, setDialog] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState({});

  const closeModal = () => {
    setDialog(false);
  };

  let handleOnClickViewDetail = (item) => {
    WalletService.getTransactionDetailHistory(item.id)
      .then((res) => {
        setTransactionDetail(res?.data?.data);
        setDialog(true);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  let filters = [
    {
      name: "Type",
      key: "type",
      input: (s) => (
        <TableFilterInputSelect
          {...s}
          options={[
            { label: t("Buy MUT"), value: "buymut" },
            { label: t("Buy Ticket"), value: "buyticket" },
            { label: t("Deposit"), value: "deposit" },
            { label: t("Draw Game"), value: "drawgame" },
            { label: "Game Min Max", value: "buyGameMinMax" },
            { label: "Game Even Odd", value: "buyGameEvenOdd" },
            { label: "Partner", value: "partner" },
            { label: t("Receive"), value: "receive" },
            { label: t("Transfer"), value: "transfer" },
            { label: t("Ticket Commission"), value: "ticketcommission" },
            { label: t("VIP5 Commission"), value: "vip5commissiom" },
            { label: t("Withdraw"), value: "withdraw" },
            { label: t("Winner"), value: "winner" },
            { label: t("Winning Commission"), value: "winningcommission" },
          ]}
        />
      ),
    },
    {
      name: "Status",
      key: "status",
      input: (s) => (
        <TableFilterInputSelect
          {...s}
          options={[
            { label: t("Pending"), value: "Pending" },
            { label: t("Success"), value: "Success" },
            { label: t("Rejected"), value: "Rejected" },
          ]}
        />
      ),
    },
    {
      name: "Wallet Address",
      key: "address",
      input: (s) => <TableFilterInputText {...s} />,
    },
    {
      name: "Created Date",
      key: "created",
      input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
      defaultValue: {
        fromDate: moment().subtract(1, "month"),
        toDate: moment(),
      },
    },
  ];

  let structure = [
    {
      name: "TYPE",
      key: "type",
      render: (item) => item?.type,
    },
    {
      name: "AMOUNT",
      render: (item) => (
        <div className="amount">
          <div className="amount__icon">
            <img src={`/assets/images/coins/${Number(item.tran_value) !== 0 ? "usdt" : "mut"}.png`} alt="usdt" />
          </div>
          <div className="amount__text">
            {Number(item.tran_value) !== 0 ? Number(item.tran_value).toFixed(2) : Number(item.tran_diamond_value).toFixed(2)}
          </div>
        </div>
      ),
    },
    {
      name: "STATUS",
      key: "status",
      render: (item) => item?.status,
    },
    {
      name: "CREATED DATE",
      key: "created_at",
      render: (item) => moment.utc(item.created_at).local().format("L HH:mm:ss"),
    },
    {
      name: "ACTION",
      render: (item) => (
        <div className="action" onClick={() => handleOnClickViewDetail(item)}>
          <Icon.EyeViewDetailIcon />
        </div>
      ),
    },
  ];

  return (
    <div className="overview-page">
      <div className="overview__wallet">
        <div className="overview__wallet__info">
          <div className="overview__wallet__info__list">
            <div className="overview__wallet__info__list__item">
              <div className="overview__wallet__info__list__item__title">USDT</div>
              <div className="overview__wallet__info__list__item__value">
                <div className="overview__wallet__info__list__item__value__icon">
                  <img src="/assets/images/coins/usdt.png" alt="usdt" />
                </div>
                <div className="overview__wallet__info__list__item__value__text">
                  {user?.wallets[0].balance != null ? Number(user?.wallets[0].balance).toFixed(2) : "N/A"}
                </div>
              </div>
            </div>
            <div className="overview__wallet__info__list__item">
              <div className="overview__wallet__info__list__item__title">MUT</div>
              <div className="overview__wallet__info__list__item__value">
                <div className="overview__wallet__info__list__item__value__icon">
                  <img src="/assets/images/coins/mut.png" alt="mut" />
                </div>
                <div className="overview__wallet__info__list__item__value__text">
                  {user?.wallets[0] != null ? Number(user?.wallets[0] ? user?.wallets[0].diamond : 0).toFixed(0) : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overview__wallet__action">
          <div className="overview__wallet__action__deposit">
            <Button
              className="overview__wallet__action__deposit-btn"
              label={"Deposit"}
              buttonType="primary"
              onClick={() => history.push("/deposit")}
            />
          </div>
          <div className="overview__wallet__action__exchange">
            <Button
              className="overview__wallet__action__exchange-btn"
              label={"Buy MUT"}
              buttonType="secondary"
              onClick={() => history.push("/exchange")}
            />
          </div>
          {/* <Button
                        className="overview__wallet__action__withdraw-btn"
                        label={'Withdraw'}
                        buttonType="secondary"
                        onClick={() => null}
                    /> */}
        </div>
      </div>
      <div className="overview__aff">
        <div className="overview__aff__cover">
          <img src="/assets/images/aff-cover-image.png" alt="" />
        </div>
        <div className="overview__aff__main">
          <div className="overview__aff__main__main-title">Refer your friend</div>
          <div className="overview__aff__main__sub-title">All for One, One for All.</div>
          <div className="overview__aff__main__link overview__aff__main__long-link">
            <div className="overview__aff__main__link__icon">
              <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.1667 0.333374H10.6667C10.2084 0.333374 9.83342 0.708374 9.83342 1.16671C9.83342 1.62504 10.2084 2.00004 10.6667 2.00004H13.1667C14.5417 2.00004 15.6667 3.12504 15.6667 4.50004C15.6667 5.87504 14.5417 7.00004 13.1667 7.00004H10.6667C10.2084 7.00004 9.83342 7.37504 9.83342 7.83337C9.83342 8.29171 10.2084 8.66671 10.6667 8.66671H13.1667C15.4667 8.66671 17.3334 6.80004 17.3334 4.50004C17.3334 2.20004 15.4667 0.333374 13.1667 0.333374ZM5.66675 4.50004C5.66675 4.95837 6.04175 5.33337 6.50008 5.33337H11.5001C11.9584 5.33337 12.3334 4.95837 12.3334 4.50004C12.3334 4.04171 11.9584 3.66671 11.5001 3.66671H6.50008C6.04175 3.66671 5.66675 4.04171 5.66675 4.50004ZM7.33342 7.00004H4.83341C3.45841 7.00004 2.33341 5.87504 2.33341 4.50004C2.33341 3.12504 3.45841 2.00004 4.83341 2.00004H7.33342C7.79175 2.00004 8.16675 1.62504 8.16675 1.16671C8.16675 0.708374 7.79175 0.333374 7.33342 0.333374H4.83341C2.53341 0.333374 0.666748 2.20004 0.666748 4.50004C0.666748 6.80004 2.53341 8.66671 4.83341 8.66671H7.33342C7.79175 8.66671 8.16675 8.29171 8.16675 7.83337C8.16675 7.37504 7.79175 7.00004 7.33342 7.00004Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="overview__aff__main__link__text">{`${Config.X_THIRD_PARTY_BASE_URL}/signup/${user?.referral_code}`}</div>
            <Clipboard
              data-clipboard-text={`${Config.X_THIRD_PARTY_BASE_URL}/signup/${user?.referral_code}`}
              onClick={() => toast.success("Copy Successfully")}
            >
              <div className="overview__aff__main__link__copy">
                <Icon.CopyIcon />
              </div>
            </Clipboard>
          </div>
          <div className="overview__aff__main__link overview__aff__main__short-link">
            <div className="overview__aff__main__link__icon">
              <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.6"
                  d="M1.88525 8.95901H0L0.278688 7.28687H2.14754L2.39344 5.56556H0.491803L0.754098 3.89343H2.63934L3.09836 0.893433H4.90164L4.45902 3.89343H6.29508L6.73771 0.893433H8.55738L8.09836 3.89343H10L9.7377 5.56556H7.85246L7.60656 7.28687H9.52459L9.26229 8.95901H7.36066L6.88525 12.1065H5.06557L5.54098 8.95901H3.70492L3.22951 12.1065H1.42623L1.88525 8.95901ZM5.81967 7.31966L6.08197 5.53278H4.18033L3.91803 7.31966H5.81967Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="overview__aff__main__link__text">{user?.referral_code}</div>
            <div className="overview__aff__main__link__copy">
              <Clipboard data-clipboard-text={user?.referral_code} onClick={() => toast.success("Copy Successfully")}>
                <Icon.CopyIcon />
              </Clipboard>
            </div>
          </div>
        </div>
      </div>
      <div className="overview__trans">
        <div className="overview__trans__title">HISTORY</div>
        <Table
          hasOrderColumn
          filters={filters}
          structure={structure}
          fetchData={async (state) =>
            WalletService.getTransactionHistory(
              moment(state.fromDate).toISOString(),
              moment(state.toDate).toISOString(),
              state.pageNumber - 1,
              state.limit,
              state.type,
              state.status,
              state.address
            ).then((res) => {
              return {
                data: res?.data?.data?.result,
                count: res?.data?.data?.total,
              };
            })
          }
        />
      </div>
      <ModalHistory dialog={dialog} transactionDetail={transactionDetail} closeModal={closeModal} />
      <Toaster />
    </div>
  );
});

export default OverviewPage;
