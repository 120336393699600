// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/BackgroundHIW1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/BackgroundHIW2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".operate {\n  padding-top: 3.563rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") left no-repeat, url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") right no-repeat;\n}\n.operate .operate-item {\n  display: inline-block;\n  width: 22.375rem;\n}\n.operate .operate-item .backgroud-image {\n  position: absolute;\n  margin: -4rem auto;\n}\n.operate .operate-item .backgroud-image-2 {\n  position: absolute;\n  margin: -9rem auto;\n}\n.operate .operate-item .image {\n  margin: 0 auto;\n  width: 60%;\n}\n.operate .operate-item .image-2 {\n  margin: -2.8rem auto;\n  width: 60%;\n  height: 12.4rem;\n}\n.operate .operate-item h3 {\n  margin-top: 3.25rem;\n  font-weight: bold;\n  font-size: 2.25rem;\n  line-height: 1.688rem;\n  text-transform: capitalize;\n  color: #040302;\n}\n.operate .operate-item p {\n  margin-top: 2.063rem;\n  font-weight: 500;\n  font-size: 1.125rem;\n  line-height: 1.688rem;\n  color: #040302;\n}\n\n@media (max-width: 79.938rem) {\n  .operate {\n    background: none;\n  }\n  .operate .operate-item {\n    transform: scale(0.8) !important;\n  }\n}\n@media (max-width: 22.375rem) {\n  .operate .operate-item {\n    width: 100% !important;\n  }\n}", ""]);
// Exports
module.exports = exports;
