import React, { useEffect } from "react";
import { DashboardHeader } from "component";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

export const dashboardLayout = (Component) => (props) => {
    
    const auth = useSelector((state) => state.auth);

    if (auth.isLoggedIn === false) return <Redirect to="/" />;
    return (<>
        <div className="dashboard-layout">
            <DashboardHeader />
            <Component {...props} />
        </div>
    </>);
};
