import React, { useEffect, useRef } from "react";
import { useForm, InputWraper, Button } from "modules";
import { dashboardLayout, InputText, InputNumber, InputSelect, InputSelectAsyncData, InputDateTime } from "component";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Config } from "configs";
import NoUser from "assets/images/NoUser.png";
import { updateAva, uploadAvatar } from "actions/user";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getCountries } from "actions/directory";
import { changeDisplayName, getProfileUser, profileUpdate } from "actions/user";
import moment from "moment";

const SettingsPage = dashboardLayout(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const genderOption = [
    { label: t("Male"), value: "M" },
    { label: t("Female"), value: "F" },
  ];

  const { user, ava } = useSelector((state) => state.user);
  const { countries } = useSelector((state) => state.directory);

  const avatarInputRef = useRef(null);

  const { getInputProps, handleSubmit, isSubmitting, isValid } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "name",
        validate: Yup.string().required("Name is required").typeError("Name is required"),
        defaultValue: user?.name,
      },
      {
        name: "displayname",
        defaultValue: user?.displayname,
      },
      {
        name: "email",
        validate: Yup.string().required("Email is required").typeError("Email is required"),
        defaultValue: user?.email,
        isDisabled: true,
      },
      {
        name: "phone",
        validate: Yup.string().required("Phone Number is required").typeError("Phone Number is required"),
        defaultValue: user?.phone,
      },
      {
        name: "gender",
        validate: Yup.string().required("Gender is required").typeError("Gender is required"),
        defaultValue: user?.gender,
      },
      {
        name: "country_id",
        validate: Yup.string().required("Country is required").typeError("Country is required"),
        defaultValue: user?.country_id,
      },
      {
        name: "dob",
        validate: Yup.string().required("Date Of Birth is required").typeError("Date Of Birth is required"),
        defaultValue: user?.dob,
      },
    ],
    onSubmit: async (values) => {
      return dispatch(
        profileUpdate(
          values?.name,
          values?.displayname,
          values?.gender,
          moment(values?.dob).format("YYYY-MM-DD"),
          values?.phone,
          user.email,
          values?.country_id
        )
      )
        .then(() => {
          toast.success("Update successfully");
          dispatch(getProfileUser());
          dispatch(changeDisplayName(values?.displayname));
        })
        .catch(() => {});
    },
  });

  const handleAvatarInputChange = (event) => {
    const file = event.target.files[0];
    dispatch(uploadAvatar(file))
      .then(() => {
        dispatch(updateAva(URL.createObjectURL(file)));
        toast.success("Upload avatar successfully");
      })
      .catch(() => {
        toast.error("Upload avatar failed");
      });
  };

  useEffect(() => {
    dispatch(getProfileUser());
    dispatch(getCountries());
  }, []);

  return (
    <div className="settings-page">
      <div className="settings">
        <div className="settings__information">
          <div className="settings__information__main-title">PERSONAL INFORMATION</div>
          <div className="settings__information__avatar">
            <img
              className="settings__information__avatar__img"
              src={ava ? ava : user?.avatar !== `${Config.API_SERVER}/avatar/no-avatar.png` ? user?.avatar : NoUser}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoUser;
              }}
            />
            <div className="settings__information__avatar__edit" onClick={() => avatarInputRef.current.click()}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.6556 3.43911L8.56079 1.34473C8.49114 1.27507 8.40845 1.21981 8.31745 1.18211C8.22644 1.1444 8.1289 1.125 8.03039 1.125C7.93189 1.125 7.83435 1.1444 7.74334 1.18211C7.65234 1.21981 7.56965 1.27507 7.5 1.34473L1.71985 7.12489C1.6499 7.19428 1.59444 7.27688 1.5567 7.36789C1.51896 7.45891 1.49969 7.55652 1.5 7.65504V9.74989C1.5 9.9488 1.57902 10.1396 1.71967 10.2802C1.86033 10.4209 2.05109 10.4999 2.25 10.4999H10.125C10.2245 10.4999 10.3198 10.4604 10.3902 10.3901C10.4605 10.3197 10.5 10.2243 10.5 10.1249C10.5 10.0254 10.4605 9.93005 10.3902 9.85972C10.3198 9.78939 10.2245 9.74989 10.125 9.74989H5.40563L10.6556 4.49989C10.7253 4.43024 10.7806 4.34755 10.8183 4.25655C10.856 4.16554 10.8754 4.068 10.8754 3.9695C10.8754 3.87099 10.856 3.77345 10.8183 3.68244C10.7806 3.59144 10.7253 3.50875 10.6556 3.43911ZM9 5.09473L6.90563 2.99989L8.03063 1.87489L10.125 3.96973L9 5.09473Z"
                  fill="#454769"
                />
              </svg>
              <input type="file" style={{ display: "none" }} onChange={handleAvatarInputChange} ref={avatarInputRef} accept="image/*" />
            </div>
          </div>
          <div className="settings__information__form">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-8">
              <InputWraper label={"NAME*"} placeholder={"Enter your name"} inputProps={getInputProps("name")} component={InputText} />
              <InputWraper
                label={"DISPLAY NAME"}
                placeholder={"Enter your display name"}
                inputProps={getInputProps("displayname")}
                component={InputText}
              />
              <InputWraper
                label={"EMAIL*"}
                placeholder={"Enter your email"}
                inputProps={getInputProps("email")}
                component={InputText}
                isDisable={true}
              />
              <InputWraper
                label={"PHONE NUMBER*"}
                placeholder={"Enter your phone number"}
                inputProps={getInputProps("phone")}
                component={InputText}
              />
              <InputWraper
                label={"GENDER*"}
                placeholder={"Choose your gender"}
                inputProps={getInputProps("gender")}
                renderInput={(props) => <InputSelect {...props} options={genderOption} />}
              />
              <InputWraper
                label={"COUNTRY*"}
                placeholder={"Choose your country"}
                inputProps={getInputProps("country_id")}
                renderInput={(state) => (
                  <InputSelectAsyncData
                    {...state}
                    isClearable={false}
                    asyncData={{
                      response: { data: countries },
                      pathLabel: "name",
                      pathValue: "id",
                    }}
                  />
                )}
              />
              <InputWraper
                label={"DATE OF BIRTH*"}
                placeholder={"Enter your date of birth"}
                inputProps={getInputProps("dob")}
                renderInput={(props) => <InputDateTime {...props} format={"MM/dd/yyyy"} />}
              />
            </div>
          </div>
          <div className="settings__information__main-button">
            <Button
              className="settings__information__main-button__save"
              label={"Save"}
              buttonType="secondary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </div>
        {/* <div className="settings__other-settings">
                    <div className="settings__other-settings__main-title">SETTINGS</div>
                    <div className="settings__other-settings__main-button">
                        <div className="settings__other-settings__main-button__save" onClick={() => null} >Change password</div>
                    </div>
                </div> */}
      </div>
      <Toaster />
    </div>
  );
});

export default SettingsPage;
