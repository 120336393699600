import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
// import { get } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";

function TableUserWinning(props) {
  const { t } = useTranslation();

  const renderUserCard = () => {
    if (props?.winners?.length > 0)
      return props.winners.slice(0, 5).map((winner, index) => {
        return (
          <div className="winning-user__card grid grid-cols-3 sm:flex items-center text-left " key={index}>
            <div className="card-user__left text-center pr-2 sm:mr-4">
              <div className="frame-avatar text-center">
                <img src={winner?.image_url} alt="Winner" />
                <p>{moment(winner.created_at).local().format("HH:mm")}</p>
                <p>{moment(winner.created_at).local().format("LL")}</p>
              </div>
            </div>
            <div className="col-span-2 ml-6 sm:ml-0">
              <p className="card-user__name">{winner?.name}</p>
              <p className="card-user__value">
                <span>${Number(winner?.win_bonus).toFixed(2)}</span>
              </p>
              <div className="flex items-center">
                <div className="">
                  <p className="card-user__prize">{winner.lottery}</p>
                  <p className="card-user__result">{winner.ticket_number}</p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    else {
      return (
        <div className="text-center font-bold text-xl my-20">
          <span>{t("No winner yet")}</span>
        </div>
      );
    }
  };

  return (
    <div className="lottery-winning bg-white text-left mt-4 lg:col-span-3" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
      <h4 className="header-table lottery-title lottery-title-2 flex items-baseline justify-between pb-2 xl:pb-6">
        {t("Winning Users")}
        <div className="flex items-center mt-2 sm:mt-0 lg:mt-2 xl:mt-0">
          <Link className="xl:px-1" to="/winning-user">
            {t("See more")}
          </Link>
          <i className="pi pi-chevron-right"></i>
        </div>
      </h4>
      <div className="mb-4 pb-4 lg:pb-0 text-center padding-table-winning">{renderUserCard()}</div>
    </div>
  );
}

export default TableUserWinning;
