import { SET_STYLE_HEADING } from "./types";

export const setStyleHeading = (path) => (dispatch) => {
  if (path === "/" || path === "/home" || path === "/verify-partner")
    dispatch({
      type: SET_STYLE_HEADING,
      payload: true,
    });
  else
    dispatch({
      type: SET_STYLE_HEADING,
      payload: false,
    });
};
