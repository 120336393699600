import { walletAPI } from "configs/const";
import customAxios from "../configs/axios";

const getDepositeList = (from_date, to_date) => {
  return customAxios.post(walletAPI.getDepositeList, {
    from_date,
    to_date,
  });
};

const getDepositeDetail = (deposite_id) => {
  return customAxios.post(walletAPI.getDepositeDetail, {
    deposite_id,
  });
};

const getWithdrawList = (from_date, to_date) => {
  return customAxios.post(walletAPI.getWithdrawList, {
    from_date,
    to_date,
  });
};

const getWithdrawDetail = (withdraw_process_id) => {
  return customAxios.post(walletAPI.getWithdrawDetail, {
    withdraw_process_id,
  });
};

const getPaymentMethod = () => {
  return customAxios.get(walletAPI.getPaymentMethod);
};

const getPrepareWithdraw = () => {
  return customAxios.get(walletAPI.getPrepareWithdraw);
};

const getTransactionHistory = async (from_date, to_date, page, pageSize, type, status, address) => {
  return customAxios.post(walletAPI.getTransactionHistory, {
    from_date,
    to_date,
    page,
    pageSize,
    type,
    status,
    address,
  });
};

const getTransactionDetailHistory = (transaction_id) => {
  return customAxios.post(walletAPI.getTransactionDetailHistory, {
    transaction_id,
  });
};

const getRate = () => {
  return customAxios.get(walletAPI.getRate);
};

const postWithdraw = (chain, coin_id, address, amount) => {
  return customAxios.post(walletAPI.postWithdraw, {
    chain,
    coin_id,
    address,
    amount,
  });
};

const postWithdrawVerify = (chain, coin_id, address, amount, ip) => {
  return customAxios.post(walletAPI.postWithdrawVerify, {
    chain,
    coin_id,
    address,
    amount,
    ip,
  });
};

const getOrderHistory = (from_date, to_date, page, pageSize) => {
  return customAxios.post(walletAPI.getOrderHistory, {
    from_date,
    to_date,
    page,
    pageSize,
  });
};

export const checkIsvalidAddress = (address) => {
  return customAxios.post(walletAPI.checkIsvalidAddress, {
    address,
  });
};

const getFee = (type) => {
  return customAxios.get(walletAPI.getFee + type);
};

const transfer = (email_address, amount) => {
  return customAxios.post(walletAPI.transfer, {
    email_address,
    amount,
  });
};

const exchange = (amount) => {
  return customAxios.post(walletAPI.exchange, {
    amount,
  });
};

const exchangeHistory = () => {
  return customAxios.get(walletAPI.exchangeHistory);
};

const contractLottery = () => {
  return customAxios.get(walletAPI.contractLottery);
};

export default {
  getDepositeList,
  getDepositeDetail,
  getWithdrawList,
  getPaymentMethod,
  getPrepareWithdraw,
  getTransactionHistory,
  getTransactionDetailHistory,
  getRate,
  postWithdraw,
  getOrderHistory,
  postWithdrawVerify,
  checkIsvalidAddress,
  getWithdrawDetail,
  getFee,
  transfer,
  exchange,
  exchangeHistory,
  contractLottery,
};
