import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  EMAIL_ADDRESS,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CHANGE_FORGOT_PASSWORD_SUCCESS,
  CHANGE_FORGOT_PASSWORD_FAIL,
  SET_MESSAGE,
  RESET_FLAG_VALIDATION,
  RESEND_CODE_REGISTER_SUCCESS,
  RESEND_CODE_REGISTER_FAIL,
  LOADING_FAIL,
  VERIFY_PARTNER_SUCCESS,
} from "./types";

import AuthService from "../services/auth.service";
import { setMessage } from "./message";

export const registerAccount =
  (name, displayname, email, password, country_id, referral_by) => (dispatch) => {
    return AuthService.register(name, displayname, email, password, country_id, referral_by).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        dispatch({
          type: LOADING_FAIL,
        });

        return Promise.resolve();
      },
      (error) => {
        const error_code =
          (error.response &&
            error.response.data &&
            (error.response.data.error_code || error.response.data.message)) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: LOADING_FAIL,
        });

        dispatch(setMessage("register", error_code));

        return Promise.reject();
      }
    );
  };

export const emailAddress = (email) => (dispatch) => {
  dispatch({
    type: EMAIL_ADDRESS,
    payload: email,
  });
};

export const verifyEmail = (email, code) => (dispatch) => {
  return AuthService.verifyEmail(email, code).then(
    (response) => {
      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.error_code || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: VERIFY_EMAIL_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch(setMessage("verifyEmail", error_code));

      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.error_code || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
        payload: error_code,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch(setMessage("login", error_code));
      return Promise.reject();
    }
  );
};

export const forgotPassword = (email) => (dispatch) => {
  return AuthService.forgotPassword(email).then(
    (response) => {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.error_code || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: FORGOT_PASSWORD_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch(setMessage("forgotPassword", error_code));

      return Promise.reject();
    }
  );
};

export const changeForgotPassword = (code, email, password) => (dispatch) => {
  return AuthService.changeForgotPassword(code, email, password).then(
    (response) => {
      dispatch({
        type: CHANGE_FORGOT_PASSWORD_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      const error_code =
        (error.response &&
          error.response.data &&
          (error.response.data.error_code || error.response.data.message)) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHANGE_FORGOT_PASSWORD_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      dispatch(setMessage("changeForgotPassword", error_code));

      return Promise.reject();
    }
  );
};

export const resetFlagValidation = () => (dispatch) => {
  dispatch({
    type: RESET_FLAG_VALIDATION,
  });
};

export const resendCodeRegister = (email) => (dispatch) => {
  return AuthService.resendCodeRegister(email).then(
    (response) => {
      dispatch({
        type: RESEND_CODE_REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: RESEND_CODE_REGISTER_FAIL,
      });

      dispatch({
        type: LOADING_FAIL,
      });

      // dispatch(setMessage("changeForgotPassword", error_code));

      return Promise.reject();
    }
  );
};

export const verifyPartner = (code, email) => (dispatch) => {
  return AuthService.verifyPartner(code, email).then(
    (response) => {
      dispatch({
        type: VERIFY_PARTNER_SUCCESS,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: response.data.message,
      // });

      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.resolve();
    },
    (error) => {
      const msg =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: msg,
      });
      dispatch({
        type: LOADING_FAIL,
      });
      return Promise.reject();
    }
  );
};
