// import { parse } from 'querystring';
// import pathRegexp from 'path-to-regexp';
// import { Route } from '@/models/connect';
// import { DefaultSettings } from '../../config/defaultSettings';
// import { successMsg, errorMsg } from "@/common/services/highline.corner";
// import _ from 'lodash';


// /* eslint no-useless-escape:0 import/prefer-default-export:0 */
// const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

// export const isUrl = (path: string): boolean => reg.test(path);

// export const isAntDesignPro = (): boolean => {
//   if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
//     return true;
//   }
//   return window.location.hostname === 'preview.pro.ant.design';
// };

// // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
// export const isAntDesignProOrDev = (): boolean => {
//   const { NODE_ENV } = process.env;
//   if (NODE_ENV === 'development') {
//     return true;
//   }
//   return isAntDesignPro();
// };

// export const getPageQuery = () => parse(window.location.href.split('?')[1]);

// /**
//  * props.route.routes
//  * @param router [{}]
//  * @param pathname string
//  */
// export const getAuthorityFromRouter = <T extends { path?: string }>(
//   router: T[] = [],
//   pathname: string,
// ): T | undefined => {
//   const authority = router.find(({ path }) => path && pathRegexp(path).exec(pathname));
//   if (authority) return authority;
//   return undefined;
// };

// export const getRouteAuthority = (path: string, routeData: Route[]) => {
//   let authorities: string[] | string | undefined;
//   routeData.forEach(route => {
//     // match prefix
//     if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
//       if (route.authority) {
//         authorities = route.authority;
//       }
//       // exact match
//       if (route.path === path) {
//         authorities = route.authority || authorities;
//       }
//       // get children authority recursively
//       if (route.routes) {
//         authorities = getRouteAuthority(path, route.routes) || authorities;
//       }
//     }
//   });
//   return authorities;
// };

// export function setTenantCode(value: string) {
//   if (!value || value === '') {
//     return localStorage.removeItem('tenant-code');
//   }
//   return localStorage.setItem('tenant-code', value);
// }

// export function getTenantCode() {
//   const value = localStorage.getItem('tenant-code');
//   if (!value) {
//     return '';
//   }

//   return value;
// }

// export function setTenantShortName(tenantShortName: string) {
//   if (!tenantShortName || tenantShortName === '') {
//     return localStorage.removeItem('tenant-short-name');
//   }
//   return localStorage.setItem('tenant-short-name', tenantShortName);
// }

// export function getTenantShortName() {
//   const tenantShortName = localStorage.getItem('tenant-short-name');
//   if (!tenantShortName) {
//     return '';
//   }

//   return tenantShortName;
// }

// export function saveSettings(settings: DefaultSettings) {
//   if (!settings) {
//     return localStorage.removeItem('settings');
//   }
//   return localStorage.setItem('settings', JSON.stringify(settings));
// }

// export function getSettings() {
//   const settings = localStorage.getItem('settings');
//   if (!settings) {
//     return '';
//   }

//   return JSON.parse(settings);
// }

// export const getRoleNameFromRoleType = (roleType: number): string => {
//   let roleName = 'guest';
//   switch (roleType) {
//     case 1:
//       roleName = 'admin';
//       break;
//     case 2:
//       roleName = 'user';
//       break;
//     default:
//       roleName = 'guest';
//       break;
//   }
//   return roleName;
// };

// export function generateMessageResponse (res: any) {
//   if (res.isSuccess) {
//     if (res.errors.length !== 0) {
//       const msg = _.get(res, 'message');
//       errorMsg({msg});
//         return false;
//       }
//     successMsg({});
//   }else {
//     errorMsg({});
//   }
// }


import { useEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile =
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) &&
      !/iPad|Tablet/i.test(userAgent);
    setIsMobile(mobile);
  }, []);

  return isMobile;
};
