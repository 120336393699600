import imgPhase from "assets/images/imagePhase.png";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";
import { Icon } from "modules";

function TableNumberWinning(props) {
  const { t } = useTranslation();
  const contractLottery = useSelector((state) => state.wallet.contractLottery);

  const renderTicketNumber = (arrayTicketNumber) => {
    return arrayTicketNumber.map((number, index) => {
      return (
        <p
          key={index}
          className={`number-item flex items-center justify-center
        ${index === 0 ? " sm:mr-1.5 mr-1" : index === arrayTicketNumber.length - 1 ? "sm:ml-1.5 ml-1" : "sm:mx-1.5 mx-1"} 
        `}
        >
          <span>{number}</span>
        </p>
      );
    });
  };

  const renderWinningNumberCard = () => {
    if (props?.draw?.length > 0)
      return props.draw.slice(0, 10).map((item, index) => {
        const ticket_number = item.draw_number.split("");
        return (
          <div key={index} className={`${item.lottery === "Mega6X Max"} winning-number__card my-3 block justify-between sm:flex items-center`}>
            <div className="flex">
              <img src={imgPhase} alt="Power Ball" />
              <div className="card-number mr-1 sm:mr-2 md:mr-4 sm:flex-1">
                <p className="card-number__title mb-2.5">{item.lottery}</p>
                <div className=" flex items-center respon-number mr-4">{renderTicketNumber(ticket_number)}</div>
              </div>
            </div>
            <div
              className="card-date sm:text-right inline-flex flex-row-reverse 
                      justify-end ml-2 gap-4 sm:mt-0 sm:inline-block"
            >
              <p className="mb-0">{moment(item.end).local().format("HH:mm")}</p>
              <p className="mb-0">{moment(item.end).local().format("LL")}</p>
            </div>
          </div>
        );
      });
    else {
      return (
        <div className="text-center font-bold text-xl my-20">
          <span>{t("No number yet")}</span>
        </div>
      );
    }
  };

  return (
    <div className="lottery-winning bg-white text-left mt-4 lg:col-span-4" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
      <div className="header-table">
        <h4 className="lottery-title lottery-title-1 flex items-baseline justify-between">
          {t("Winning Numbers")}
          <div className="flex items-center mt-2 sm:mt-0 lg:mt-2 xl:mt-0">
            <Link className="xl:px-1" to="/winning-number">
              {t("See more")}
            </Link>
            <i className="pi pi-chevron-right"></i>
          </div>
        </h4>
        <div className="flex items-center gap-1">
          <Icon.Contract />
          <span>
            Smart Contract:
            <a href={`https://testnet.bscscan.com/address/${contractLottery}`} target="__blank" className="ml-1">
              {contractLottery ? contractLottery : ""}
            </a>
          </span>
        </div>
      </div>
      <div className="mb-5 pb-1 lg:pb-0 padding-table-winning">{renderWinningNumberCard()}</div>
    </div>
  );
}

export default TableNumberWinning;
