import React, { useState } from "react";
import "./style.scss";
import GooglePlay1 from "assets/images/GooglePlay1.png";
import AppStore1 from "assets/images/AppStore1.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import mmlotto from "assets/apk/mmlotto.apk";

function Advertisement() {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <div className="advertisement">
      {/* <img src={BackgroundbannerBottom} className="background"/> */}
      <div className="container grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
        <div className="advertisement__left">
          <h3 className="font-bold" data-aos="fade-up" data-aos-duration="600">
            {t("Play Game")}
          </h3>
          <p data-aos="fade-up" data-aos-duration="600" data-aos-delay="100">
            {t(
              "Our mmlotto games are available on mobile app, please download and enjoy your time"
            )}
          </p>
          <div
            className="flex items-center gap-x-4 lg:gap-x-6 mt-8"
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="200"
          >
            <div
              className="cursor-pointer"
              onClick={() => {
                setDisplayModal(true);
              }}
              // href="https://www.apple.com/app-store" target="_blank"
            >
              <img src={AppStore1} alt="App Store" />
            </div>
            {/* <a
              href="https://install.appcenter.ms/orgs/vmgb-co.-ltd/apps/super-lucky/distribution_groups/public"
              target="_blank"
            > */}
            <Link to={mmlotto} target="_blank" download="mmlotto.apk">
              <img src={GooglePlay1} alt="Google Play" />
            </Link>
            {/* </a> */}
          </div>
        </div>
        <div
          className="advertisement__right hidden lg:block relative items-center"
          data-aos="fade-right"
          data-aos-duration="600"
        >
          <div className="bg-phone"></div>
        </div>
      </div>
      <Dialog
        visible={displayModal}
        onHide={() => setDisplayModal(false)}
        dismissableMask={true}
        closable={false}
        showHeader={false}
        className="modal-app"
      >
        <div className="modal-app-content text-center">
          <button
            className="btn-close"
            onClick={() => {
              setDisplayModal(false);
            }}
          >
            <span className=" pi pi-times"></span>
          </button>
          <div className="container">
            <span>
              We will be on the AppStore soon. In the meantime contact your supporter for
              instructions. Thank you!
            </span>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Advertisement;
