import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountDownTime from "component/countDownTime";
import { InputNumber } from "primereact/inputnumber";
import IconQuestion from "assets/svg/IconQuestion";
import { useDispatch, useSelector } from "react-redux";
import { buyGame } from "actions/game";
import { getProfileUser } from "actions/user";
import toast from "react-hot-toast";
import { getGameEvenOdd, getGameMaxMin, getTimePhase } from "actions/phase";
import moment from "moment";
import ModalRulesGame from "component/modalRulesGame";
import { useHistory } from "react-router";
import Diamond from "assets/images/Diamond.png";
import ModalGameSuccess from "component/modalGameSuccess";

function GameMaxMinEvenOdd(props) {
  const { t } = useTranslation();
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [evenValue, setEvenValue] = useState(0);
  const [oddValue, setOddValue] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const [CountdownMaxMin, setCountDownMaxMin] = useState(0);
  const [CountdownEvenOdd, setCountDownEvenOdd] = useState(0);
  const [typeGame, setTypeGame] = useState(null);
  const phase = useSelector((state) => state.phase);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const info = () => toast("Please login", { icon: "❗" });
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [displayModalSuccess, setDisplayModalSuccess] = useState(false);

  const closeModalSuccess = () => {
    setDisplayModalSuccess(false);
  };

  const onSubmit = (typeGame, type) => {
    if (!isLoggedIn) {
      info();
      history.push("/login");
    } else {
      setIsErr(false);
      const amount =
        typeGame === "MaxMin" && type === 1
          ? maxValue
          : typeGame === "MaxMin" && type === 2
          ? minValue
          : typeGame === "EvenOdd" && type === 1
          ? evenValue
          : oddValue;
      if (amount > 0)
        dispatch(
          buyGame({
            lottery_id: typeGame === "MaxMin" ? phase?.gamePhaseMaxMin?.lotteryId : phase?.gamePhaseEvenOdd?.lotteryId,
            phase_id: typeGame === "MaxMin" ? phase?.gamePhaseMaxMin?.id : phase?.gamePhaseEvenOdd?.id,
            choose:
              typeGame === "MaxMin" && type === 1
                ? "Max"
                : typeGame === "MaxMin" && type === 2
                ? "Min"
                : typeGame === "EvenOdd" && type === 1
                ? "Even"
                : "Odd",
            amount: amount,
          })
        )
          .then(() => {
            setIsErr(false);
            dispatch(getProfileUser());
            dispatch(getGameMaxMin());
            dispatch(getGameEvenOdd());
            setDisplayModalSuccess(true);
            toast.success(
              `You have successfully purchased the game ${
                typeGame === "MaxMin" && type === 1
                  ? "Max"
                  : typeGame === "MaxMin" && type === 2
                  ? "Min"
                  : typeGame === "EvenOdd" && type === 1
                  ? "Even"
                  : "Odd"
              }`,
              {
                style: {
                  width: "fit-content",
                  maxWidth: "none",
                },
              }
            );
          })
          .catch(() => {
            setIsErr(true);
          });
      else {
        toast("Please enter the diamond you want to play", {
          style: {
            width: "fit-content",
            maxWidth: "none",
          },
        });
      }
    }
    // toast.dismiss();
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  useEffect(() => {
    isErr &&
      toast(message, {
        style: {
          width: "fit-content",
          maxWidth: "none",
        },
      });
  }, [isErr, message]);

  useEffect(() => {
    let timerMinMax;
    let timerEvenOdd;

    let start = phase.timePhase ? moment(phase.timePhase.time).utc() : moment().utc();

    let endMaxMin = phase.gamePhaseMaxMin ? moment(phase.gamePhaseMaxMin.end).utc() : moment().utc();
    const durationMaxMin = moment.duration(endMaxMin.diff(start));
    let MillisecondsMaxMin = durationMaxMin.asMilliseconds();

    if (MillisecondsMaxMin !== 0) {
      timerMinMax = setInterval(() => {
        if (MillisecondsMaxMin > 0) {
          MillisecondsMaxMin = moment.duration(MillisecondsMaxMin - 1000, "milliseconds");
        } else {
          if (MillisecondsMaxMin === 0) {
            dispatch(getTimePhase());
            dispatch(getGameMaxMin());
          }
        }
        if (MillisecondsMaxMin >= 0) setCountDownMaxMin(moment(MillisecondsMaxMin.asMilliseconds()).utc());
      }, 1000);
    }

    let endEvenOdd = phase.gamePhaseEvenOdd ? moment(phase.gamePhaseEvenOdd.end).utc() : moment().utc();
    const durationEvenOdd = moment.duration(endEvenOdd.diff(start));
    let MillisecondsEvenOdd = durationEvenOdd.asMilliseconds();

    if (MillisecondsEvenOdd !== 0) {
      timerEvenOdd = setInterval(() => {
        if (MillisecondsEvenOdd > 0) {
          MillisecondsEvenOdd = moment.duration(MillisecondsEvenOdd - 1000, "milliseconds");
        } else {
          if (MillisecondsEvenOdd === 0) {
            dispatch(getTimePhase());
            dispatch(getGameEvenOdd());
          }
        }
        if (MillisecondsEvenOdd >= 0) setCountDownEvenOdd(moment(MillisecondsEvenOdd.asMilliseconds()).utc());
      }, 1000);
    }
    return () => {
      clearInterval(timerMinMax);
      clearInterval(timerEvenOdd);
    };
  }, [phase, dispatch]);

  const renderCardGameMinMax = () => {
    return (
      <div className="md:w-5/6 w-full container-minmax mx-auto mt-14">
        <div className="lg:flex lg:justify-between relative">
          <div className="user-diamond" style={{ color: "#ffff" }}>
            {user && (
              <span className="flex items-center">
                Accumulated Points: <img className="ml-4 mr-1" src={Diamond} alt="Logo" />
                {Number(user?.wallets[0].diamond).toFixed(2)}
              </span>
            )}
          </div>
          <div
            className="game-question"
            onClick={() => {
              setDisplayModal(true);
              setTypeGame("MaxMin");
            }}
          >
            <IconQuestion />
          </div>
          <div className="container-ticket col-span-1">
            <div className="name-type-ticket mt-4">
              <span>{t("Max")}</span>
            </div>
            <div className="input-price-ticket">
              <span className="p-input-icon-left">
                <img src={Diamond} alt="Logo" />
                <InputNumber
                  placeholder="Enter the diamond"
                  minFractionDigits={2}
                  value={maxValue}
                  onFocus={() => setMaxValue()}
                  onChange={(e) => setMaxValue(e.value)}
                />
              </span>
            </div>
            <div className="flex items-center justify-center lg:justify-start amount-user">
              <span>Total Set: {Number(phase?.gamePhaseMaxMin?.totalDiamondMax).toFixed(2)}</span>
            </div>
            <div className="btn-buy">
              <button onClick={() => onSubmit("MaxMin", 1)}>{t("Play")}</button>
            </div>
          </div>

          <div className="col-span-1 container-time">
            <div className="id-phase">#{phase?.gamePhaseMaxMin?.phaseCode}</div>
            <div className="ticket-card__minmax flex justify-evenly">
              <CountDownTime Countdown={CountdownMaxMin} typeCountDown={"GameMinMax"} />
            </div>
          </div>

          <div className="container-ticket col-span-1">
            <div className="name-type-ticket mt-4">
              <span>{t("Min")}</span>
            </div>
            <div className="input-price-ticket">
              <span className="p-input-icon-left">
                <img src={Diamond} alt="Logo" />
                <InputNumber
                  placeholder="Enter the diamond"
                  minFractionDigits={2}
                  value={minValue}
                  onFocus={() => setMinValue()}
                  onChange={(e) => setMinValue(e.value)}
                />
              </span>
            </div>
            <div className="flex items-center justify-center lg:justify-start amount-user">
              <span>Total Set: {Number(phase?.gamePhaseMaxMin?.totalDiamondMin).toFixed(2)}</span>
            </div>
            <div className="btn-buy">
              <button onClick={() => onSubmit("MaxMin", 2)}>{t("Play")}</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCardGameEvenOdd = () => {
    return (
      <div className="md:w-5/6 w-full container-evedodd mx-auto my-14">
        <div className="lg:flex lg:justify-between relative">
          <div className="user-diamond" style={{ color: "#040302" }}>
            {user && (
              <span className="flex items-center">
                Accumulated Points: <img className="ml-4 mr-1" src={Diamond} />
                {Number(user?.wallets[0].diamond).toFixed(2)}
              </span>
            )}
          </div>
          <div
            className="game-question"
            onClick={() => {
              setTypeGame("EvenOdd");
              setDisplayModal(true);
            }}
          >
            <IconQuestion />
          </div>
          <div className="container-ticket col-span-1">
            <div className="name-type-ticket mt-4">
              <span>{t("Even")}</span>
            </div>
            <div className="input-price-ticket">
              <span className="p-input-icon-left">
                {/* <DiamondSmall /> */}
                <img src={Diamond} alt="Logo" />
                <InputNumber
                  placeholder="Enter the diamond"
                  minFractionDigits={2}
                  value={evenValue}
                  onFocus={() => setEvenValue()}
                  onChange={(e) => setEvenValue(e.value)}
                />
              </span>
            </div>
            <div className="flex items-center justify-center lg:justify-start amount-user">
              <span>Total Set: {Number(phase?.gamePhaseEvenOdd?.totalDiamondEven).toFixed(2)}</span>
            </div>
            <div className="btn-buy">
              <button onClick={() => onSubmit("EvenOdd", 1)}>{t("Play")}</button>
            </div>
          </div>

          <div className="col-span-1 container-time">
            <div className="id-phase">#{phase?.gamePhaseEvenOdd?.phaseCode}</div>
            <div className="ticket-card__minmax flex justify-evenly">
              <CountDownTime Countdown={CountdownEvenOdd} typeCountDown={"GameEvenOdd"} />
            </div>
          </div>

          <div className="container-ticket col-span-1">
            <div className="name-type-ticket mt-4">
              <span>{t("Odd")}</span>
            </div>
            <div className="input-price-ticket">
              <span className="p-input-icon-left">
                <img src={Diamond} alt="Logo" />
                <InputNumber
                  placeholder="Enter the diamond"
                  minFractionDigits={2}
                  value={oddValue}
                  onFocus={() => setOddValue()}
                  onChange={(e) => setOddValue(e.value)}
                />
              </span>
            </div>
            <div className="flex items-center justify-center lg:justify-start amount-user">
              <span>Total Set: {Number(phase?.gamePhaseEvenOdd?.totalDiamondOdd).toFixed(2)}</span>
            </div>
            <div className="btn-buy">
              <button onClick={() => onSubmit("EvenOdd", 2)}>{t("Play")}</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGameMinMaxEvenOdd = () => {
    return (
      // props.phase &&
      // props.phase.listPhase != null &&
      <>
        <div className="items-center frame-buy-ticket">{renderCardGameMinMax()}</div>
        <div className="items-center frame-buy-ticket">{renderCardGameEvenOdd()}</div>
      </>
    );
  };

  return (
    <>
      <div className="lottery-jackpot">
        <div data-aos="fade-up" data-aos-duration="600" data-aos-delay="300" className={`container text-center ${props.page !== "Game" ? "" : ""}`}>
          {renderGameMinMaxEvenOdd()}
        </div>
      </div>
      <ModalRulesGame typeGame={typeGame} displayModal={displayModal} closeModal={closeModal} />
      <ModalGameSuccess displayModalSuccess={displayModalSuccess} closeModalSuccess={closeModalSuccess} />
    </>
  );
}

export default GameMaxMinEvenOdd;
