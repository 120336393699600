import _ from 'lodash';
import React, { FC, useState } from 'react';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { ClassNames, DateTimeUtils } from 'modules';

export const TableFilterRangeTimeInput = (
  props
) => {
  return (
    <InputDateTimeRange
      startTimeDefaultValue={
        (props.params[props.fromKey]
          ? new Date(props.params[props.fromKey])
          : undefined)
      }
      endTimeDefaultValue={
        (props.params[props.fromKey]
          ? new Date(props.params[props.toKey])
          : undefined)
      }
      onChange={(e) => {
        if (e)
          props.onChange({
            [props.fromKey]: e.startTime,
            [props.toKey]: e.endTime
          });
        else props.onChange({ [props.fromKey]: '', [props.toKey]: '' });
      }}
      format={_.isNil(props?.format) ? 'dd/MM/y HH:mm:ss' : props.format}
    />
  );
};

// ============================ Date Time ============================

export const InputDateTime = ({isSecondTime = false, locale = 'en-GB', ...props}) => {
  const {
    onChange,
    minDate,
    maxDate,
    maxDetail,
    disabled,
    className,
    format
  } = props;

  const handleChange = (date) => {
    let time;

    if (date) {
      time = new Date(date).getTime();
      if (isSecondTime) time = DateTimeUtils.timeToSeconds(date);
    }

    if (onChange) return onChange(time);
  };

  const convertTime = (time) => {
    if (!time) return;
    if (isSecondTime) return DateTimeUtils.secondsToTime(time);
    return new Date(time);
  };

  return (
    <DateTimePicker
      className={`InputDateTime ${className}`}
      calendarClassName="InputDateTimeCalendar"
      clockClassName="InputDateTimeClock"
      maxDetail={maxDetail}
      disableClock={true}
      onChange={handleChange}
      minDate={convertTime(minDate)}
      maxDate={convertTime(maxDate)}
      value={convertTime(props.value)}
      locale={'vi-VN'}
      disabled={disabled}
      format={format}
      onCalendarClose={() => {
        if (props.onFocus) props.onFocus();
      }}
      onCalendarOpen={() => {
        if (props.onBlur) props.onBlur();
      }}
      onBlur={(e) => {
        if (props.onBlur) props.onBlur(e);
      }}
      onFocus={(e) => {
        if (props.onFocus) props.onFocus(e);
      }}
    />
  );
};

// ============================ Date Time Range ============================

export const InputDateTimeRange = ({isSecondTime = false, locale = 'en-GB', ...props}) => {
  const { onChange, minDate, maxDate, className } = props;
  const [startTime, setStartTime] = useState(props.startTimeDefaultValue);
  const [endTime, setEndTime] = useState(props.endTimeDefaultValue);
  //const [formatStr, setFormatStr] = useState(props.format);

  // ============================ Functions ============================
  const handleChangeRange = (type, date) => {
    if (type === 'startTime') {
      if (endTime && date) onChange({ startTime: date, endTime });
      if (!endTime && !date) onChange(null);
      setStartTime(date);
    }

    if (type === 'endTime') {
      if (startTime && date) onChange({ startTime, endTime: date });
      if (!startTime && !date) onChange(null);
      setEndTime(date);
    }
  };

  return (
    <div
      className={ClassNames({
        InputDateTimeRange: true,
        [className]: !!className,
        disabled: props.disabled
      })}
    >
      <div className="input">
        <div className="label">From</div>
        <InputDateTime
          value={startTime}
          isSecondTime={isSecondTime}
          minDate={isSecondTime && minDate ? minDate * 1000 : minDate}
          onChange={(date) => handleChangeRange('startTime', date)}
          format={_.isNil(props?.format) ? 'dd/MM/y HH:mm:ss' : props.format}
        />
      </div>
      <div className="input">
        <div className="label">To</div>
        <InputDateTime
          value={endTime}
          isSecondTime={isSecondTime}
          onChange={(date) => handleChangeRange('endTime', date)}
          minDate={startTime}
          maxDate={isSecondTime && maxDate ? maxDate * 1000 : maxDate}
          format={_.isNil(props?.format) ? 'dd/MM/y HH:mm:ss' : props.format}
        />
      </div>
    </div>
  );
};
