import React, { useEffect, useState } from "react";

import { QRCodeWalletAddress } from "component";
import ReactSelect, { components } from "react-select";
import { ENetWork } from "types";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dashboardLayout } from "component";
import { getPaymentMethod } from "actions/wallet";

let tokenOptions = [{ label: "USDT", value: "USDT" }];

const DepositPage = dashboardLayout((props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { paymentMethod } = useSelector((state) => state.wallet);
  console.log("DepositPage ~ paymentMethod:", paymentMethod);

  const [popupPromotionAlert, setPopupPromotionAlert] = useState(false);
  const [tokenSelected, setTokenSelected] = useState(tokenOptions[0]);
  const [network, setNetwork] = useState({
    label: `USDT - BEP20`,
    value: "BEP20",
  });
  // const userInternalWallets = useSelector((state) => state.userInternalWallets);
  // const userNetworkOptions = useSelector((state) => state.main.networkOptions);

  useEffect(() => {
    // BankService.getTokenStandard(store).then((res) => {
    //     let defaultNetwork = res?.payload?.[0];
    //     setNetwork({
    //         label: `${tokenSelected != null ? `${tokenSelected?.label} - ${defaultNetwork?.label}` : `${defaultNetwork?.label}`}`,
    //         value: defaultNetwork?.value,
    //     });
    // });
  }, []);

  // if (!isVisible) return null;

  // if (!userInternalWallets) return <Message type="loading" />;
  // if (userInternalWallets.error)
  //     return (
  //         <Message
  //             type="error"
  //             {...userInternalWallets.error}
  //         />
  //     );

  // const internalWallet = userInternalWallets.data.find(x => x.code === coinCode);
  // const internalWalletByNetwork = ObjectUtils.getIn(internalWallet, network?.value?.toLowerCase());

  const displayNetworkName = () => {
    switch (network?.value) {
      case ENetWork.BEP20: {
        return "BEP-20";
      }
      default: {
        return "BEP-20";
      }
    }
  };

  let CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="custom-option__container">
          <div className="custom-option__icon">
            <img src={`/assets/images/funds/${props?.data?.value?.toLowerCase()}.png`} alt="" />
          </div>
          <div className="custom-option__label">{props?.data?.label}</div>
        </div>
      </components.Option>
    );
  };

  let SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div className="single-value__container">
          <div className="single-value__icon">
            <img src={`/assets/images/funds/${props?.data?.value?.toLowerCase()}.png`} alt="" />
          </div>
          <div className="single-value__label">{props?.data?.label}</div>
        </div>
      </components.SingleValue>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.86198 0.195312L3.99998 3.05731L1.13798 0.195312L0.195312 1.13798L3.99998 4.94265L7.80465 1.13798L6.86198 0.195312Z"
            fill="black"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const CustomIndicatorSeparator = (props) => null;

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, [dispatch]);

  // if (paymentMethod == null)
  //     return (
  //         <div className="text-center">
  //             <Icon.Loading />
  //         </div>
  //     );

  return (
    <div className="deposit-form-page">
      <div className="indicator-back" onClick={() => history.push("/overview")}>
        <div className="indicator-back__icon">
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.64814 17.0484C9.17951 17.5171 8.41971 17.5171 7.95108 17.0484L0.751078 9.84843C0.282449 9.3798 0.282449 8.62 0.751078 8.15137L7.95108 0.951375C8.41971 0.482746 9.17951 0.482746 9.64814 0.951375C10.1168 1.42 10.1168 2.1798 9.64814 2.64843L4.49666 7.7999L18.3996 7.7999C19.0623 7.7999 19.5996 8.33716 19.5996 8.9999C19.5996 9.66264 19.0623 10.1999 18.3996 10.1999L4.49666 10.1999L9.64814 15.3514C10.1168 15.82 10.1168 16.5798 9.64814 17.0484Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="indicator-back__label">{"DEPOSIT - USDT"}</div>
      </div>
      <div className="deposit-form">
        <div className="deposit-form-wrapper">
          <div className="deposit-form__step-1">
            <div className="deposit-form__step-1__title">
              <div className="deposit-form__step-1__title__icon">1</div>
              <div className="deposit-form__step-1__title__label">{"SELECT TOKEN TO DEPOSIT"}</div>
            </div>
            <div className="deposit-form__step-1__content">
              <ReactSelect
                options={tokenOptions}
                value={tokenSelected}
                onChange={setTokenSelected}
                className="token-select"
                classNamePrefix="token-select"
                placeholder={"Please select currency..."}
                components={{
                  Option: CustomOption,
                  SingleValue: SingleValue,
                  DropdownIndicator,
                  IndicatorSeparator: CustomIndicatorSeparator,
                }}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="deposit-form__step-2">
            <div className="deposit-form__step-2__title">
              <div className="deposit-form__step-2__title__icon">2</div>
              <div className="deposit-form__step-2__title__label">{`SELECT ${tokenSelected?.label} DEPOSIT NETWORK`}</div>
            </div>
            <div className="deposit-form__step-2__content">
              <div className="deposit-zone">
                <div className="deposit-zone__network-select">
                  <ReactSelect
                    options={[{ label: "USDT - BEP20", value: "BEP20" }]}
                    value={network}
                    onChange={(element) =>
                      setNetwork({
                        label: `${tokenSelected != null ? `${tokenSelected?.label} - ${element?.label}` : `${element?.label}`}`,
                        value: element?.value,
                      })
                    }
                    className="network-select"
                    classNamePrefix="network-select"
                    components={{
                      // Option: CustomOption,
                      // SingleValue: SingleValue,
                      DropdownIndicator,
                      IndicatorSeparator: CustomIndicatorSeparator,
                    }}
                    isSearchable={false}
                  />
                </div>
                <div className="deposit-zone__address-label">{"CLICK TO COPY ASSETS ADDRESS"}</div>
                {/* @ts-ignore */}
                <CopyToClipboard text={paymentMethod?.[0]?.address} onCopy={() => toast.success("Copy Successfully")}>
                  <div className="deposit-zone__address-content">
                    <div className="deposit-zone__address-content__value">{paymentMethod?.[0]?.address}</div>
                    <div className="deposit-zone__address-content__icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.4" clipPath="url(#clip0_652_5285)">
                          <path
                            d="M13.7344 15.4922H5.76562C4.79626 15.4922 4.00781 14.7037 4.00781 13.7344V5.76562C4.00781 4.79626 4.79626 4.00781 5.76562 4.00781H13.7344C14.7037 4.00781 15.4922 4.79626 15.4922 5.76562V13.7344C15.4922 14.7037 14.7037 15.4922 13.7344 15.4922ZM5.76562 5.17969C5.44254 5.17969 5.17969 5.44254 5.17969 5.76562V13.7344C5.17969 14.0575 5.44254 14.3203 5.76562 14.3203H13.7344C14.0575 14.3203 14.3203 14.0575 14.3203 13.7344V5.76562C14.3203 5.44254 14.0575 5.17969 13.7344 5.17969H5.76562ZM2.83594 10.8047H2.25C1.92691 10.8047 1.66406 10.5418 1.66406 10.2188V2.25C1.66406 1.92691 1.92691 1.66406 2.25 1.66406H10.2188C10.5418 1.66406 10.8047 1.92691 10.8047 2.25V2.80664H11.9766V2.25C11.9766 1.28064 11.1881 0.492188 10.2188 0.492188H2.25C1.28064 0.492188 0.492188 1.28064 0.492188 2.25V10.2188C0.492188 11.1881 1.28064 11.9766 2.25 11.9766H2.83594V10.8047Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_652_5285">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </CopyToClipboard>
                <div className="deposit-zone__warning">
                  <strong>{`${"Warning"}:`}</strong>
                  {` ${"The network must be"} ${displayNetworkName()} ${"Make sure your network matches the network address"}.`}
                </div>
                <div className="deposit-zone__qr">
                  <QRCodeWalletAddress value={paymentMethod?.[0]?.address} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
});

export default DepositPage;
