import { Icon } from "modules";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";

export const InputNumber = (props) => {
  const { value, onTouched, suffix, customSuffix, prefix, max, min, onChange, placeholder, isDisabled, decimalScale, onBlur } = props;

  return (
    <Fragment>
      {/* @ts-ignore */}
      <NumberFormat
        // fixedDecimalScale={true}
        decimalScale={decimalScale != null ? decimalScale : 8}
        value={value || ""}
        // decimalSeparator={'en-US'}
        // thousandSeparator={'en-US'}
        onValueChange={({ floatValue, value }) => {
          return onChange(floatValue);
        }}
        // onChange={(e: any) => onChange(+e?.target?.value)}
        onBlur={(e) => {
          let _e = { ...e };
          setTimeout(() => {
            onTouched();
            if (onBlur) onBlur(_e);
          }, 500);
        }}
        suffix={suffix}
        prefix={prefix}
        placeholder={placeholder}
        disabled={isDisabled}
        // decimalScale={decimalScale ? decimalScale : 8}
      />

      {customSuffix && <div className="custom-suffix">{customSuffix}</div>}

      {value ? (
        <div className="btnInputNumberClear" onClick={() => props.onChange(0)}>
          <Icon.Close />
        </div>
      ) : null}
    </Fragment>
  );
};
