const ITableStructureItem = {
  name: '',
  key: '',
  render: (rowValue, fetchData, column) => {},
  style: {},
  className: '',
  sort: {}
};

const ITableStructureSort = {
  key: '',
  increaseValue: null,
  descreaseValue: null
};

const ITableStructureItemFilter = {
  name: '',
  key: '',
  defaultValue: null,
  input: () => {}
};

const ITableFilterInputProps = {
  paramKey: '',
  params: {},
  onChange: (value) => {},
  placeholder: '',
  delayOnChange: false
};

const ITableResponse = {
  count: 0,
  data: []
};

const ITableProps = {
  structure: [],
  filters: [],
  className: '',
  isShowLoading: false,
  isShowMessageEmpty: false,
  itemPerPages: 0,
  enableReinitialize: false,
  id: '',
  ref: null,
  data: {},
  fetchData: (params) => new Promise((resolve) => resolve(ITableResponse)),
  searchBox: {},
  hasCheckbox: false,
  hasSearchButton: false,
  valueCheckbox: null,
  onChangeCheckbox: (value) => {},
  hasOrderColumn: false,
  doubleScroll: false,
  forceUpdateTable: null,
  isOpenFilterBox: false
};

const ITableStatePagination = {
  isVisible: false,
  pageNumber: 0,
  itemsPerPage: 0,
  totalPage: 0
};

const ITableState = {
  isFetching: false,
  count: 0,
  data: [],
  error: '',
  params: {},
  pagination: ITableStatePagination,
  isShowTable: false,
  isOpenFilter: false
};

const IFetchDataParameters = {
  pagination: ITableStatePagination,
  params: {}
};

const ITableScrollProps = {
  structure: [],
  filters: [],
  isScrollInside: false,
  className: '',
  itemPerPages: 0,
  fetchData: (params) => new Promise((resolve) => resolve(ITableResponse)),
  searchBox: {},
  forceUpdateTable: 0,
  hasOrderColumn: false
};

const ITableSearchBox = {
  placeholder: '',
  fetchData: (q) => new Promise((resolve) => resolve([])),
  onSelect: (value) => {}
};