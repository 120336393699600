export class ObjectUtils {
    static getIn(obj, path, def, convertValue) {
        try {
            var stringToPath = function (path) {
                if (typeof path !== 'string') return path;
                var output = [];
                path.split('.').forEach(function (item) {
                    item.split(/\[([^}]+)\]/g).forEach(function (key) {
                        if (key.length > 0) {
                            output.push(key);
                        }
                    });
                });
                return output;
            };

            path = stringToPath(path);
            var current = obj || {};

            for (var i = 0; i < path.length; i++) {
                if (typeof current[path[i]] === 'undefined') return def;
                current = current[path[i]];
            }

            if (current && convertValue) return convertValue(current);
            return current;
        } catch (error) {
            return def;
        }
    };

    static cleanObj(obj) {
        obj = obj || {};

        if (Array.isArray(obj)) {
            return obj.map(item => Object.keys(item).reduce((acc, key) => (
                item[key] === undefined
                    || item[key] === null
                    || item[key] === ''
                    ? acc
                    : { ...acc, [key]: item[key] }
            ), {}))
        }

        return Object.keys(obj).reduce((acc, key) => (
            obj[key] === undefined
                || obj[key] === null
                || obj[key] === ''
                ? acc
                : { ...acc, [key]: obj[key] }
        ), {})
    }

    static isEmptyObj(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }

    static isHasValue(obj) {
        return !ObjectUtils.isEmptyObj(obj)
    }

    static selects(obj, keys) {
        return keys.reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {})
    }

    static toQueryString(query, keyOfItemsMustBeEndCode) {
        try {
            const cleanedObj = Object.keys(query).reduce((acc, key) => (
                query[key] === undefined
                    || query[key] === null
                    || query[key] === ''
                    ? acc
                    : { ...acc, [key]: query[key] }
            ), {})

            let output = '?';
            for (const key in cleanedObj) {
                if (cleanedObj[key]) {
                    const item = cleanedObj[key];
                    if (keyOfItemsMustBeEndCode && keyOfItemsMustBeEndCode.includes(key)) {
                        output += `${key}=${encodeURIComponent(item)}&`;
                    } else {
                        output += `${key}=${item}&`;
                    }
                }
            }

            return output.slice(0, output.length - 1)
        } catch (error) {
            return ''
        }
    }
}