import { SET_STYLE_HEADING } from "../actions/types";

const initialValues = { styleHeading: true };

export default function (state = initialValues, { type, payload }) {
  switch (type) {
    case SET_STYLE_HEADING:
      return {
        ...state,
        styleHeading: payload,
      };
    default:
      return state;
  }
}
