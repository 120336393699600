import { phaseAPI } from "configs/const";
import customAxios from "../configs/axios";

const getPhase = () => {
  return customAxios.get(phaseAPI.getPhase);
};

const getTimePhase = () => {
  return customAxios.get(phaseAPI.getTimePhase);
};

const getGameMaxMin = () => {
  return customAxios.get(phaseAPI.getGameMaxMin);
};

const getGameEvenOdd = () => {
  return customAxios.get(phaseAPI.getGameEvenOdd);
};

export default {
  getPhase,
  getTimePhase,
  getGameMaxMin,
  getGameEvenOdd,
};
